import React, { useState } from 'react';
import { Event, AppointmentDetails } from '@live-chair-health/lch-core';

import CalendarView from './components/CalendarView';
import './styles.scss';

interface CalendarProps {
  eventList: Event[];
  appointmentsList: AppointmentDetails[],
  userId: string;
}

const Calendar: React.FC<CalendarProps> = ({ eventList, userId, appointmentsList }) => {
  const [shownDate, setShownDate] = useState(new Date());

  return <CalendarView currentDate={shownDate} onChange={setShownDate} eventList={eventList} scheduledAppointments={appointmentsList} userId={userId} />;
};

export default Calendar;
