import { User } from '../models/user';

export const getFirstName = (user: User) => {
  if (user) return user.fullName?.split(' ')[0];
};

export const getLastName = (user: User) => {
  if (user) return user.fullName?.split(' ')[1];
};

export const splitFullName = (fullName: string | null) => {
  const splitFullName = fullName ? fullName.split(' ') : null;

  if (splitFullName != null) return { firstName: splitFullName[0], lastName: splitFullName[1] };

  return { firstName: '', lastName: '' };
};
