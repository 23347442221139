import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Patient as Profile } from '@live-chair-health/lch-core';

const { persistAtom } = recoilPersist({
  key: 'profile-persist',
  storage: localStorage,
});

const INIT_VALUE: Profile = {
  address: {},
  app_household_id: '',
  app_language: '',
  assigned_agents_ids: [],
  barber_id: '',
  barber_shop: 0,
  cell_phone_verified: false,
  comments: {},
  communication_log: {},
  created_at: 0,
  created_by_id: '',
  date_of_birth: '',
  diseases_articles_read: [],
  emails: [],
  employment_location: {},
  ethnicity: '',
  first_name: '',
  id: '',
  insurance_status: [],
  last_communication: 0,
  last_name: '',
  last_seen_pcp: {
    pcp_doctor_id: '',
    pcp_supplier_id: '',
    last_seen_date: 0,
  },
  lch_id: '',
  main_pcp: {
    pcp_doctor_id: '',
    pcp_supplier_id: '',
  },
  marital_status: '',
  member_number: '',
  origin: '',
  other_race: '',
  partners_ids: [],
  phones: [],
  questionnaire_id: '',
  race_ids: '',
  referred_by: '',
  registration_id: '',
  related_appointments_ids: [],
  related_calls_ids: [],
  related_emails_ids: [],
  related_forms_ids: [],
  related_patients_ids: [],
  related_sms_ids: [],
  related_tasks_ids: [],
  representative_id: '',
  sex: '',
  sms_notifications: false,
  tags: [],
  updated_by_id: '',
  user_type: 0,
  validated: false,
};

export const ProfileState = atom<Profile>({
  key: 'profileState',
  default: INIT_VALUE,
  effects_UNSTABLE: [persistAtom],
});
