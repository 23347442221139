import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { LearningCenter } from './../models/learning-center';
import { getArticles } from './../adapters/learning-center-adapter';

const { persistAtom } = recoilPersist({
    key: 'learning-persist',
    storage: localStorage,
  });

  const INIT_VALUE: LearningCenter  =  {
    referencesLimitReached: false,
      articles:[{
                  id:null,
                  url:null,
                  title:null,
                  summary:null,
                  category:null,
      }]

  }

  export const LearningCenterState = atom<LearningCenter>({
    key: 'learningCenterState',
    default: INIT_VALUE,
    effects_UNSTABLE: [persistAtom],
  });

  export const LearningCenterSelector = selector({
    key: 'LearningCenterArticleOfTheMonthSelector',
    get: async ({ get }) => {
      const response = await getArticles();
      const articles = response.data.data.data.articles;
      const articleOfTheMonth = articles.find((article)=>{
        let articleTopicOfTheMonth = article?.topicOfTheMonth === true;
        return articleTopicOfTheMonth;
      })
      return articleOfTheMonth;
    },
  });

export const ArticleOfTheMonthState = atom({
    key: 'articleOfTheMonthState',
    default: {},
    effects_UNSTABLE:[persistAtom]
  })