import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { IonCard, IonCardContent, IonItem, IonCardHeader, IonText, IonIcon, IonPage, IonContent } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'firebase/analytics';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getArticles } from '../../../common/adapters/learning-center-adapter';
import { ExternalLinkModal } from '../../../components/externalLinkModal/ExternalLinkModal';
import { analytics } from '../../../common/firebase/firebase';
import { UserState } from '../../../common/store-atom/user-atom';
import { ArticleOfTheMonthState } from './../../../common/store-atom/learning-center-atom';
import { User } from '../../../common/models/user';
import { Spinner } from '../../../components/spinner/spinner';
import { TopicOfTheMonth } from './topic-of-the-month/index';
import { LearningCenterState } from './../../../common/store-atom/learning-center-atom';

import './styles.scss';

export const AboutYourHealthPage = () => {
  const queryArticles = useQuery('articles', getArticles);
  const [openArticle, setOpenArticle] = useState<any>({ open: false, url: '' });
  const userSelector: User = useRecoilValue(UserState);
  const [learningCenterState, setLearningCenterState] = useRecoilState(LearningCenterState);
  const [articleOfTheMonthState, setArticleTopicOfTheMonthState] = useRecoilState(ArticleOfTheMonthState);
  const { t } = useTranslation();

  let articleTopicOfTheMonth = queryArticles.data?.data.data.articles.find((article) => {
    let articleOfTheMonth = article?.topicOfTheMonth === true;
    return articleOfTheMonth;
  });

  if (!articleTopicOfTheMonth && queryArticles.data?.data.data.articles?.length > 0) {
    articleTopicOfTheMonth = queryArticles.data?.data.data.articles[0];
  }

  const handleOpenArticle = (article: any) => {
    logEvent(analytics, 'article_opened', {
      articleTile: article.title,
      articleUrl: article.url,
      timeStamp: new Date(),
      userId: userSelector.uid,
    });
    setOpenArticle({ open: true, url: article.url });
  };

  const getLearningCenterData = async () => {
    await getArticles().then(onSuccess).catch(onError);
  };

  const onSuccess = (response: any) => {
    setLearningCenterState({
      ...response.data.data,
    });
    setArticleTopicOfTheMonthState({
      ...response.data.data.articles.find((article) => {
        let articleOfTheMonth = article?.topicOfTheMonth === true;
        return articleOfTheMonth;
      }),
    });
  };

  const onError = (error) => {
    console.error(error);
  };

  useEffect(() => {
    getLearningCenterData();
    setArticleTopicOfTheMonthState({ ...articleTopicOfTheMonth });
  }, []);

  return (
    <IonPage>
      <Spinner isOpen={queryArticles.isLoading} />

      {!queryArticles.isLoading && (
        <IonContent fullscreen>
          <div className="lc-learning-center-topic-of-the-month-banner  learning-center">
            <IonItem>
              <div className="header-container">
                <div className="icon">
                  <svg width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.1806 3.95321C16.2619 2.59207 15.2119 1.32344 14.0306 0.186961C13.5712 -0.262346 12.7969 0.160531 12.9281 0.794847C13.1775 2.03705 13.44 3.6757 13.44 5.14255C13.44 7.86482 11.6681 10.0717 8.96437 10.0717C6.94312 10.0717 5.28937 8.84272 4.5675 7.08514C4.43625 6.82084 4.38375 6.66226 4.305 6.37154C4.16063 5.81651 3.43875 5.64472 3.12375 6.13367C2.8875 6.49047 2.66437 6.84727 2.45437 7.2305C0.8925 9.92634 0 13.0715 0 16.4281C0 22.2691 4.69875 27 10.5 27C16.3013 27 21 22.2691 21 16.4281C21 11.8161 19.5825 7.53445 17.1806 3.95321ZM10.1194 23.0355C7.78312 23.0355 5.89312 21.1854 5.89312 18.886C5.89312 16.7452 7.27125 15.2387 9.58125 14.763C11.5106 14.3666 13.4925 13.534 14.8706 12.2257C15.2381 11.8822 15.8419 12.0407 15.9469 12.5297C16.2487 13.8776 16.4062 15.2784 16.4062 16.6924C16.4194 20.1943 13.5975 23.0355 10.1194 23.0355Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="title">
                  <h4>{t('learning_center_tab_topic_of_the_month.title')}</h4>
                </div>
              </div>
            </IonItem>

            <div className="topic-of-the-month-container">
              <TopicOfTheMonth article={articleTopicOfTheMonth} />
            </div>
          </div>

          <div className="lc-articles-container">
            <IonCard className="lc-card-about">
              <IonCardHeader>
                <IonText>
                  <h4>{t('learning_center_tab_section.title')}</h4>
                </IonText>
              </IonCardHeader>

              <IonCardContent>
                {queryArticles.data?.data.data &&
                  queryArticles.data?.data.data.articles.map((elem: any, index: number) => {
                    return (
                      <IonItem lines="none" key={index} onClick={() => handleOpenArticle(elem)}>
                        <IonText color="primary">
                          <small>{elem.category}</small>
                          <h5>{elem.title}</h5>
                          <p>{elem.summary}</p>
                        </IonText>

                        <div className="lc-card-about-button">
                          <IonIcon icon={chevronForwardOutline} />
                        </div>
                      </IonItem>
                    );
                  })}

                <ExternalLinkModal
                  show={openArticle.open}
                  externalURL={openArticle.url}
                  handleOnhideModal={() => {
                    setOpenArticle(false);
                  }}
                ></ExternalLinkModal>
              </IonCardContent>
            </IonCard>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};
