import { useState, useEffect } from 'react';
import { Share } from '@capacitor/share';
import {
  IonButton,
  IonContent,
  IonItem,
  IonPage,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonIcon,
  IonFooter,
  useIonPopover,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { UserAccess } from '../../../common/hooks/user-access';
import { ShareTopicOfTheMonthCentralBanner } from './ShareTopicOfTheMonthCentralBanner';
import { URL_ABOUT_YOUR_HEALTH } from './../../../common/constants/routers';
import { SharePopover } from './../../../components/share-popover/index';
import { ArticleOfTheMonthState } from './../../../common/store-atom/learning-center-atom';
import { ProfileState } from './../../../common/store-atom/profile-atom';
import './styles.scss';

export const ShareTopicOfTheMonthView = ({ article, isReferenceLimitReached }) => {
  const { t } = useTranslation();
  var TinyURL = require('tinyurl');
  const articleTopicOfTheMonth = useRecoilValue(ArticleOfTheMonthState);
  const { isClient, isPartner } = UserAccess();
  const [openPopup, setOpenPopup] = useState(false);
  const profile = useRecoilValue(ProfileState);
  const [shareable, setShareable] = useState(false);
  const [hashedLink, setHashedLink] = useState('');
  const dialogTitle = 'Share Topic of the Month';

  const currentURL = 'https://dev-clients.livechair.co/topic-of-the-month';

  const title = 'Check this Health article';
  const text = isPartner
    ? t('share-topic-of-the-month-sharing-text-message_partner')
    : t('share-topic-of-the-month-sharing-text-message_partner');
  const articleId = article.id;
  const id = profile.id;
  const unHashedShareLink = `${currentURL}/?profileId=${id}&articleId=${articleId}`;
  const buttonClassName = isReferenceLimitReached
    ? 'share-topic-of-the-month-button-page-limit-reached'
    : 'share-topic-of-the-month-button-page';

  const hashShareLink = async (url) => {
    await TinyURL.shorten(url).then((res: string) => {
      setHashedLink(res);
    });
  };

  const onShareSuccess = () => {
    dismiss();
  };

  const share = async (ev) => {
    await Share.share({
      title: title,
      text: text,
      url: hashedLink,
      dialogTitle: dialogTitle,
    })
      .then(onShareSuccess)
      .catch((e) => {
        present({ event: ev.nativeEvent });
        if (e.toString().includes('AbortError')) {
          dismiss();
        }
      });
  };

  const [present, dismiss] = useIonPopover(SharePopover, {
    onHide: () => dismiss(),
    event: Event || undefined,
    article: articleTopicOfTheMonth,
    profile: profile,
    isShareable: shareable,
    isOpen: openPopup,
    setIsOpen: setOpenPopup,
  });

  const history = useHistory();

  const handleOnBack = () => {
    history.push(URL_ABOUT_YOUR_HEALTH);
  };

  useEffect(() => {
    hashShareLink(unHashedShareLink);
  });

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonItem lines="none" className="lc-back-arrow" slot="start" onClick={handleOnBack}>
              <IonIcon icon={arrowBack} color="primary" />
            </IonItem>
            <IonTitle color="primary">{t('share-topic-of-the-month-page-header.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div className="center-container">
            <div className="share-topic-of-the-month-page-container">
              <div className="header-logo">
                <img src="/assets/img/health-is-wealth-logo.svg" alt="Health is Wealth" />
              </div>

              <div className="header-title">
                <h5>
                  {t('share-topic-of-the-month-page.title')}
                  <br />
                  {t('share-topic-of-the-month-page.title2')}
                </h5>
              </div>

              <div className="share-topic-reward-image">
                <ShareTopicOfTheMonthCentralBanner />
              </div>

              <div className="share-topic-reward-image-summary">
                <p>
                  {t('share-topic-of-the-month-page-summary')}
                  <br />
                  {t('share-topic-of-the-month-page-summary2')}
                  <br />
                  {t('share-topic-of-the-month-page-summary3')}
                </p>
              </div>
            </div>
          </div>
        </IonContent>

        <IonFooter className="responsive-center-container">
          {isReferenceLimitReached ? (
            <div className="limit-reached-notification-banner ion-text-center">
              <p>{t('share-topic-of-the-month-page-limit-reached-banner-text')}</p>
            </div>
          ) : (
            <div className="footer-counter">
              <p>
                {t('share-topic-of-the-month-page-footer-counter.limit')}
                {'50'} {t('share-topic-of-the-month-page-footer-counter.limit2')}
              </p>
            </div>
          )}

          <IonButton
            onClick={(e) => {
              share(e);
            }}
            expand="block"
            className={buttonClassName}
            size="small"
            fill="clear"
          >
            <svg
              className="share-topic-icon"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.125 9.375C0.125 11.0979 1.52708 12.5 3.25 12.5C4.07708 12.5 4.825 12.1719 5.38437 11.6458L11.625 15.2125C11.6062 15.3479 11.5833 15.4833 11.5833 15.625C11.5833 17.3479 12.9854 18.75 14.7083 18.75C16.4312 18.75 17.8333 17.3479 17.8333 15.625C17.8333 13.9021 16.4312 12.5 14.7083 12.5C13.8812 12.5 13.1333 12.8281 12.574 13.3542L6.33333 9.78854C6.35208 9.65208 6.375 9.51667 6.375 9.375C6.375 9.23333 6.35208 9.09792 6.33333 8.96146L12.574 5.39583C13.1333 5.92188 13.8812 6.25 14.7083 6.25C16.4312 6.25 17.8333 4.84792 17.8333 3.125C17.8333 1.40208 16.4312 0 14.7083 0C12.9854 0 11.5833 1.40208 11.5833 3.125C11.5833 3.26667 11.6062 3.40208 11.625 3.53854L5.38437 7.10417C4.80834 6.55696 4.04452 6.25128 3.25 6.25C1.52708 6.25 0.125 7.65208 0.125 9.375Z"
                fill="white"
              />
            </svg>
            {t('button_title_share')}
          </IonButton>
        </IonFooter>
      </IonPage>
    </>
  );
};
