/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { useState, useRef, useEffect, useCallback, useContext } from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { PhoneAuthProvider } from 'firebase/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Address } from '@live-chair-health/lch-core';

import { AccountSetting, User } from '../../common/models/user';
import { formatCelPhone, formatGoogleCellPhone } from '../../common/utils/cel-phone';
import { formatDate } from '../../common/utils/date';
import { ModalWarning } from '../../components/modal-warning';
import { getLastNavigationPage } from '../../common/storage';
import { URL_MAIN_HOME } from '../../common/constants/routers';
import { isEmpty } from '../../common/utils/string-empty';
import { schemaAccountSettings } from './utils';
import { ItemFormGroup } from '../../components/item-form-group/item-form-group';
import { InputText } from '../../components/inputs/input-text';
import { InputNumber } from '../../components/inputs/input-number';
import { InputTextLocation } from '../../components/inputs/input-text-location';
import { OptionsModel } from '../../common/models/option-model';
import { options } from '../../common/utils/location-options';
import { placeToAddress } from '../../common/utils/place-to-address';
import { comesFromAccountSettingsState, phoneNumberState, verificationIdState, wasNewPhoneNumberVerifiedState } from '../../common/store-atom/register-atom';
import { generateRecaptcha, phoneAuth } from '../../common/firebase/phone-auth';
import { URL_REGISTER_PHONE_VERIFY } from '../../common/constants/routers';
import { addCountryCode } from '../../common/utils/cel-phone';

import { addressToString } from '../../common/utils/address-to-string';
import { FlagsContext } from '../../common/firebase/flags-providers';
import './styles.scss';

interface IAccountSettingViewProps {
  isClient: boolean;
  accountSetting: AccountSetting;
  barber_shop: OptionsModel[];
  allHairProfessional: OptionsModel[];
  callback: (value: number) => void;
  handleOnSave: (accountSeeting: AccountSetting) => void;
  submitFormSucces?: boolean;
  messageErrorFiled?: Array<any>;
  address?: string;
  originalAddress: Address;
  originalPhones: Array<string>;
  onChangesSuccess: (data: any) => void;
}

export const AccountSettingView = ({
  isClient,
  accountSetting,
  callback,
  handleOnSave,
  submitFormSucces = false,
  messageErrorFiled = [],
  originalAddress,
  originalPhones,
  onChangesSuccess,
}: IAccountSettingViewProps) => {
  const history = useHistory();
  const autoCompleteRef = useRef<HTMLInputElement | null>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t, i18n } = useTranslation();
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const [checked, setChecked] = useState(true);
  const [defaultHref, setDefaultHref] = useState<string>('');
  const [parsedAddress, setParsedAddress] = useState<Address>({});
  const [oldLanguage, setOldLanguage] = useState<string>(i18n.language);
  const [renderZipCode, setRenderZipCode] = useState<boolean>(false);
  const flags = useContext(FlagsContext);
  const setPhoneNumber = useSetRecoilState(phoneNumberState);
  const phoneNumber = useRecoilValue(phoneNumberState);
  const setVerificationId = useSetRecoilState(verificationIdState);

  const setComesFromAccountSettingsPage = useSetRecoilState(comesFromAccountSettingsState);
  const wasNewPhoneNumberVerified = useRecoilValue(wasNewPhoneNumberVerifiedState);
  const setWasNewPhoneNumberVerified = useSetRecoilState(wasNewPhoneNumberVerifiedState);

  const schema = schemaAccountSettings(isClient, flags?.isAddressAZipCode || false);

  const { register, handleSubmit, formState, setValue, getValues, setError } = useForm<User>({
    defaultValues: accountSetting,
    resolver: yupResolver(schema),
  });

  const { ref, ...rest } = register('address');

  const { isDirty } = formState;

  const setFormDefaultValues = () => {
    if (phoneNumber.phone && phoneNumber.phone.length) {
      // @ts-ignore
      setValue('phones', phoneNumber.phone);
    } else if (originalPhones.length) {
      // @ts-ignore
      setValue('phones', originalPhones[0]);
    }
  };

  const onSubmit = async (data: AccountSetting) => {
    data.isClient = isClient;
    data.barber_shop = data.barber_shop && Number(data.barber_shop);
    // @ts-ignore
    data.phones = Array.isArray(data.phones) ? data.phones : [formatCelPhone(data.phones || '')];

    data.date_of_birth = data.date_of_birth && formatDate(new Date(data.date_of_birth));
    data.language = data.language;
    data.smsNotifications = data.smsNotifications;
    
    const didPhoneChange = Array.isArray(data.phones) && originalPhones[0] !== data.phones[0];
    
    if (!renderZipCode) {
      let address = {};
      if (!Object.keys(parsedAddress).length || addressToString(parsedAddress) === accountSetting.address) {
        address = originalAddress;
      } else {
        address = parsedAddress;
      }

      data.address = address;
    }

    // delete data.phones;

    if (didPhoneChange) {
      const phone = addCountryCode(data.phones[0]);
      setPhoneNumber({ phone });

      generateRecaptcha();

      const appVerifier = window.recaptchaVerifier;

      const provider = new PhoneAuthProvider(phoneAuth);

      provider.verifyPhoneNumber(phone, appVerifier)
        .then((verificationId) => {
          setVerificationId({ verificationId });
          setComesFromAccountSettingsPage({ comesFromAccountSettings: true });
          history.push(URL_REGISTER_PHONE_VERIFY);
        });
    } else {
      handleOnSave(data);
    }
  };

  useEffect(() => {
    if (window.google) {
      // @ts-ignore
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

      // @ts-ignore
      autoCompleteRef.current.addListener('place_changed', async function () {
        // @ts-ignore
        const place = await autoCompleteRef.current.getPlace();
        const addressObj = placeToAddress(place);
        setParsedAddress({
          ...addressObj,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          placeID: place.place_id,
          ...(addressObj && {
            formattedAddress: addressToString(addressObj),
          }),
        });
      });
    }
  }, [window.google]);

  useEffect(() => {
    setRenderZipCode(flags?.isAddressAZipCode || false);
  }, [flags]);

  useEffect(() => {
    if (wasNewPhoneNumberVerified.wasNewPhoneNumberVerified) {
      handleOnSave({
        phones: [formatGoogleCellPhone(phoneNumber.phone)],
      });
      setWasNewPhoneNumberVerified({
        wasNewPhoneNumberVerified: false,
      });
      setVerificationId({
        verificationId: '',
      });
    }
  }, [wasNewPhoneNumberVerified]);

  useEffect(() => {
    setFormDefaultValues();
  }, [])

  const handleOnChangesmsNotifications = (e) => {
    setChecked(e);
    setValue('smsNotifications', e, { shouldDirty: true });
  };

  const hasChanged = () => {
    accountSetting.email = getValues().email;
    return JSON.stringify(accountSetting) === JSON.stringify(getValues());
  };

  const handleOnBack = () => {
    if (!submitFormSucces) {
      const formStateChanged = hasChanged();
      formStateChanged ? history.goBack() : setOpenModalWarning(true);
    } else {
      history.goBack();
    }
  };

  const setGoBackUrl = useCallback(async () => {
    const url = await getLastNavigationPage();
    setDefaultHref(url || URL_MAIN_HOME);
  }, []);

  const handleOnChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setValue('language', language, { shouldDirty: true });
  };

  const handleOnChangeBirthday = (e: any) => {
    setValue('date_of_birth', e, { shouldDirty: true });
  };
  const setErrorFileds = () => {
    if (messageErrorFiled) {
      messageErrorFiled.forEach((fieldName) =>
        setError(fieldName, { type: 'manual', message: t('page_profile_register.field_validation_error.' + fieldName) })
      );
    }
  };

  const setDefaultSmsNotification = () => {
    if (accountSetting.smsNotifications === true) {
      setChecked(true);
      return true;
    }
    setChecked(false);
    return false;
  };

  useEffect(() => {
    setGoBackUrl();

    if (accountSetting.language && !isEmpty(accountSetting.language)) {
      setOldLanguage(accountSetting.language);
    }

    if (messageErrorFiled.length) {
      setErrorFileds();
    }

    document.getElementById('label')?.click();
  }, [messageErrorFiled]);

  useEffect(() => {
    setDefaultSmsNotification();
  }, []);

  const renderAddressConditionalFields = () => {
    if (renderZipCode) {
      return (
        <ItemFormGroup label={t('page_setting_account.label_zip_code')} errorMessage={formState.errors.zipCode?.message}>
          <InputNumber name="zipCode" maxLength={5} register={register} />
        </ItemFormGroup>
      );
    } else {
      return (
        <ItemFormGroup label={t('page_setting_account.label_address')} errorMessage={(formState.errors.address as any)?.message}>
          <InputTextLocation passedRef={ref} rest={rest} inputRef={inputRef} />
        </ItemFormGroup>
      );
    }
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonItem lines="none" className="lc-back-arrow" slot="start" onClick={handleOnBack}>
              <IonIcon icon={arrowBack} color="primary" />
            </IonItem>
            <IonTitle color="primary">{t('page_setting_account.header_title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div id="sign-in-button" />

        <IonContent fullscreen class="lc-content-footer lc-content-padding">
          <div className="lc-acc-settings-container">
            <ModalWarning
              isOpen={openModalWarning}
              titleHeader={t('ModalWarning_titleHeader')}
              subTitleHeader={t('ModalWarning_subTitleHeader')}
              redirect={defaultHref}
              callback={() => setOpenModalWarning(false)}
              language={oldLanguage}
            />

            <form onSubmit={handleSubmit((data) => onSubmit(data))} noValidate>
              <IonItem lines="none" className="lc-form-group">
                <IonLabel position="stacked">{t('page_setting_account.label_language')}</IonLabel>

                <IonSelect
                  okText={t('button_title_apply')}
                  cancelText={t('button_title_cancel')}
                  {...register('language')}
                  onIonChange={(e) => handleOnChangeLanguage(e.detail.value)}
                >
                  <IonSelectOption key="en" value="en">
                    {t('english')}
                  </IonSelectOption>
                  <IonSelectOption key="es" value="es">
                    <p>{t('spanish')}</p>
                  </IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem lines="none" className="lc-form-group">
                <h5>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                      fill="#230870"
                    />
                  </svg>{' '}
                  {t('profile')}
                </h5>
              </IonItem>

              <div className="lc-form-col-2">
                <ItemFormGroup
                  label={t('page_setting_account.label_first_name')}
                  errorMessage={formState.errors.first_name?.message}
                >
                  <InputText name="first_name" register={register} />
                </ItemFormGroup>

                <ItemFormGroup
                  label={t('page_setting_account.label_last_name')}
                  errorMessage={formState.errors.last_name?.message}
                >
                  <InputText name="last_name" register={register} />
                </ItemFormGroup>
              </div>

              <ItemFormGroup
                label={t('page_setting_account.label_email')}
                helperText={t('page_setting_account.contact_update_helper_text')}
              >
                <input className="lc-input" disabled={flags?.disable_contact_info_edit} value={accountSetting?.email || ''} />
              </ItemFormGroup>

              <ItemFormGroup
                label={t('page_setting_account.label_cell_phone')}
              >
                <InputMask className="lc-input" mask="(999) 999 9999" {...register('phones')} />
              </ItemFormGroup>

              {renderAddressConditionalFields()}

              <ItemFormGroup
                label={t('page_setting_account.label_date_of_birth')}
                errorMessage={formState.errors.date_of_birth?.message}
              >
                {/* @ts-ignore */}
                <IonDatetime
                  doneText={t('button_title_apply')}
                  cancelText={t('button_title_cancel')}
                  displayFormat={t('page_setting_account.displayFormat')}
                  onIonChange={(e) => handleOnChangeBirthday(e.detail.value)}
                  {...register('date_of_birth')}
                />
              </ItemFormGroup>

              <IonItem lines="none" className="lc-form-group">
                <svg
                  className="label-icon"
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.58648 19.5C8.68648 19.5 9.58648 18.6 9.58648 17.5H5.58648C5.58648 18.6 6.47648 19.5 7.58648 19.5ZM13.5865 13.5V8.5C13.5865 5.43 11.9465 2.86 9.08648 2.18V1.5C9.08648 0.67 8.41648 0 7.58648 0C6.75648 0 6.08648 0.67 6.08648 1.5V2.18C3.21648 2.86 1.58648 5.42 1.58648 8.5V13.5L0.296477 14.79C-0.333523 15.42 0.106477 16.5 0.996477 16.5H14.1665C15.0565 16.5 15.5065 15.42 14.8765 14.79L13.5865 13.5Z"
                    fill="#230870"
                  />
                </svg>
                <h5>{t('page_setting_account.label_notifications')}</h5>
              </IonItem>

              <IonItem lines="none">
                <IonLabel className="sms-notifications-label">
                  <h5>
                    <strong>{t('page_setting_account.label_notifications_enable_toggle.label')}</strong>
                  </h5>
                </IonLabel>
                <IonCheckbox
                  {...register('smsNotifications')}
                  checked={checked}
                  onIonChange={(e) => handleOnChangesmsNotifications(e.detail.checked)}
                  name="smsNotifications"
                  className="check-box"
                  slot="start"
                  mode="md"
                />
              </IonItem>
              <IonFooter className="lc-footer responsive-center-container">
                <IonButton
                  className="save-button-account-settings"
                  type="submit"
                  color={!isDirty ? 'medium' : 'primary'}
                  disabled={!isDirty}
                  expand="block"
                >
                  {t('page_setting_account.button_save')}
                </IonButton>
              </IonFooter>
            </form>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
