import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

/**Icon */
import { logoApple } from 'ionicons/icons';

/**Component */
import { SocialNetworkButton } from '../social-network-button';

export const AppleButton: React.FC = () => {
  /**Contants */
  const appleTitle = 'Apple';
  const appleProvider = new firebase.auth.OAuthProvider('apple.com');

  return (
    <SocialNetworkButton
      title={appleTitle}
      className="register-button"
      icon={logoApple}
      provider={appleProvider}
    />
  );
};
