interface IInputTextLocation {
  rest: any;
  passedRef: any;
  inputRef: any;
}

export const InputTextLocation = ({ inputRef, rest, passedRef }: IInputTextLocation) => (
  <input
    className="lc-input"
    ref={(e) => {
      passedRef(e);
      inputRef.current = e;
    }}
    {...rest}
  />
);
