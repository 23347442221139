import { FC } from 'react';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { IonButton, IonCard, IonContent, IonInput, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilValue } from 'recoil';

import { saveBiometrics } from '../../../../common/adapters/biometrics-adapter';
import { Biometrics } from '../../../../common/models/biometrics';
import { MessageToastHooks } from '../../../../common/hooks/message-toast';
import { URL_MAIN_HEALTH, URL_MAIN_HEALTH_RECORDS } from '../../../../common/constants/routers';
import { ItemFormGroup } from '../../../../components/item-form-group/item-form-group';
import { Spinner } from '../../../../components/spinner/spinner';
import TopCard from '../../../../components/top-card';
import { analytics } from '../../../../common/firebase/firebase';
import { UserState } from '../../../../common/store-atom/user-atom';
import '../styles.scss';

const LogBiometricsPage: FC = () => {
  const { t } = useTranslation();
  const { messageToastError } = MessageToastHooks();
  const queryClient = useQueryClient();
  const history = useHistory();
  const userSelector = useRecoilValue(UserState);

  const bpValidationData = yup
    .number()
    .typeError(t('message_field_required'))
    .required(t('message_field_required'))
    .min(20, t('value_low'))
    .max(400, t('value_high'));

  const schema = yup.object().shape({
    systole: bpValidationData,
    diastole: bpValidationData,
    weight: yup
      .number()
      .typeError(t('message_field_required'))
      .required(t('message_field_required'))
      .min(5, t('value_low'))
      .max(1400, t('value_high')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { systole: null, diastole: null, weight: null },
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    logEvent(analytics, 'log_blood_pressure', { userId: userSelector.id });
    queryClient.invalidateQueries('getAllBiometrics');
    reset();
    history.replace(URL_MAIN_HEALTH_RECORDS);
  };

  const onError = (error: AxiosError) => messageToastError(error);

  const mutation = useMutation(saveBiometrics, {
    onSuccess,
    onError,
  });

  const onSubmit = (data: any) => {
    const biometrics: Biometrics = {
      systole: Number(data.systole),
      diastole: Number(data.diastole),
      weight: Number(data.weight),
    };
    mutation.mutate({ ...biometrics });
  };

  return (
    <IonPage>
      <Spinner isOpen={mutation.isLoading} />
      <IonContent fullscreen className="lc-full-spot-bg">
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: '400px' }}>
            <TopCard message={`🫀 ${t('biometric_card_protect_heart')}`} redirectTo={URL_MAIN_HEALTH} />

            <div className="log-biometrics-card">
              <IonCard>
                <h3>{t('biometrics_tab_add_biometrics_blood_pressure_field.label')}</h3>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="fields-section">
                    <div>
                      <section>
                        <ItemFormGroup
                          label={t('biometrics_tab_add_biometrics_sistolic.label')}
                          errorMessage={errors.systole?.message}
                        >
                          <IonInput
                            placeholder="XXX"
                            id="systole"
                            color="primary"
                            type="number"
                            // @ts-ignore
                            min="20"
                            // @ts-ignore
                            max="400"
                            {...register('systole')}
                          />
                        </ItemFormGroup>
                      </section>

                      <section>
                        <ItemFormGroup
                          label={t('biometrics_tab_add_biometrics_diastolic.label')}
                          errorMessage={errors.diastole?.message}
                        >
                          <IonInput
                            placeholder="XXX"
                            id="diastole"
                            color="primary"
                            type="number"
                            // @ts-ignore
                            min="20"
                            // @ts-ignore
                            max="400"
                            {...register('diastole')}
                          />
                        </ItemFormGroup>
                      </section>
                    </div>

                    <section>
                      <ItemFormGroup
                        label={t('biometrics_tab_add_biometrics_weight.label')}
                        errorMessage={errors.weight?.message}
                      >
                        <IonInput
                          placeholder="XXX"
                          id="weight"
                          color="primary"
                          type="number"
                          // @ts-ignore
                          min="5"
                          // @ts-ignore
                          max="1400"
                          {...register('weight')}
                        />
                      </ItemFormGroup>
                    </section>
                  </div>
                  <IonButton type="submit" expand="block">
                    {t('button_title_save')}
                  </IonButton>
                </form>
              </IonCard>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LogBiometricsPage;
