import { Route, Redirect } from 'react-router-dom';
import { REPRESENTATIVE_URL_LOGIN } from '../../common/constants/routers';
import { userUrlProvider } from '../../common/hooks/useUrlProvider';
import { Spinner } from '../spinner/spinner';

const GuardedRoute = ({ validTypes, component: Component, ...rest }) => {
  const { userType, isLoadingAuth } = userUrlProvider();

  const isValid = validTypes.indexOf(userType) > -1 ? true : false;

  return isLoadingAuth ? (
    <Spinner isOpen />
  ) : (
    <Route
      {...rest}
      render={(props) => (isValid ? <Component {...props} /> : <Redirect to={REPRESENTATIVE_URL_LOGIN} />)}
    ></Route>
  );
};

export default GuardedRoute;
