import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';
import { getFirestore } from 'firebase/firestore';
import * as config from './config';

export const firebaseConfig = config.getConfigFireBase();

// Initialize Firebase
export const fireBaseApp = initializeApp(firebaseConfig);

export const analytics = getAnalytics(fireBaseApp);

export const auth = getAuth(fireBaseApp);

export const remoteConfig = getRemoteConfig(fireBaseApp);

export const db = getFirestore(fireBaseApp);
