import { Plugin } from 'chart.js';
import { BiometricsResponse } from '../../common/models/biometrics';

export const plugin: Plugin = {
  id: 'custom_canvas_background_color',
  beforeDraw: (chart) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#FFB4AB';
    ctx.fillRect(0, 0, chart.width, chart.height / 4);
    ctx.fillStyle = '#FFCFAE';
    ctx.fillRect(0, chart.height * 0.25, chart.width, chart.height / 4);
    ctx.fillStyle = '#FFECC0';
    ctx.fillRect(0, chart.height * 0.5, chart.width, chart.height / 4);
    ctx.fillStyle = '#DAF4E1';
    ctx.fillRect(0, chart.height * 0.75, chart.width, chart.height / 4);
    ctx.restore();
  },
};

const hiddenGridsAndTicks = {
  ticks: {
    display: false,
  },
  grid: {
    display: false,
    drawBorder: false,
  },
};

export const options = {
  layout: { padding: 0 },
  maintainAspectRatio: false,
  scales: {
    x: { ...hiddenGridsAndTicks },
    y: { ...hiddenGridsAndTicks, min: 0, max: 100 },
  },
};

// Each value returned by this
// object literal corresponds
// to a point in the Y axis of the graph
const GraphPointProvider = {
  LOW: 7,
  NORMAL: 7,
  ELEVATED: 35,
  HIGH_STAGE_1: 63,
  HIGH_STAGE_2: 91,
  HYPERTENSIVE_CRISIS: 91,
};

export const buildGraphData = (biometricsLogs: BiometricsResponse[]) => {
  let labels: string[] = [];
  let datasets: { data: number[]; backgroundColor: ['black']; tension: number }[] = [
    {
      data: [],
      backgroundColor: ['black'],
      tension: 0.3,
    },
  ];

  biometricsLogs.map((biometric) => {
    labels.push('');
    datasets[0].data.push(GraphPointProvider[biometric.bloodPressureCategory]);
  });

  // Without this reverse, the data
  // is shown in the graph in descending
  // order, from newer <- to -> older
  datasets[0].data.reverse();

  return { labels, datasets };
};
