import { menuController } from '@ionic/core';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IonMenu, IonHeader, IonToolbar, IonContent, IonList, IonTitle, IonIcon } from '@ionic/react';
import { closeOutline, documentText, lockClosed, logOutOutline, personCircle } from 'ionicons/icons';
import { ItemMenu } from './components/item-menu';
import { ItemMenuLogOut } from './components/item-menu-log-out';
import { AuthType } from '../../common/enums/auth-type';
import { UserState } from '../../common/store-atom/user-atom';
import { ProfileState } from './../../common/store-atom/profile-atom';
import { URL_ACCOUNT_SETTINGS, URL_CHANGE_PASSWORD, URL_TERMS_CONDITIONS } from '../../common/constants/routers';
import './styles.scss';

export const LCHeaderMenu: React.FC = () => {
  const profileState = useRecoilValue(ProfileState);
  const userState = useRecoilValue(UserState);
  const { t } = useTranslation();

  return (
    <IonMenu side="end" contentId="main-content">
      <IonHeader>
        <IonToolbar>
          <IonTitle color="primary">
            <span>{profileState.first_name}</span> {profileState.last_name}
          </IonTitle>
          <IonIcon src={closeOutline} color="primary" onClick={async () => await menuController.toggle()} />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonList>
          <ItemMenu title={t('header_menu.title_account_settings')} url={URL_ACCOUNT_SETTINGS} icon={personCircle} />
          {userState.authType === AuthType.emailAndPassword && (
            <ItemMenu title={t('header_menu.title_change_password')} url={URL_CHANGE_PASSWORD} icon={lockClosed} />
          )}
          <ItemMenu title={t('header_menu.title_terms_condition')} url={URL_TERMS_CONDITIONS} icon={documentText} />

          <ItemMenuLogOut title={t('header_menu.title_log_out')} icon={logOutOutline} />
        </IonList>
      </IonContent>
    </IonMenu>
  );
};
