import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export function startLogrocket() {
  LogRocket.init(String(process.env.REACT_APP_LOG_ROCKET_APP_ID), {
    shouldCaptureIP: false,
    shouldDebugLog: false,
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
    network: {
      isEnabled: false,
    },
  });

  setupLogRocketReact(LogRocket);
}

export const identifyUser = async (identifier: string | null | undefined = '') => {
  LogRocket.identify(String(identifier));
};
