import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { IonCheckbox, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

import { SlideButtons } from '../buttons';
import { SliderBody } from '../slider-body';
import { SliderHeader } from '../slider-header';
import { ISliderProps } from '../slider';
import { UserHealthState } from '../../../../common/store-atom/user-health-atom';
import { mapUniqueCheckboxWithSuboptions, getQuestionTitle } from '../../utils/utils';

export const UniqueCheckboxWithSuboptionsQuestion = ({
  swiper,
  setActiveIndex,
  verifyLanguageSetting,
  question,
  index,
  isLast,
  isRepresentativeHRA,
}: ISliderProps) => {
  const [suboptions, setSuboptions] = useState<Array<any>>([]);
  const [dataOnly, setDataOnly] = useState<Array<any>>([]);
  const [selected, setSelected] = useState<string | null>(null);
  const [userHealthState, setUserHealthState] = useRecoilState(UserHealthState);

  const setMapQuestion = () => {
    const data = mapUniqueCheckboxWithSuboptions(question, verifyLanguageSetting());
    setDataOnly(data.filter((x) => x.values.length === 1));
    setSuboptions([...data.filter((x) => x.values.length > 1)]);
  };

  const handleOnChange = (value: string) => {
    setSelected(value);
    setUserHealthState({ ...userHealthState, [question.id]: value });
    setActiveIndex(question.id);
    setDataOnly(
      [...dataOnly].map((element: any) => {
        element.values[0].selected = element.values[0].name === value;
        return element;
      })
    );
  };

  const handleCheck = (value: string) => {
    setDataOnly(
      [...dataOnly].map((element: any) => {
        element.values[0].selected = element.values[0].name === value;
        return element;
      })
    );
    setUserHealthState({ ...userHealthState, [question.id]: value });
    setSelected(value);
  };

  useEffect(() => {
    setMapQuestion();
  }, [question]);

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <SliderBody>
          <div className="lc-slider-wrapper">
            <SliderHeader description={getQuestionTitle(question, verifyLanguageSetting())} />
            {suboptions &&
              suboptions.map((element: any, index: number) => {
                return (
                  <div key={index} className="lc-select-list">
                    <IonLabel color="primary">{element.name}</IonLabel>
                    <IonSelect okText="Apply" value={selected} onIonChange={(e) => handleOnChange(e.detail.value)}>
                      {element.values.map((elem: any, i: number) => {
                        return (
                          <IonSelectOption key={`${i}-select-option`} value={elem.value}>
                            {elem.name}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  </div>
                );
              })}
            {dataOnly &&
              dataOnly.map((element: any, index: number) => {
                return (
                  <div key={`${index}-checkbox`} className="lc-select-list" onClick={() => handleCheck(element.values[0].name)}>
                    <IonLabel color="primary">{element.name}</IonLabel>
                    <IonCheckbox
                      value={element.values[0].name}
                      className="lc-checkbox"
                      slot="end"
                      checked={element.values[0].selected}
                    />
                  </div>
                );
              })}
          </div>
        </SliderBody>

        <SlideButtons
          swiper={swiper}
          disabled={selected === null}
          showBackButton={index > 0}
          buttonFinish={isLast}
          isRepresentativeHRA={isRepresentativeHRA}
        />
      </div>
    </>
  );
};
