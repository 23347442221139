import { IonIcon, IonItem, IonText } from '@ionic/react';

interface IItemMenuProps {
  icon: string;
  title: string;
  url?: string;
}
export const ItemMenu = ({ title, url, icon }: IItemMenuProps) => {
  return (
    <>
      <IonItem key={title} routerLink={url} detail={false}>
        <IonText>
          <IonIcon src={icon} />
          <p>{title}</p>
        </IonText>
      </IonItem>
    </>
  );
};
