import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { addStripeAccount, getRewards, getPayoutAccountType, payoutRewards } from '../../../common/adapters/earnings-adapter';
import { PAYPAL_LINK } from '../../../common/constants/paypal-url';
import { EarningsAmountState } from '../../../common/store-atom/earnings-atom';
import { TabMenuState } from '../../../common/store-atom/tab-menu-atom';
import { Spinner } from '../../../components/spinner/spinner';
import { ProfileState } from './../../../common/store-atom/profile-atom';
import { PayoutAccountType } from './enums/payout-account-type';
import {
  URL_MAIN_EARNINGS_CONFIRMATION,
  URL_MAIN_EARNINGS_ERROR,
  URL_MAIN_PHONE_NUMBER_VERIFICATION,
} from '../../../common/constants/routers';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRow,
  IonText,
  useIonPopover,
  useIonToast,
} from '@ionic/react';
import './styles.scss';

interface PayoutAccountProps {
  accountType: number;
  setIsLoading: (b: boolean) => void;
}

const PayoutAccountHeader: React.FC<PayoutAccountProps> = ({ accountType, setIsLoading }) => {
  const { t } = useTranslation();
  const [present, dismiss] = useIonToast();

  const mutationLinkStripeAccount = useMutation(addStripeAccount, {
    onSuccess: (data: any) => {
      window.open(data.data.data.stripeLink, '_self');
    },
    onError: (error: any) => {
      present({
        color: 'danger',
        buttons: [{ text: 'Ok', handler: () => dismiss() }],
        message: error.response.data.message.join('\r\n'),
      });
    },
  });

  const stripeAccountLinkHandler = () => {
    setIsLoading(true);
    mutationLinkStripeAccount.mutate();
  };

  const paypalAccountLinkHandler = () => {
    window.open(PAYPAL_LINK, '_self');
  };

  const [showEditAccountPopover] = useIonPopover(EditAccountPopover, {
    arrow: false,
    accountType,
    onSwitchToPayPal: paypalAccountLinkHandler,
    onSwitchToStripe: stripeAccountLinkHandler,
    onEditAccount: accountType === PayoutAccountType.STRIPE ? stripeAccountLinkHandler : paypalAccountLinkHandler,
  });

  const editAccountHandler: React.MouseEventHandler<HTMLIonButtonElement> = (e) => {
    showEditAccountPopover({
      cssClass: 'lc-content-padding',
      event: e.nativeEvent,
    });
  };

  const editAccountHandlerWrapper = (e) => editAccountHandler(e);

  if (!accountType) {
    return (
      <IonGrid>
        <IonRow>
          <IonCol className="ion-no-padding banner-link-account-container">
            <IonText color="primary">
              <h5 className="lc-bold">{t('provider_earnings_tab_sub_title')}</h5>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-no-padding">
            <IonButton className="stripe-button" expand="block" fill="outline" onClick={stripeAccountLinkHandler}>
              <IonIcon name="stripe-logo" src="assets\img\stripe-wordmark.svg" />
            </IonButton>
          </IonCol>
          <IonCol className="ion-no-padding">
            <IonButton className="paypal-button" fill="outline" expand="block" onClick={paypalAccountLinkHandler}>
              <IonIcon name="paypal-logo" src="assets\img\paypal-wordmark.svg" />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }

  if (accountType === PayoutAccountType.PAYPAL) {
    return (
      <IonGrid>
        <IonRow>
          <IonCol className="ion-no-padding paypal-link-confirmation">
            <IonText className="ion-text-center" color="primary">
              <h5 className="lc-bold">{t('provider_earnings_tab_paypal_account_link_confirmation.label')}</h5>
            </IonText>
            <div className="account-info-container-paypal">
              <IonIcon name="paypal-logo" src="assets\img\paypal-wordmark.svg" />
              <IonIcon onClick={editAccountHandlerWrapper} size="tiny" name="edit-button" src="assets\icon\common\edit.svg" />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  }
  return accountType === PayoutAccountType.STRIPE ? (
    <IonGrid>
      <IonRow>
        <IonCol className="ion-no-padding stripe-link-confirmation">
          <IonText className="ion-text-center" color="primary">
            <h5 className="lc-bold">{t('provider_earnings_tab_stripe_account_link_confirmation.label')}</h5>
          </IonText>
          <div className="account-info-container-stripe">
            <IonIcon name="stripe-logo" src="assets\img\stripe-wordmark.svg" />
            <IonIcon onClick={editAccountHandlerWrapper} size="tiny" name="edit-button" src="assets\icon\common\edit.svg" />
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  ) : (
    <IonGrid />
  );
};

interface EditAccountPopoverProps {
  accountType: PayoutAccountType;
  onSwitchToPayPal: () => void;
  onSwitchToStripe: () => void;
  onEditAccount: () => void;
}

const EditAccountPopover: React.FC<EditAccountPopoverProps> = ({
  accountType,
  onSwitchToPayPal,
  onSwitchToStripe,
  onEditAccount,
}) => {
  const { t } = useTranslation();

  return (
    <IonList className="ion-text-center ion-padding stripe-paypal-popup">
      <IonItem className="ion-text-center" detail={false} lines="full" button onClick={onEditAccount}>
        <strong> {t('provider_earnings_edit_account_button.label')}</strong>
      </IonItem>
      {accountType === PayoutAccountType.PAYPAL ? (
        <IonItem className="ion-text-center" detail={false} lines="none" button onClick={onSwitchToStripe}>
          <strong>{t('provider_earnings_switch_to_stripe_button.label')}</strong>
          <IonIcon src="/assets/img/stripe-square.svg" />
        </IonItem>
      ) : (
        <IonItem className="ion-text-center" detail={false} lines="none" button onClick={onSwitchToPayPal}>
          <strong>{t('provider_earnings_switch_to_paypal_button.label')}</strong>
          <IonIcon src="/assets/img/paypal-square.svg" />
        </IonItem>
      )}
    </IonList>
  );
};

export const TabEarningsPage = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tabMenuState = useRecoilValue(TabMenuState);
  const earningsSetState = useSetRecoilState(EarningsAmountState);
  const profileState = useRecoilValue(ProfileState);
  const isCellphoneVerified = profileState?.cell_phone_verified;
  const queryRewards = useQuery('getRewards', getRewards);

  const queryAccountType = useQuery('getPayoutAccountType', getPayoutAccountType);
  const accountType = queryAccountType.data?.data.data.accountType;

  const { t } = useTranslation();
  const redirectEarnings = () => {
    if (isCellphoneVerified) {
      return;
    } else {
      return history.push(URL_MAIN_PHONE_NUMBER_VERIFICATION);
    }
  };

  const cashOutMutation = useMutation(payoutRewards, {
    onSuccess: (data: any) => {
      const amount = [...data.data.data].reduce((cont: number, elem: any) => {
        return cont + elem.amount;
      }, 0);
      earningsSetState(amount);
      history.push(URL_MAIN_EARNINGS_CONFIRMATION);
    },
    onError: () => {
      history.push(URL_MAIN_EARNINGS_ERROR);
    },
  });

  const handlePayoutPendingRewards = () => {
    cashOutMutation.mutate();
  };

  const pendingAmount = queryRewards.data?.data.data.pendingAmount | 0;
  const totalAmount = queryRewards.data?.data.data.totalAmount | 0;

  const disableCashOutButton = () => {
    return pendingAmount == 0 || accountType === PayoutAccountType.UNDEFINED || isCellphoneVerified === false;
  };

  useEffect(() => {
    if (tabMenuState === 'earnings') {
      queryRewards.refetch();
      redirectEarnings();
    }
  }, [isCellphoneVerified, tabMenuState, history]);

  return (
    <IonPage>
      <IonContent fullscreen className="lc-full-spot-bg lc-earning">
        <Spinner isOpen={queryRewards.isLoading || queryAccountType.isLoading || isLoading} />

        <div className="lc-earning-intro">
          <PayoutAccountHeader accountType={accountType} setIsLoading={setIsLoading} />
        </div>

        <Spinner isOpen={cashOutMutation.isLoading} />

        <div className="block-center-earning-page">
          <div className="lc-earning-card">
            <IonCard className="lc-pink-card">
              <IonCardHeader>
                <IonText color="primary">{t('provider_earnings_to_redeem_card.title')}</IonText>
              </IonCardHeader>
              <IonCardContent>
                <IonText color="primary">
                  <h1>
                    $ {pendingAmount}
                    {pendingAmount ? (
                      ''
                    ) : (
                      <IonButton
                        className={disableCashOutButton() ? 'cash-out-disabled' : ''}
                        expand="block"
                        color="warning"
                        disabled={disableCashOutButton()}
                        onClick={disableCashOutButton() ? undefined : handlePayoutPendingRewards}
                      >
                        {t('provider_earnings_to_redeem_cash_out_button.label')}
                      </IonButton>
                    )}
                  </h1>
                </IonText>
              </IonCardContent>
              <IonItem lines="none">
                {queryRewards.data?.data.data.pendingAmount == 0 ? (
                  <div className="lc-earning-card-pending">
                    <IonText className="ion-text-center">
                      <h4 className="lc-semi-bold">{t('provider_earnings_to_redeem_no_money_message')}</h4>
                    </IonText>
                    <IonText className="ion-text-center" color="primary">
                      <p>{t('provider_earnings_to_redeem_no_money_sub_title')}</p>
                    </IonText>
                  </div>
                ) : (
                  <IonButton
                    className={disableCashOutButton() ? 'cash-out-disabled' : ''}
                    expand="block"
                    color="warning"
                    disabled={disableCashOutButton()}
                    onClick={disableCashOutButton() ? undefined : handlePayoutPendingRewards}
                  >
                    {t('provider_earnings_to_redeem_cash_out_button.label')}
                  </IonButton>
                )}
              </IonItem>
            </IonCard>
          </div>
        </div>
        <div className="block-center-earning-page">
          <div className="lc-earning-total">
            <IonCard>
              <IonCardHeader className="d-flex ion-justify-space-between ion-align-items-center">
                <IonText>
                  <p className="ion-no-margin">{t('provider_earnings_to_redeem_total_earnings.label')}</p>
                </IonText>
                <IonText color="dark">
                  <h4 className="lc-bold ion-text-right">${totalAmount}</h4>
                </IonText>
              </IonCardHeader>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
