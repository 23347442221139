import React from 'react';
import { IonCard } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format } from 'date-fns';

import { BiometricsResponse } from '../../../../../common/models/biometrics';
import './styles.scss';

interface BloodPressureCardProps {
  BPData: BiometricsResponse;
}

const BloodPressureCard: React.FC<BloodPressureCardProps> = ({
  BPData: { createdAt, diastole, systole, bloodPressureCategory },
}) => {
  const { t } = useTranslation();

  const BPStatus = {
    LOW: { text: t('biometric_blood_pressure_banner_low_text'), color: '#D7F0FD' },
    NORMAL: { text: t('biometric_blood_pressure_banner_normal_text'), color: '#DAF4E1' },
    ELEVATED: { text: t('biometric_blood_pressure_banner_elevated_text'), color: '#FFECC0' },
    HIGH_STAGE_1: { text: `${t('biometric_blood_pressure_banner_stage_text')} 1`, color: '#FFCFAE' },
    HIGH_STAGE_2: { text: `${t('biometric_blood_pressure_banner_stage_text')} 2`, color: '#FFB4AB' },
    HYPERTENSIVE_CRISIS: { text: t('biometric_blood_pressure_banner_get_medical_care'), color: '#EB4335' },
  };

  const setHyperTensionCrisisStyle = (status: string) => {
    return status === t('biometric_blood_pressure_banner_get_medical_care');
  };

  return (
    <IonCard className="bp" style={{ backgroundColor: BPStatus[bloodPressureCategory].color }}>
      <section className="bp-date">
        <span>{format(new Date(createdAt), 'MMM')}</span>
        <span>{format(new Date(createdAt), 'dd')}</span>
      </section>

      <section className="bp-content">
        <section>
          <span>{systole}</span>
          <span>{t('biometrics_tab_biometrics_card_systolic.label')}</span>
        </section>
        <hr />
        <section>
          <span>{diastole}</span>
          <span>{t('biometrics_tab_biometrics_card_diastolic.label')}</span>
        </section>
      </section>
      <section className={clsx('bp-status', setHyperTensionCrisisStyle(BPStatus[bloodPressureCategory].text) && 'bp-ht-crisis')}>
        {BPStatus[bloodPressureCategory].text}
      </section>
    </IonCard>
  );
};

export default BloodPressureCard;
