import React from 'react';
import { IonCard, IonText, IonCardHeader } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import FamilyMemberList from './FamilyMemberList';
import { IFamilyMember } from '../../../../common/models/households';
import '../styles.scss';

const CardSchedule: React.FC<{ familyMembers: IFamilyMember[]; present(): void }> = ({ familyMembers, present }) => {
  const { t } = useTranslation();

  return (
    <IonCard className="lc-care-card-schedule">
      <IonCardHeader className="lc-care-card-info-header" />
      <section>
        <IonText>{t('care_tab_appointment_card_text')}</IonText>

        <FamilyMemberList list={familyMembers} handleModal={present} />
      </section>
    </IonCard>
  );
};

export default CardSchedule;
