import { FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { checkmarkCircle } from 'ionicons/icons';
import { IonButton, IonCard, IonContent, IonFooter, IonIcon, IonItem, IonPage, IonRouterLink } from '@ionic/react';

import { URL_MAIN_EARNINGS } from '../../../../common/constants/routers';
import { EarningsAmountState } from '../../../../common/store-atom/earnings-atom';
import './styles.scss';

export const EarningsConfirmationPage: FC = () => {
  const earningsState = useRecoilState(EarningsAmountState);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    queryClient.invalidateQueries('getRewards');
  });

  return (
    <IonPage>
      <IonContent fullscreen className="lc-content-footer-with-tabs lc-confirmation-earnings-tab ion-no-padding">
        <IonItem color="success" className="ion-text-center">
          <article>
            <IonIcon slot="start" icon={checkmarkCircle} />
            <h4>
              {t('provider_earnings_confirmation.title_part_one')} <br /> {t('provider_earnings_confirmation.title_part_two')}
              💰💸
            </h4>
          </article>
        </IonItem>

        <div className="message-container">
          <IonCard color="light" className="ion-text-center">
            <div className="coin">
              <span>${earningsState}</span>
            </div>
            <br />
            <h5>{t('provider_earnings_confirmation.sub_title')}</h5>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter className="lc-footer-with-tabs">
        <IonRouterLink routerLink={URL_MAIN_EARNINGS}>
          <IonButton expand="block">{t('provider_earnings_back_to_earnings_button.label')}</IonButton>
        </IonRouterLink>
      </IonFooter>
    </IonPage>
  );
};
