import { IonPage, IonContent } from '@ionic/react';
import { TFunction } from 'react-i18next';

import { Spinner } from '../../../../../components/spinner/spinner';
import { CSROrganization } from './RepresentativeOrganizationsContainer';

interface RepresentativeOrganizationsViewProps {
  t: TFunction<'translation'>;
  organizationsList: Array<CSROrganization>;
  isLoading: boolean;
}

export const RepresentativeOrganizationsView = ({ t, organizationsList, isLoading }: RepresentativeOrganizationsViewProps) => (
  <IonPage>
    <IonContent className="representative-users-content" fullscreen color="background">
      <h1 className="title">{t('representative-organizations-title')}</h1>
      <div className="list-container">
        <div className="row">
          <div className="list-item">
            <h2>Name</h2>
          </div>
          <div className="list-item">
            <h2>Address</h2>
          </div>
          <div className="list-item">
            <h2>Type</h2>
          </div>
        </div>
        <Spinner isOpen={isLoading} />
        {organizationsList.map(({ id, address, name, createdAt, type }) => (
          <div key={id} className="row">
            <div className="list-item client">
              <h4>{name}</h4>
              <span>{createdAt}</span>
            </div>
            <div className="list-item next-step">{address}</div>
            <div className="list-item">
              <h5>{type}</h5>
            </div>
          </div>
        ))}
      </div>
    </IonContent>
  </IonPage>
);
