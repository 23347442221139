import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IonPage, IonContent, IonButton } from '@ionic/react';

import { VerificationLogo } from './VerificationLogo';
import { URL_ACCOUNT_SETTINGS, URL_MAIN_HOME } from '../../../common/constants/routers';
import { Spinner } from '../../../components/spinner/spinner';
import { getPatient } from '../../../common/adapters/user-profile-adapter';
import './styles/cellphoneVerification.scss';

interface ViewProps {
  isLoading: boolean;
  createVerificationCode(): void;
}

export const CellphoneVerificationView: React.FC<ViewProps> = ({ isLoading, createVerificationCode }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState('');

  const history = useHistory();

  const handleGoToEditNumber = () => {
    return history.push(URL_ACCOUNT_SETTINGS);
  };

  useEffect(() => {
    getPatient()
      .then((result) => {
        setPhone(result.data.data.phones.slice(-4));
        setLoading(false);
      })
      .catch(() => {
        history.push(URL_MAIN_HOME);
        setLoading(false);
      });
  }, []);

  return (
    <IonPage>
      {loading ? (
        <Spinner isOpen={loading} />
      ) : (
        <IonContent fullscreen ion-text-center>
          <Spinner isOpen={isLoading} />
          <div className="illustration-container">
            <VerificationLogo />
          </div>
          <div className="block-center-profile-register">
            <div className="lc-profile-register-buttons-container">
              <div className="title-container ion-text-center">
                <h4>{t('mobile_verification_index_title')}</h4>
              </div>
              <div className="sub-title-container">
                <p>
                  {t('mobile_verification_index_please_verify_message-1')} '{phone}'{' '}
                  {t('mobile_verification_index_please_verify_message-2')}
                </p>
              </div>
              <div className="buttons-container">
                {' '}
                <IonButton onClick={createVerificationCode} className="lch-send-verification-code-button" expand="block">
                  {t('mobile_verification_index_send_code_button-label')}
                </IonButton>
                <IonButton onClick={handleGoToEditNumber} className="lch-edit-number-button" expand="block">
                  {t('mobile_verification_index_edit_number_button-label')}
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      )}
    </IonPage>
  );
};
