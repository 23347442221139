import { useEffect, useState } from 'react';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { LANGUAGE_ENGLISH, LANGUAGE_SPANISH } from '../../../../common/constants/language';
import { HRARegisterState } from '../../../../common/store-atom/hra-register-atom';
import { ProfileState } from '../../../../common/store-atom/profile-atom';
import { ProgressBarState } from '../../../../common/store-atom/progress-bar-atom';
import { INIT_VALUE_USER_HEALTH, UserHealthState } from '../../../../common/store-atom/user-health-atom';
import { SliderConfirmation } from '../sliders/slider-confirmation';
import { RadioQuestion } from '../questions/radio-question';
import { UniqueCheckboxWithSuboptionsQuestion } from '../questions/unique-checkbox-with-suboptions-question';
import { MultipleCheckboxQuestion } from '../questions/multiple-checkbox-question';
import { RadioWithSubquestionsQuestion } from '../questions/radio-with-subquestions-question';
import { QuestionnaireQuestionsState } from '../../../../common/store-atom/questionnaire-questions';
import { ActiveIndexState } from '../../../../common/store-atom/active-index-atom';
import { Questions } from '../../../../common/models/questions';
import './styles.scss';

export interface ISliderProps {
  swiper: SwiperInterface | null;
  setActiveIndex: any;
  verifyLanguageSetting: any;
  isRepresentativeHRA?: boolean;
  question: Questions;
  index: number;
  isLast: boolean;
}

export const Slides: React.FC<{ isRepresentativeHRA: boolean }> = ({ isRepresentativeHRA }) => {
  const { i18n } = useTranslation();
  const [swiperState, setSwiperState] = useState<SwiperInterface | null>(null);
  let haveQuestionsBeenExecuted = false;
  const [questionnaireQuestions] = useRecoilState(QuestionnaireQuestionsState);
  const setUserHealthState = useSetRecoilState(UserHealthState);
  const [hraRegisterState] = useRecoilState(HRARegisterState);
  const setProgressBarState = useSetRecoilState(ProgressBarState);
  const userSelector = useRecoilValue(ProfileState);
  const setActiveIndexState = useSetRecoilState(ActiveIndexState);

  useEffect(() => {
    return () => {
      setUserHealthState(INIT_VALUE_USER_HEALTH);
      setProgressBarState(1 / questionnaireQuestions.length);
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(hraRegisterState.language);
  }, [hraRegisterState.language]);

  const heightMeasure = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  heightMeasure();

  window.addEventListener('resize', () => {
    heightMeasure();
  });

  const setLanguage = () => {
    const userLang = navigator.language;
    const isEs = userLang.includes('es') || userLang.includes('ES');

    return isEs ? LANGUAGE_SPANISH : LANGUAGE_ENGLISH;
  };

  const verifyLanguageSetting = () => {
    if (isRepresentativeHRA) {
      return hraRegisterState.language;
    }

    if (userSelector.app_language === null) {
      return setLanguage();
    }

    return userSelector.app_language;
  };

  return (
    <>
      <Swiper
        className="lc-slides"
        speed={400}
        initialSlide={0}
        noSwiping={true}
        noSwipingClass="swiper-slide"
        onSwiper={(swiper) => {
          setSwiperState(swiper);
        }}
      >
        {questionnaireQuestions.map((question, index) => {
          const isLast = questionnaireQuestions.length - 1 === index;
          if (index === 0) {
            haveQuestionsBeenExecuted = true;
          }
          if (question.type === 'radio') {
            return (
              <SwiperSlide key={index}>
                <RadioQuestion
                  swiper={swiperState}
                  setActiveIndex={setActiveIndexState}
                  verifyLanguageSetting={verifyLanguageSetting}
                  question={question}
                  index={index}
                  isLast={isLast}
                  isRepresentativeHRA={isRepresentativeHRA}
                />
              </SwiperSlide>
            );
          }
          if (question.type === 'unique_checkbox_with_suboptions') {
            return (
              <SwiperSlide key={index}>
                <UniqueCheckboxWithSuboptionsQuestion
                  swiper={swiperState}
                  setActiveIndex={setActiveIndexState}
                  verifyLanguageSetting={verifyLanguageSetting}
                  question={question}
                  index={index}
                  isLast={isLast}
                  isRepresentativeHRA={isRepresentativeHRA}
                />
              </SwiperSlide>
            );
          }
          if (question.type === 'multiple_checkbox') {
            return (
              <SwiperSlide key={index}>
                <MultipleCheckboxQuestion
                  swiper={swiperState}
                  setActiveIndex={setActiveIndexState}
                  verifyLanguageSetting={verifyLanguageSetting}
                  question={question}
                  index={index}
                  isLast={isLast}
                  isRepresentativeHRA={isRepresentativeHRA}
                />
              </SwiperSlide>
            );
          }
          if (question.type === 'radio_with_subquestions') {
            return (
              <SwiperSlide key={index}>
                <RadioWithSubquestionsQuestion
                  swiper={swiperState}
                  setActiveIndex={setActiveIndexState}
                  verifyLanguageSetting={verifyLanguageSetting}
                  question={question}
                  index={index}
                  isLast={isLast}
                  isRepresentativeHRA={isRepresentativeHRA}
                />
              </SwiperSlide>
            );
          }
        })}
        {haveQuestionsBeenExecuted && (
          <SwiperSlide>
            <SliderConfirmation />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
};
