export const mapQuestionnaires = (options: Array<any>, language: string | null | undefined): Array<any> => {
  const arrayList = options.map((element: any) => {
    let text = element.text_en;
    if (language === 'es' && element.text_es) {
      text = element.text_es;
    }

    return {
      name: text,
      value: element.value,
      isChecked: false,
      disabled: false,
    };
  });

  return arrayList;
};

export const getQuestionTitle = (data: any, language: string | null | undefined) => {
  if (language === 'es' && data.prompt_es) {
    return data.Prompt_es;
  }
  return data.prompt_en;
};

export const mapUniqueCheckboxWithSuboptions = (data: any, language: string | null | undefined) => {
  let mappedOptions = new Array<any>();

  data.options.forEach((element: any) => {
    let name = language === 'es' && element.prompt_es ? element.prompt_es : element.prompt_en;
    let list = element['options'];
    let value = element['value'];

    const data = { name, list, value };
    mappedOptions.push(data);
  });
  mappedOptions = mappedOptions.map((elem: any) => {
    if (elem.value) {
      return { name: elem.name, values: [{ name: elem.value, value: elem.value, selected: false }] };
    } else {
      let list = [...elem.list].map((x: any) => {
        const name = language === 'es' && x.text_es ? x.text_es : x.text_en;
        return { name, value: x.value, selected: false };
      });
      return { name: elem.name, values: [...list] };
    }
  });
  return mappedOptions;
};
