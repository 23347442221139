import { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IonPage, IonContent, IonCard, IonButton } from '@ionic/react';

import { requestPermission } from '../../../common/firebase/cloud-messaging';
import { ProfileState } from '../../../common/store-atom/profile-atom';
import { getPatient } from '../../../common/adapters/user-profile-adapter';
import { AddBiometrics } from '../health/components/addBiometrics';
import UpcomingEvents from '../../../components/upcoming-events';
import { fetchCalendar } from '../../../common/adapters/events-adapter';
import { ICalendar } from '../../../common/models/events';
import { Spinner } from '../../../components/spinner/spinner';
import { URL_LOGIN, URL_MAIN_CARE, URL_MAIN_HEALTH_APPOINTMENT, URL_PROFILE_REGISTER } from '../../../common/constants/routers';
import TopCard from '../../../components/top-card';
import './styles.scss';

export const TabHomePage: FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [profileState, setProfileState] = useRecoilState(ProfileState);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<ICalendar>({
    events: [],
    scheduledAppointments: [],
  });

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const lat = profileState?.address?.lat || 0;
      const lng = profileState?.address?.lng || 0;
      const response = await getPatient();
      if (response.data.data) {
        const userData = response.data.data;
        setProfileState({
          ...userData,
        });
      }
      i18n.changeLanguage(response.data.data.language);
      Promise.all([
        requestPermission(),
        getProfileData(),
        fetchCalendar(lat, lng).then((response) => {
          setCalendar(response?.data?.data || []);
        }),
      ])
        .catch(handleError)
        .finally(onFinally);
    } catch (err: any) {
      handleError(err);
    }
  }, []);

  const getProfileData = () => {
    getPatient().then(onProfileSuccess).catch(handleError);
  };

  const onProfileSuccess = async (response: any) => {
    if (response?.data?.data) {
      setProfileState({
        ...response.data.data,
      });
      i18n.changeLanguage(response.data.data.language);
    }
  };

  const handleError = (error: AxiosError) => {
    const status = error.response?.status;
    if ([500, 401].includes(Number(status))) {
      return (window.location.href = URL_LOGIN);
    }
    if (status === 604) {
      return (window.location.href = URL_PROFILE_REGISTER);
    }
  };

  const onFinally = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    requestPermission();
    fetchData();
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="lc-full-spot-bg lc-homepage">
        <Spinner isOpen={isLoading} />
        <div>
          <section>
            <section>
              <article>
                <TopCard message={`👋🏾 ${t('page_home_nice_to_see')} ${profileState.first_name}`} />
                <AddBiometrics />
              </article>
            </section>

            <section>
              <div className="card">
                <IonCard>
                  <h3>📅 {t('page_home_your_health_calendar')}</h3>

                  <UpcomingEvents calendar={calendar} />

                  <IonButton expand="block" fill="outline" onClick={() => history.push(URL_MAIN_CARE)}>
                    {t('page_home_see_all_events')}
                  </IonButton>

                  <IonButton expand="block" fill="outline" onClick={() => history.push(URL_MAIN_HEALTH_APPOINTMENT)}>
                    {t('care_tab_info_card_marker_1')}
                  </IonButton>
                </IonCard>
              </div>
            </section>
          </section>
        </div>
      </IonContent>
    </IonPage>
  );
};
