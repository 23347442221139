import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonButton, IonContent, IonFooter, IonItem, IonPage } from '@ionic/react';
import { URL_MAIN_HOME } from '../../../common/constants/routers';
import { CheckInNextJourneyStepCardContainer } from './../components/next-journey-step-card/CheckInNextJourneyStepCardContainer';
import { Spinner } from '../../../components/spinner/spinner';
import '../styles/check-in-view.scss';

export const CheckInView = ({ shop_name, journey_cards, next_card, isLoading }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const returnHome = () => {
    return history.replace(URL_MAIN_HOME);
    //post to check-in endpoint
  };
  var key = 0;

  return (
    <IonPage>
      <IonContent className="lch-ion-content-check-in" fullscreen>
        <Spinner isOpen={isLoading} />
        <IonItem className="ion-no-padding lch-ion-item-check-in">
          <div className="checkIn-header-container">
            <div className="checkIn-header-title-container">
              <h1>
                {t('check-in-header-title')}
                <br /> {t('check-in-header-title-2')}
              </h1>
            </div>
            <div className="checkIn-header-subtitle-container">
              <p>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.8978 4.89L18.8478 1.52C18.6278 0.62 17.8478 0 16.9378 0H3.04776C2.14776 0 1.35776 0.63 
                            1.14776 1.52L0.0977558 4.89C-0.362244 6.86 0.947756 8 0.997756 8.06V16C0.997756 17.1 1.89776 18 
                            2.99776 18H16.9978C18.0978 18 18.9978 17.1 18.9978 16V8.06C20.1178 6.94 20.0878 5.65 19.8978 
                            4.89ZM10.9978 2H12.9578L13.4978 5.52C13.5878 6.23 13.1078 7 12.2178 7C11.5478 7 10.9978 6.41 
                            10.9978 5.69V2ZM4.43776 5.86C4.35776 6.51 3.83776 7 3.22776 7C2.29776 7 1.87776 6.03 2.03776 
                            5.36L3.04776 2H5.01776L4.43776 5.86ZM8.99776 5.69C8.99776 6.41 8.44776 7 7.70776 7C6.95776 7 
                            6.40776 6.3 6.48776 5.52L7.03776 2H8.99776V5.69ZM16.7678 7C16.1578 7 15.6278 6.51 15.5578 
                            5.86L14.9778 2L16.9078 1.99L17.9578 5.36C18.1178 6.03 17.7078 7 16.7678 7Z"
                    fill="white"
                  />
                </svg>
                {shop_name}
              </p>
              {/* icon<p>{shop_name}</p> */}
            </div>
            <div className="checkIn-reward-banner">
              <p>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.7162 0.083252C5.13436 0.083252 0.614258 4.74992 0.614258 10.4999C0.614258 16.2499 
                            5.13436 20.9166 10.7162 20.9166C16.3082 20.9166 20.8384 16.2499 20.8384 10.4999C20.8384 4.74992 
                            16.3082 0.083252 10.7162 0.083252ZM13.9824 16.1145L10.7263 14.0937L7.47025 16.1145C7.08599 16.3541 
                            6.61072 15.9999 6.71185 15.552L7.57137 11.7395L4.70965 9.18742C4.37595 8.88534 4.55797 8.31242 5.0029 
                            8.27075L8.78482 7.93742L10.2612 4.34367C10.4331 3.91659 11.0196 3.91659 11.1915 4.34367L12.6679 7.927L16.4498
                             8.26034C16.8947 8.302 17.0767 8.87492 16.7329 9.177L13.8712 11.7291L14.7307 15.552C14.8319 15.9999 14.3667 16.3541 
                             13.9824 16.1145V16.1145Z"
                    fill="#230870"
                  />
                </svg>
                {t('check-in-reward-banner-text')}
              </p>
            </div>
          </div>
          <svg id="purple-splash" width="183" height="276" viewBox="0 0 183 276" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M245.909 12.8555C268.938 35.9167 277.985 68.0769 279.712 100.622C281.476 133.885 273.617 165.697 255.499 193.649C232.643 228.91 208.22 274.418 166.219 275.712C124.247 277.005 101.927 228.923 72.1661 199.299C44.0942 171.356 3.00017 149.988 0.222117 110.477C-2.59664 70.3865 28.454 36.6784 58.8866 10.4292C85.8053 -12.7891 120.12 -24.06 155.666 -23.5989C189.389 -23.1614 222.078 -11.0095 245.909 12.8555Z"
              fill="#C2ADFD"
            />
          </svg>
        </IonItem>

        <div className="checkIn-card-center-container">
          <div className="checkIn-next-journey-step-card-container">
            {journey_cards.map((journey_card) => {
              return (
                <CheckInNextJourneyStepCardContainer
                  title_en={journey_card.title_en}
                  title_es={journey_card.title_es}
                  detail_en={journey_card.detail_en}
                  detail_es={journey_card.detail_es}
                  reward_amount={journey_card.reward_amount}
                  type={journey_card.type}
                  next_card={next_card}
                  key={key++}
                />
              );
            })}
          </div>
        </div>
        <div className="checkIn-next-journey-step-footer">
          <p>
            {t('check-in-next-journey-step-footer')}
            <br /> {t('check-in-next-journey-step-footer-2')}
          </p>
        </div>
      </IonContent>
      <IonFooter className="responsive-center-container lch-footer-check-in">
        <IonButton onClick={returnHome} expand="block" size="small" color="primary">
          {t('check-in-footer-button')}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
