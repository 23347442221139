import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { registrationStateAtom } from '../store-atom/register-atom';

export const useRegistrationData = (url?: string) => {
  const [registrationState, setRegistrationState] = useRecoilState(registrationStateAtom);
  const urlParams = new URLSearchParams(url);
  const registrationId = urlParams.get('registrationId');
  const campaignId = urlParams.get('campaignId') ? urlParams.get('campaignId') : 'not_from_campaign';
  const shopId = urlParams.get('shopId') ? urlParams.get('shopId') : 'not_from_shop';
  const questionnaireId = urlParams.get('questionnaireId') ? urlParams.get('questionnaireId') : 'default_Lch_HRA';

  useEffect(() => {
    if (!url) return;
    setRegistrationState({
      campaignId,
      shopId,
      registrationId,
      initialized: true,
      questionnaireId,
    });
  }, [campaignId, shopId, registrationId, url]);

  return { ...registrationState };
};
