import { IonItem, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <IonItem lines="none">
      <IonText>
        <h1>
          {t('register_email.title_header_register')}
          <br />
          {t('register_email.title_header_email_account')}
        </h1>
      </IonText>
    </IonItem>
  );
};
