import React from 'react';
import { IonPage, IonContent, IonImg, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import '../styles.scss';
import { URL_MAIN_HOME } from '../../../common/constants/routers';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent fullscreen className="lc-justify-center error-draw">
        <div className="block-center-error-page">
          <div className="lc-error-page-buttons-container">
            <div className="lch-error-container">
              <div className="error-code">
                <h6>404</h6>
              </div>
              <div className="error-title">
                <h6>
                  {t('page_error_404_page_not.message').toUpperCase()}
                  <br />
                  {t('page_error_404_page_found.message').toUpperCase()}
                </h6>
              </div>
              <div className="error-message">
                <h6>
                  {t('page_error_404_page_the_requested_page.message')}
                  <br />
                  {t('page_error_404_page_was_not_found.message')}.
                </h6>
              </div>
              <div className="lch-error-footer">
                <IonButton href={URL_MAIN_HOME} className="lch-error-redirect-button">
                  {t('page_error_404_page_back_to_home.button')}
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
