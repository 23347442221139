import { useCallback, useEffect, useState } from 'react';
import { auth } from '../firebase/firebase';
import { URL_MAIN_HOME, REPRESENTATIVE_URL_LOGIN, REPRESENTATIVE_URL_USERS, URL_LOGIN } from '../constants/routers';
import { HOST_NAME_REPRESENTATIVES } from '../constants/base-url';
import { getNewToken } from '../firebase/session-management';

export const userUrlProvider = () => {
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);
  const [userType, setUserType] = useState<string>('');
  const { hostname } = window.location;
  const isRepresentative = hostname.includes(HOST_NAME_REPRESENTATIVES);

  const userNotSignedInRedirectToLogin = () => {
    const url = isRepresentative ? REPRESENTATIVE_URL_LOGIN : URL_LOGIN;
    setRedirectTo(url);
  };

  const userSignedInRedirectToHome = () => {
    setRedirectTo(isRepresentative ? REPRESENTATIVE_URL_USERS : URL_MAIN_HOME);
  };

  const getUserAuth = useCallback(async () => {
    const user = auth.currentUser;

    if (!user) {
      setIsLoadingAuth(false);
      return;
    }

    const idTokenResult = await user.getIdTokenResult(true);
    const claimType = idTokenResult.claims.type;

    if (!claimType) return;

    setUserType(claimType.toString());
    setIsLoadingAuth(false);
  }, []);

  const urlProvider = async () => {
    try {
      const token = await getNewToken();

      if (token) {
        userSignedInRedirectToHome();
        return;
      } else {
        userNotSignedInRedirectToLogin();
        return;
      }
    } catch (e) {
      userNotSignedInRedirectToLogin();
    }
  };

  useEffect(() => {
    getUserAuth();
    urlProvider();
  }, []);

  return { redirectTo, isLoadingAuth, userType };
};
