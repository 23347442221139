import React from "react";
import { IonButton, IonContent, IonFooter, IonItem, IonPage, IonText, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { phoneNumberState } from '../../common/store-atom/register-atom';
import { useRecoilValue } from 'recoil';
import { ProfileState } from '../../common/store-atom/profile-atom';
import { sendUniqueLink } from '../../common/adapters/unique-links';

import lc_logo from '../../assets/img/lc_rebrand_logo.svg';
import member from "../../assets/img/member.png";
import './styles.scss';

export const ExpiredLink: React.FC = () => {
  const { t } = useTranslation();
  const { phone } = useRecoilValue(phoneNumberState);
  const { id: patientId = ''} = useRecoilValue(ProfileState);
  const [present, dismiss] = useIonToast();

  const handleOnClick = async () => {
    try {
      const { data: { data }} = await sendUniqueLink({ patientId: patientId || '' });
      if (!data) {
        present({
          color: 'danger',
          position: 'top',
          buttons: [{ text: 'Ok', handler: () => dismiss() }],
          message: t('uniqueLink.expired_error'),
        });
      } else {
        present({
          color: 'success',
          position: 'top',
          buttons: [{ text: 'Ok', handler: () => dismiss() }],
          message: t('uniqueLink.expired_success'),
        });
      }
    } catch {
      present({
        color: 'danger',
        position: 'top',
        buttons: [{ text: 'Ok', handler: () => dismiss() }],
        message: t('uniqueLink.expired_error'),
      });
    }
  }

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <div className="block-center">
            <div className="lc-login-container ion-justify-content-center">
              <div className="ion-text-start">
                <img className="lc-register-logo" alt="live chair logo" src={lc_logo} />
              </div>

              <IonItem lines="none">
                <IonText className="lc-register-headline">{t('uniqueLink.expired')}</IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText className="lc-get-new-link-subtitle">{t('uniqueLink.get_new_link_subtitle')}{phone?.slice(-4)}</IonText>
              </IonItem>
              <IonButton expand="block" type="submit" onClick={handleOnClick} className='lc-expired-link-button' color={'primary'}>
                {t('uniqueLink.get_new_link')}
              </IonButton>
              <IonFooter className="lc-footer lc-login-container lch-no-border lch-light-teal">
                <div className={'lc-footer-container'}>
                  <img className="lch-circle" src={member} alt="footer image" />
                  <div className={"lch-member-text"}>
                    <div>{t('uniqueLink.page_member_advice')}</div>
                    <div className={"lc-bold"}>{t('uniqueLink.lch_member')}</div>
                  </div>
                </div>
              </IonFooter>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
