import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent, useIonModal, IonButton, IonCard } from '@ionic/react';

import { ICalendar } from '../../../common/models/events';
import { Spinner } from '../../../components/spinner/spinner';
import { IFamilyMember } from '../../../common/models/households';
import { ProfileState } from '../../../common/store-atom/profile-atom';
import { FindHelpModal } from '../../../components/externalLinkModal/FindHelpModal';
import { fetchCalendar } from '../../../common/adapters/events-adapter';
import AddFamilyMember from './components/AddFamilyMember';
import Calendar from '../../../components/events-calendar';
import CardSchedule from './components/CardSchedule';
import UpcomingEvents from '../../../components/upcoming-events';
import './styles.scss';

export const TabCarePage: React.FC<{ loading: boolean; familyMembers: IFamilyMember[] }> = ({ loading, familyMembers }) => {
  const userInfo = useRecoilValue(ProfileState);
  const { t } = useTranslation();
  const [showFindhelp, setShowFindhelp] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventList, setEventList] = useState<ICalendar>({
    events: [],
    scheduledAppointments: [],
  });
  const profileState = useRecoilValue(ProfileState);

  const handleDismiss = () => {
    dismiss();
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const lat = profileState?.address?.lat || 0;
      const lng = profileState?.address?.lng || 0;
      fetchCalendar(lat, lng)
        .then((response) => {
          setEventList(response?.data?.data);
        })
        .catch(() => {
          setEventList({
            events: [],
            scheduledAppointments: [],
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const [present, dismiss] = useIonModal(AddFamilyMember, { userInfo, dismiss: handleDismiss });

  return (
    <IonPage>
      <FindHelpModal
        show={showFindhelp}
        handleOnhideModal={() => {
          setShowFindhelp(false);
        }}
      />
      <Spinner isOpen={loading} />

      <IonContent className="lc-full-spot-bg" fullscreen>
        <div className="lc-care-container">
          <CardSchedule familyMembers={familyMembers} present={present} />
          <IonCard className="card_get-connected">
            <section className="card_get-connected-container">
              <span className="event_title-for-you">🥕 {t('findhelp_container_title')}</span>
              <p className="title_get-description">{t('findhelp_modal_description')}</p>
              <IonButton
                onClick={() => {
                  setShowFindhelp(true);
                }}
                fill="solid"
                type="submit"
                expand="block"
              >
                {t('findhelp_call_to_action_button')}
              </IonButton>
            </section>
          </IonCard>

          {isLoading ? (
            <Spinner isOpen={isLoading} />
          ) : (
            <div className="events-card">
              <span className="event_title-for-you">📅 {t('events-management-upcoming')}</span>
              <Calendar eventList={eventList.events} appointmentsList={eventList.scheduledAppointments}  userId={String(profileState?.id)} />
              <UpcomingEvents calendar={eventList} />
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
