import { IonCard, IonChip, IonIcon, IonLabel, IonText } from '@ionic/react';
import { format, formatISO } from 'date-fns';
import { checkmarkCircleOutline, chevronForwardOutline, timeOutline } from 'ionicons/icons';

import { StatusCard } from '../../enums/status-card';
import { ICardProps } from '../../interfaces/ICardProps';
import stepIcon from '../../../../../assets/icons/step.svg';
import rewardsIcon from '../../../../../assets/icons/rewards.svg';
import '../rebrandCards.scss';

export const GenericCard = (props: ICardProps) => {
  const handleOnClick = () => {};

  const CardIcon = {
    Step: stepIcon,
    Reward: rewardsIcon,
    Generic: stepIcon,
    PCP: stepIcon,
  };

  return (
    <IonCard
      className="rebrand-card"
      style={{ boxShadow: props.status === 'done' ? 'none' : '' }}
      {...(props.action && { onClick: handleOnClick })}
    >
      <section className="rebrand-card-container">
        <section className="rebrand-card-content">
          <section className="rebrand-card-date">
            <IonIcon src={CardIcon[props.type]} />
            <time dateTime={formatISO(new Date(String(props.createdAt)), { representation: 'date' })}>
              <span>{format(new Date(props.createdAt), 'MMM').toUpperCase()}</span>
              <span>{format(new Date(props.createdAt), 'dd')}</span>
            </time>
          </section>

          <section className="rebrand-card-text">
            <IonText style={{ display: props.type === 'Step' ? 'inherit' : 'none' }}></IonText>

            {props.status.toLocaleLowerCase() === StatusCard.DONE ? (
              <IonChip className={'rebrand-card-chip confirmed'}>
                <IonIcon icon={props.status ? checkmarkCircleOutline : timeOutline} color="white" />
                <IonLabel>{props.status}</IonLabel>
              </IonChip>
            ) : (
              <IonChip className={'rebrand-card-chip unconfirmed'}>
                <IonIcon icon={props.status ? checkmarkCircleOutline : timeOutline} color="white" />
                <IonLabel>{props.status}</IonLabel>
              </IonChip>
            )}

            <IonText>{props.title}</IonText>
          </section>
        </section>

        {props.action && (
          <section
            style={{
              backgroundColor: '#D7F0FD',
            }}
            className="rebrand-card-arrow"
          >
            <IonIcon icon={chevronForwardOutline} />
          </section>
        )}
      </section>
    </IonCard>
  );
};
