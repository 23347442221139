import React from 'react';
import { IonPage, IonContent, IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { URL_MAIN_HOME } from '../../../common/constants/routers';
import '../styles.scss';

export const InternalServerErrorPage = () => {
  const history = useHistory();
  const handleOnClick = () => {
    history.push(URL_MAIN_HOME);
  };
  return (
    <IonPage>
      <IonContent fullscreen className="lc-full-spot-bg">
        <div className="block-center-error-page">
          <div className="lc-error-page-buttons-container">
            <div className="lch-error-container">
              <div className="error-code">
                <h6>500</h6>
              </div>
              <div className="error-title">
                <h6>
                  SERVER <br /> ERROR
                </h6>
              </div>
              <div className="error-message">
                <h6>Something went wrong</h6>
              </div>
              <div className="lch-error-footer">
                <IonButton
                  onClick={() => {
                    handleOnClick();
                  }}
                  className="lch-error-redirect-button"
                >
                  Try Again
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
