import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import { IonButton, IonIcon, useIonToast } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import 'firebase/messaging';

import '../styles.scss';

/** Common */
import { User } from '../../../common/models/user';
import { UserState } from '../../../common/store-atom/user-atom';
import { signInWithSocialNetwork, signOut } from '../../../common/firebase/sign-in';

/** Component */
import { LCButton } from '../button';
import { getPatient } from '../../../common/adapters/user-profile-adapter';
import { UserAccess } from '../../../common/hooks/user-access';
import { deleteToken } from '../../../common/storage';
import { URL_MAIN_HOME, URL_PROFILE_REGISTER } from '../../../common/constants/routers';
import { AuthType } from '../../../common/enums/auth-type';
import { LANGUAGE_ENGLISH, LANGUAGE_SPANISH } from '../../../common/constants/language';
import { Spinner } from '../../spinner/spinner';

interface ISocialNetworkButtonProps {
  provider: firebase.auth.AuthProvider;
  title: string;
  icon: string;
  backgroundColor?: string;
  className?: string;
}
export const SocialNetworkButton: React.FC<ISocialNetworkButtonProps> = ({
  provider,
  title,
  icon,
  backgroundColor,
  className,
}: ISocialNetworkButtonProps) => {
  const history = useHistory();
  const { isClient, isPartner } = UserAccess();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useRecoilState(UserState);
  const setPatient = useSetRecoilState(UserState);

  const [present, dismiss] = useIonToast();
  const { i18n } = useTranslation();

  const onSuccess = async (response: any) => {
    return response.data?.data;
  };

  const onFinally = () => {
    setIsLoading(false);
  };

  const onError = (error: AxiosError) => {
    setIsLoading(false);
    if (error.response?.status === 604) {
      return (window.location.href = URL_PROFILE_REGISTER);
    }
  };

  const setUserRecoilState = (profile: any, userType: AuthType) => {
    const patient: User = {
      ...profile,
      fullName: null,
      uid: null,
      urlPicture: null,
      authType: userType,
    };
    setUser(patient);
  };

  const validatePatient = async (user) => {
    setIsLoading(true);
    const profile = await getPatient()
      .then(onSuccess)
      .catch((error: AxiosError) => {
        return onError(error);
      })
      .finally(onFinally);

    getPatient().then(async (result) => {
      setIsLoading(false);
      if (
        profile &&
        ((isClient && result.data.data.userType === AuthType.emailAndPassword) ||
          (isPartner && result.data.data.userType === AuthType.socialNetwork))
      ) {
        setUserRecoilState(profile, user.authType || 0);
        if (profile.language === LANGUAGE_ENGLISH || profile.language === LANGUAGE_SPANISH) {
          i18n.changeLanguage(profile.language);
        } else {
          i18n.changeLanguage(LANGUAGE_ENGLISH);
        }
        history.push(URL_MAIN_HOME);
      } else {
        setIsLoading(false);
        await signOut();
        await deleteToken();
        present({
          color: 'danger',
          buttons: [{ text: 'Ok', handler: () => dismiss() }],
          message: 'Oops, looks like the account you are trying to use is already connected to another LCH program.',
        });
      }
    });
  };

  /**
   *
   * @param user
   * store the user logined
   */
  const postRegisterAction = (user: User) => {
    setPatient(user);

    setUser(user);
  };

  /** Login in the user using GoogleProvider */
  const handleLoginByGoogle = async () => {
    setButtonClicked(true);
    await signInWithSocialNetwork(provider, postRegisterAction);
  };

  useEffect(() => {
    if (buttonClicked && user.uid && !user.id) {
      validatePatient(user);
    }
  }, [user]);

  return (
    <>
      <Spinner isOpen={isLoading} />
      {className ? (
        <IonButton onClick={handleLoginByGoogle} className={className} expand="block">
          <IonIcon slot="start" icon={icon} />
          {title}
        </IonButton>
      ) : (
        <LCButton onClick={handleLoginByGoogle} customColor={backgroundColor} expand="block">
          <IonIcon slot="start" icon={icon} />
          {title}
        </LCButton>
      )}
    </>
  );
};
