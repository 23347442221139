import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';

interface ISpinner {
  isOpen: boolean;
  message?: string;
}
export const Spinner = ({ isOpen, message }: ISpinner) => {
  const { t } = useTranslation();

  return <IonLoading isOpen={isOpen} message={message || t('spinner.message')} />;
};
