import { useState } from 'react';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { menuController } from '@ionic/core';
import { clipboard, clipboardSharp, heart, home, person, shield } from 'ionicons/icons';
import {
  URL_MAIN,
  URL_MAIN_HEALTH,
  URL_MAIN_HOME,
  URL_MAIN_HEALTH_APPOINTMENT,
  URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION,
  URL_ABOUT_YOUR_HEALTH,
  URL_SHARE_TOPIC_OF_THE_MONTH,
  URL_MAIN_PHONE_NUMBER_VERIFICATION,
  URL_PHONE_VERIFICATION_CONFIRMATION,
  URL_PHONE_VERIFICATION_VERIFY_CODE,
  URL_MAIN_HOME_HEALTH_QUESTIONNAIRE,
  URL_PAYPAL_OAUTH_PAGE,
  URL_MAIN_CARE,
  URL_MAIN_TODO,
  URL_HEALTH_BIOMETRICS,
  URL_MAIN_HEALTH_RECORDS,
  URL_MAIN_HEALTH_BP_CHART,
} from '../../../common/constants/routers';
import { setNavigationPage, getLastNavigationPage } from '../../../common/storage';
import { TabMenuState } from '../../../common/store-atom/tab-menu-atom';
import { TabHealthPage } from '../../tabs/health';
import { TabHomePage } from '../../tabs/home';
import { AppointmentConfirmationPage } from '../../tabs/health/pages/AppointmentConfirmationPage';
import { RequestAppointmentPage } from '../../tabs/health/pages/RequestAppointmentPage';
import { ShareTopicOfTheMonthContainer } from '../../share-topic-of-the-month/components/ShareTopicOfTheMonthContainer';
import { CellphoneVerificationPageContainer } from '../../cellphone-verification/components/CellphoneVerificationPageContainer';
import { VerifyCodeContainer } from '../../cellphone-verification/components/VerifyCodeContainer';
import { ConfirmationPage } from '../../cellphone-verification/components/Confirmation';
import { HealthQuestionnairePage } from '../../tabs/home/components/take-next-step-card/components/health-questionnaire';
import { PaypalPostRequestPage } from '../../tabs/earnings/components/paypalPostRequestPage';
import { getFlagValue } from '../../../common/firebase/remote-config';
import CareTabView from '../../tabs/care/CareTabView';
import ToDoPage from '../../tabs/to-do';
import LogBiometricsPage from '../../tabs/health/pages/LogBiometricsPage';
import HealthRecordsPage from '../../tabs/health/pages/HealthRecordsPage';
import BloodPressureChartPage from '../../tabs/health/pages/BloodPressureChartPage';
import './styles.scss';

export const LCTabsClients: React.FC = () => {
  const history = useHistory();
  const setTabMenuState = useSetRecoilState(TabMenuState);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);

  const checkEarnings = async () => {
    let response = await getLastNavigationPage();
    if (response === '/main/earnings') {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  const handleTabsDidChange = async (event: CustomEvent) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    await setNavigationPage(URL_MAIN.concat('/').concat(event.detail.tab));
    setTabMenuState(event.detail.tab);
    checkEarnings();
    history.push(`${URL_MAIN}/${event.detail.tab}`);
  };

  const handleMenu = async () => {
    await menuController.toggle();
  };

  return (
    <IonTabs>
      <IonRouterOutlet id="main-content">
        <Route path={URL_MAIN} render={() => <Redirect to={URL_MAIN_HOME} />} exact />
        <Route path="/" render={() => <Redirect to={URL_MAIN_HOME} />} exact />
        <Route path={URL_MAIN_HOME} component={TabHomePage} exact />
        <Route path={URL_MAIN_HOME_HEALTH_QUESTIONNAIRE} component={HealthQuestionnairePage} exact />
        <Route path={URL_MAIN_CARE} component={CareTabView} exact />
        <Route path={URL_MAIN_HEALTH_APPOINTMENT} component={RequestAppointmentPage} exact />
        <Route path={URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION} component={AppointmentConfirmationPage} exact />
        <Route path={URL_MAIN_HEALTH} component={TabHealthPage} exact />
        <Route path={URL_MAIN_PHONE_NUMBER_VERIFICATION} component={CellphoneVerificationPageContainer} exact />
        <Route path={URL_PHONE_VERIFICATION_VERIFY_CODE} component={VerifyCodeContainer} exact />
        <Route path={URL_PHONE_VERIFICATION_CONFIRMATION} component={ConfirmationPage} exact />
        <Route path={URL_PAYPAL_OAUTH_PAGE} component={PaypalPostRequestPage} exact />
        <Route path={URL_ABOUT_YOUR_HEALTH} component={TabHomePage} exact />
        <Route path={URL_MAIN_TODO} component={ToDoPage} exact />
        <Route exact path={URL_SHARE_TOPIC_OF_THE_MONTH} component={ShareTopicOfTheMonthContainer} />
        <Route exact path={URL_HEALTH_BIOMETRICS} component={LogBiometricsPage} />
        <Route exact path={URL_MAIN_HEALTH_RECORDS} component={HealthRecordsPage} />
        <Route exact path={URL_MAIN_HEALTH_BP_CHART} component={BloodPressureChartPage} />
      </IonRouterOutlet>

      <IonTabBar onIonTabsDidChange={handleTabsDidChange} color="navy" slot="bottom" className="custom-tab-rounded">
        <IonTabButton tab="home" href={URL_MAIN_HOME}>
          <IonIcon className="custom-tab-icon" icon={home} />
          <IonLabel>{t('menu_tab.title_home')}</IonLabel>
        </IonTabButton>

        {getFlagValue('tab_care') && (
          <IonTabButton tab="care" href={URL_MAIN_CARE}>
            <IonIcon className="custom-tab-icon" icon={shield} />
            <IonLabel>{t('menu_tab.care')}</IonLabel>
          </IonTabButton>
        )}

        {getFlagValue('tab_biometrics') && (
          <IonTabButton tab="health" href={URL_MAIN_HEALTH}>
            <IonIcon className="custom-tab-icon" icon={heart} />
            <IonLabel>{t('menu_tab.title_health')}</IonLabel>
          </IonTabButton>
        )}

        <IonTabButton tab="to-do" href={URL_MAIN_TODO} disabled={selected}>
          <IonIcon className="custom-tab-icon" icon={clipboard} />
          <IonLabel>{t('menu_tab.title_to_do')}</IonLabel>
        </IonTabButton>

        {getFlagValue('tab_about_health') && (
          <IonTabButton tab="about-your-health" href={URL_ABOUT_YOUR_HEALTH}>
            <IonIcon className="custom-tab-icon" icon={clipboardSharp} />
            <IonLabel>{t('menu_tab.title_health_actions')}</IonLabel>
          </IonTabButton>
        )}

        <IonTabButton>
          <div className="custom-tab-button" onClick={handleMenu}>
            <IonIcon className="custom-tab-icon" icon={person} />
            <IonLabel>{t('menu_tab.title_account')}</IonLabel>
          </div>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
