import { useEffect } from 'react';
import { nextJourneyStepCard } from '../../../../common/models/check-in';
import { CheckInNextJourneyStepCard } from './CheckInNextJourneyStepCard';
import { getCurrentLanguage } from '../../../../common/hooks/getCurrentLanguage';

export const CheckInNextJourneyStepCardContainer =
    ({ title_en,
        title_es,
        detail_en,
        detail_es,
        reward_amount,
        type,
        next_card }: nextJourneyStepCard) => {

        const { isEnglish } = getCurrentLanguage();
        const title = isEnglish ? title_en : title_es
        const detail = isEnglish ? detail_en : detail_es
        const nextCardTitle = isEnglish ? next_card.title_en : next_card.title_es;

        return (
            <>
                <CheckInNextJourneyStepCard
                    title={title}
                    detail={detail}
                    reward_amount={reward_amount}
                    type={type}
                    next_card_title={nextCardTitle}
                />
            </>)
    }
