import api from './api';

export const getAllProvider = async () => {
  return await api.get('providers');
};

export const getProviderBio = async (id: string) => {
  const {
    data: { data },
  } = await api.get(`providers/${id}/bio`);

  return data;
};

export const appointmentProvider = async (appointmentProvider: any) => {
  return await api.post('providers/AppointmentRequest', appointmentProvider);
};
