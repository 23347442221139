import { useRecoilValue } from 'recoil';
import React, { useEffect, useState } from 'react';
import { IonCard, IonChip, IonIcon, IonLabel, useIonModal } from '@ionic/react';
import { checkmarkCircleOutline, chevronForwardOutline, timeOutline } from 'ionicons/icons';
import { format, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Event, AppointmentDetails } from '@live-chair-health/lch-core';

import { ProfileState } from '../../../common/store-atom/profile-atom';
import { EventModalCard } from './EventModalCard';
import '../styles.scss';

interface EventItemProps {
  eventData?: Event;
  appointmentData?: AppointmentDetails;
  attending: boolean;
  eventList: any[];
  type?: string;
}

const EventCard: React.FC<EventItemProps> = ({ attending, eventData, eventList, type, appointmentData }) => {
  const profile = useRecoilValue(ProfileState);
  const [sameDayEvents, setSameDayEvents] = useState<Event[]>([]);
  const { t } = useTranslation();
  const handleDismiss = () => {
    dismiss();
  };
  const [present, dismiss] = useIonModal(EventModalCard, {
    onDismiss: handleDismiss,
    eventList: sameDayEvents,
    profileId: profile.id,
    attending,
  });
  // @ts-ignore
  const eventDateTime = eventData ? new Date(eventData.dateTime) : appointmentData ? new Date(appointmentData.AppointmentDateTime) : ''; 

  useEffect(() => {
    const eventsInSameDate = eventList.filter((event) => {
      const currentFilteredEventDateTime = event.dateTime ? event.dateTime : event.AppointmentDateTime ? new Date(event.AppointmentDateTime).getTime() : 0;
      return isSameDay(currentFilteredEventDateTime, new Date(eventDateTime).getTime());
    });

    setSameDayEvents(!!eventsInSameDate.length ? eventsInSameDate : eventList);
  }, [eventList]);

  return (
    <IonCard>
      <div
        className="event_item-container"
        onClick={() => {
          present({
            swipeToClose: true,
            cssClass: 'event-modal-container',
          });
        }}
      >
        <section className="event_inner-container">
          <section className="event_first-line">
            <span className="event_date">{format(new Date(eventDateTime), 'EEEE, MMMM, d')}</span>
            {type === 'event' &&
              <IonChip className={`event_chip ${attending ? 'confirmed' : 'unconfirmed'}`}>
                <IonIcon icon={attending ? checkmarkCircleOutline : timeOutline} color="white" />
                <IonLabel>{attending ? t('events-management-going') : 'RSVP'}</IonLabel>
              </IonChip>
            }
          </section>

          <section className="event_info-container">
            <span className="event_info-title">{type === 'event' ? eventData?.title : 'Family Health Appointment'}</span>
            {type === 'event' && <span className="event_info-description">{eventData && eventData.description ? eventData?.description.slice(0, 40) : ''}...</span>}
          </section>
        </section>

        <section className="event_card-button-arrow">
          <IonIcon icon={chevronForwardOutline} />
        </section>
      </div>
    </IonCard>
  );
};

export default EventCard;
