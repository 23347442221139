import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProfileState } from '../store-atom/profile-atom';

export const getCurrentLanguage = () => {
  const [isSpanish, setIsSpanish] = useState(false);
  const [isEnglish, setIsEnglish] = useState(true);
  const profileState = useRecoilValue(ProfileState);
  const currentLanguage = profileState.app_language;
  const checkCurrentLanguage = () => {
    if (currentLanguage === 'es' || currentLanguage === 'ES') {
      setIsEnglish(false);
      setIsSpanish(true);
    }
  };

  useEffect(() => {
    checkCurrentLanguage();
  }, []);

  return { isSpanish, isEnglish };
};
