import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import { REPRESENTATIVE_URL_PROFILE_REGISTER } from '../../../../../common/constants/routers';
import { HRALanguageType } from '../../../../../common/models/hra-register';
import { HRARegisterState } from '../../../../../common/store-atom/hra-register-atom';
import { RepresentativeHomeView } from './RepresentativeHomeView';

export const RepresentativeHomeContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [registerState, setRegisterState] = useRecoilState(HRARegisterState);

  useEffect(() => {
    setRegisterState({
      ...registerState,
      language: HRALanguageType.EN,
    });
  }, []);

  const setQuestionareLanguage = (language: HRALanguageType) => {
    setRegisterState({
      ...registerState,
      language,
    });
    history.push(REPRESENTATIVE_URL_PROFILE_REGISTER);
  };

  return <RepresentativeHomeView t={t} setQuestionareLanguage={setQuestionareLanguage} />;
};
