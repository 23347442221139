/**
 * format MM/DAY
 */
export const getNameMonthShort = (value: string) => {
  const date = new Date(value);
  const monthName = date.toLocaleString('en-us', { month: 'short' }).replace('.', '');
  return monthName;
};

export const getDayNumber = (value: string) => {
  const date = new Date(value);
  return Number(date.toLocaleString('en-us', { day: 'numeric' }));
};

export const getNameDayShort = (value: string) => {
  const date = new Date(value);
  return date.toLocaleString('en-us', { weekday: 'short' });
};

export const getYear = (value: string) => {
  const date = new Date(value);
  return date.toLocaleString('en-us', { year: 'numeric' });
};

export const getRequestedDateAppointment = (value: string) => {
  return `${getNameDayShort(value)} ${getDayNumber(value)} ${getNameMonthShort(value)} ${getYear(value)}`;
};

export const getCurrentDate = () => {
  const date = new Date();

  const mm = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const dd = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
  const yyyy = date.getFullYear();

  const today = `${yyyy}-${mm}-${dd}`;
  return today;
};

/** Format yyyy-mm-dd */
export const formatDate = (date: Date) => {
  const mm = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const dd = date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
  const yyyy = date.getFullYear();

  const today = `${yyyy}-${mm}-${dd}`;
  return today;
};
