import { HOST_NAME_CLIENTS, HOST_NAME_REPRESENTATIVES, HOST_NAME_PARTNERS } from '../../common/constants/base-url';


export const subdomainType = () => {
  const { hostname } = window.location;
  const isClientSubdomain = hostname.includes(HOST_NAME_CLIENTS);
  const isPartnerSubdomain = hostname.includes(HOST_NAME_PARTNERS);
  const isRepresentativeSubdomain = hostname.includes(HOST_NAME_REPRESENTATIVES);

  return { isClientSubdomain: isClientSubdomain, isPartnerSubdomain, isRepresentativeSubdomain };
};
