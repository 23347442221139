import { useCallback, useEffect, useState } from 'react';
import { IonButton, IonCard, IonCardContent, IonModal, IonRouterLink } from '@ionic/react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { URL_REGISTER } from '../../../common/constants/routers';
import { logOut } from '../../../common/firebase/log-out';
import { clearStore } from '../../../common/storage';
import { ItemMenu } from './item-menu';
import '../styles.scss';

interface IItemMenuLogOutProps {
  icon: string;
  title: string;
}
export const ItemMenuLogOut = ({ title, icon }: IItemMenuLogOutProps) => {
  const history = useHistory();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const onClickLogout = () => {
    clickLogout();
    history.push(URL_REGISTER);
  };

  const clickLogout = useCallback(async () => {
    await logOut();
    await clearStore();
    setOpen(false);
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      <IonModal isOpen={isOpen} cssClass="lc-modal-backdrop">
        <IonCard className="ion-text-center">
          <div>
            <br />
            <h4>{t('logout_modal.title')}</h4>
            <p>{t('logout_modal.message')}?</p>
          </div>

          <IonCardContent>
            <IonRouterLink onClick={() => setOpen(false)}>{t('button_title_cancel')}</IonRouterLink>
            <IonButton onClick={onClickLogout} fill="clear">
              {t('logout_modal_logout.button')}
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonModal>

      <div className="logout-pointer" onClick={() => setOpen(true)}>
        <ItemMenu title={title} icon={icon} />
      </div>
    </>
  );
};
