import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonContent, IonPage, IonButton, useIonViewWillEnter, IonText } from '@ionic/react';

import { AddBiometrics } from './components/addBiometrics';
import { getAllBiometrics } from '../../../common/adapters/biometrics-adapter';
import { URL_MAIN_HEALTH_BP_CHART, URL_MAIN_HEALTH_RECORDS } from '../../../common/constants/routers';
import BlankCard from '../../../components/blank-card';
import CardInfo from '../care/components/CardInfo';
import BiometricsGraph from '../../../components/biometrics-graph';
import './styles.scss';

export const TabHealthPage: React.FC = () => {
  const [biometrics, setBiometrics] = useState<Array<any>>([]);
  const { t } = useTranslation();
  const history = useHistory();

  useIonViewWillEnter(() => {
    getAllBiometrics().then((response) => {
      setBiometrics(response.data.data);
    });
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="lc-full-spot-bg lc-biometrics">
        <div className="lc-biometrics-content">
          <div className="lc-biometrics-inner">
            <br />
            <CardInfo />
            <br />
            <AddBiometrics />
            <br />
            <BlankCard
              options={{
                title: '👣 ' + t('card_journey_message'),
                description: t('how_your_health_changed'),
              }}
            >
              <BiometricsGraph data={biometrics} />
              <IonText>
                <h3 className="bp-chart-link">
                  <Link to={URL_MAIN_HEALTH_BP_CHART}>{t('learn_about_graph')}</Link>
                </h3>
              </IonText>
              <IonButton onClick={() => history.push(URL_MAIN_HEALTH_RECORDS)}>{t('check_records')}</IonButton>
            </BlankCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
