import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
/**Icon */
import { logoGoogle } from 'ionicons/icons';
import { RepresentativeSocialNetworkButton } from '../representative-social-network-button';

/**Common */

export const RepresentativeGoogleButton: React.FC = () => {
    /**Constants */
    const googleTitle = 'Google';
    const googleBackgroundColor = '#F06956';
    const googleProvider = new firebase.auth.GoogleAuthProvider();

    return (
        <RepresentativeSocialNetworkButton
            title={googleTitle}
            backgroundColor={googleBackgroundColor}
            icon={logoGoogle}
            provider={googleProvider}
        />
    );
};