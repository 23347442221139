import { IonItem } from '@ionic/react';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import './styles.scss'

export const SliderOne = () => (

    <SwiperSlide className="slide-layout">
        <img className="lc-spot-with-logo-image" src='assets\img\health-is-wealth-logo.svg' alt="Health is Wealth" />
        <div className="slide-illustration-container">
            <img className="lc-spot-with-logo-image" src='assets\img\onboarding-illustration.svg' alt="Onboarding Illustration" />
        </div>

        <div className="slide-title-container">
            <h2>Take care of your health <br /> & get paid to do it.</h2>
        </div>
        <div className="slide-sub-title-container">
            <h3>Join our program to</h3>
        </div>
        <div className="slide-items-container">
            <IonItem lines='full'>
                <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.6167 8.05402L21.8379 1.52117H20.5313C20.1394 1.52117 20.0087 1.39051 19.7474 1.25985C19.3554 0.998538 18.8328 0.606567 17.9182 0.606567C17.0036 0.606567 16.481 0.998538 16.089 1.25985C15.8277 1.52117 15.697 1.52117 15.305 1.52117C14.9131 1.52117 14.7824 1.39051 14.5211 1.25985C14.2598 0.998538 13.7372 0.606567 12.8226 0.606567C11.908 0.606567 11.3853 0.998538 10.9934 1.25985C10.732 1.39051 10.6014 1.52117 10.3401 1.52117H9.03351L11.124 8.05402C12.4306 7.53139 13.8678 7.27007 15.305 7.27007C16.8729 7.27007 18.3102 7.66205 19.6167 8.05402Z" fill="#FFC833" />
                    <path d="M27.4561 19.8133C27.4561 13.1498 21.9685 7.6622 15.305 7.6622C8.64152 7.6622 3.15393 13.1498 3.15393 19.8133C3.15393 19.944 3.15393 20.0746 3.15393 20.3359V20.4666V24.3863C3.15393 28.5673 6.55101 31.9644 10.732 31.9644H19.878C24.059 31.9644 27.4561 28.5673 27.4561 24.3863V20.4666V20.3359C27.4561 20.2053 27.4561 20.0746 27.4561 19.8133ZM15.1744 20.4666C13.8678 19.944 11.124 19.16 11.124 16.4162C11.124 16.2856 11.124 13.5418 14.2598 12.8885V12.4965C14.2598 11.8432 14.7824 11.4513 15.305 11.4513C15.8277 11.4513 16.3503 11.9739 16.3503 12.4965V12.8885C17.6569 13.1498 18.4408 13.8031 18.9634 14.4564C19.3554 14.979 19.0941 15.7629 18.5715 16.0242C18.1795 16.1549 17.6569 16.0242 17.3955 15.6323C17.0036 15.1096 16.4809 14.7177 15.4357 14.7177C14.6517 14.7177 13.2145 15.1096 13.2145 16.4162C13.2145 17.5921 14.2598 17.9841 16.3503 18.768C19.2247 19.8133 20.0087 21.2505 20.0087 22.9491C20.0087 26.0848 17.0036 26.7381 16.3503 26.8688V27.2607C16.3503 27.7834 15.8277 28.306 15.305 28.306C14.7824 28.306 14.2598 27.7834 14.2598 27.2607V26.7381C13.4758 26.6075 11.9079 25.9542 10.9934 24.2556C10.732 23.733 10.9933 23.0797 11.516 22.8184H11.6466C12.1693 22.5571 12.6919 22.8184 12.9532 23.341C13.3452 24.125 14.1291 25.0396 15.5663 25.0396C16.7423 25.0396 17.9182 24.517 17.9182 23.0797C17.9182 21.7732 17.1342 21.1199 15.1744 20.4666Z" fill="#FFC833" />
                </svg>
                Get instant rewards for healthy actions.
            </IonItem>
            <IonItem lines='full'>
                <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8753 17.6006H9.25558C7.9112 17.6006 6.82597 16.5154 6.82597 15.171C6.82597 13.8266 7.9112 12.7414 9.25558 12.7414H10.8753V11.1216C10.8753 9.77723 11.9606 8.692 13.3049 8.692C14.6493 8.692 15.7346 9.77723 15.7346 11.1216V12.7414H17.3543C18.6987 12.7414 19.7839 13.8266 19.7839 15.171C19.7839 16.5154 18.6987 17.6006 17.3543 17.6006H15.7346V19.2203C15.7346 20.5647 14.6493 21.65 13.3049 21.65C11.9606 21.65 10.8753 20.5647 10.8753 19.2203V17.6006ZM12.1711 0.204536L2.45265 3.84896C1.18925 4.33489 0.346985 5.5335 0.346985 6.89408V14.5069C0.346985 22.6866 5.87032 30.3156 13.3049 32.1783C20.7396 30.3156 26.2629 22.6866 26.2629 14.5069V6.89408C26.2629 5.5497 25.4206 4.33489 24.1572 3.86516L14.4388 0.220734C13.7099 -0.0708205 12.9 -0.0708205 12.1711 0.204536Z" fill="#77D5A7" />
                </svg>
                Receive Personalized Health guidance.
            </IonItem>
            <IonItem lines='full'>
                <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.2129 0.119873C21.4117 0.119873 18.3951 0.694471 16.3122 2.27461C14.2293 0.694471 11.2126 0.119873 8.41146 0.119873C6.32854 0.119873 4.11634 0.435902 2.26326 1.2547C1.21462 1.72875 0.510742 2.74866 0.510742 3.91222V20.1159C0.510742 21.9833 2.26326 23.3623 4.07325 22.9027C5.48101 22.5435 6.97496 22.3855 8.41146 22.3855C10.6524 22.3855 13.037 22.759 14.9619 23.7071C15.8238 24.1381 16.8006 24.1381 17.6481 23.7071C19.573 22.7447 21.9576 22.3855 24.1985 22.3855C25.635 22.3855 27.129 22.5435 28.5367 22.9027C30.3467 23.3767 32.0992 21.9977 32.0992 20.1159V3.91222C32.0992 2.74866 31.3954 1.72875 30.3467 1.2547C28.508 0.435902 26.2958 0.119873 24.2129 0.119873ZM29.2406 18.4064C29.2406 19.3114 28.4075 19.9722 27.5168 19.8142C26.4395 19.6131 25.319 19.5269 24.2129 19.5269C21.7709 19.5269 18.2514 20.4606 16.3122 21.6816V5.1476C18.2514 3.92658 21.7709 2.99286 24.2129 2.99286C25.5345 2.99286 26.8417 3.12215 28.0914 3.39508C28.7522 3.53873 29.2406 4.12769 29.2406 4.80284V18.4064Z" fill="#655DC6" />
                    <path d="M19.1564 9.47138C18.6967 9.47138 18.2802 9.18408 18.1365 8.72441C17.9498 8.16417 18.2658 7.54648 18.826 7.3741C21.0382 6.65585 23.8969 6.42602 26.5256 6.72768C27.1146 6.7995 27.5455 7.33101 27.4737 7.91997C27.4019 8.50893 26.8704 8.93988 26.2814 8.86806C23.9543 8.59512 21.4117 8.81059 19.4868 9.42829C19.3719 9.44265 19.257 9.47138 19.1564 9.47138Z" fill="#655DC6" />
                    <path d="M19.1564 13.2925C18.6967 13.2925 18.2802 13.0052 18.1365 12.5455C17.9498 11.9853 18.2658 11.3676 18.826 11.1952C21.0239 10.477 23.8969 10.2472 26.5256 10.5488C27.1146 10.6206 27.5455 11.1521 27.4737 11.7411C27.4019 12.3301 26.8704 12.761 26.2814 12.6892C23.9543 12.4163 21.4117 12.6317 19.4868 13.2494C19.3719 13.2782 19.257 13.2925 19.1564 13.2925Z" fill="#655DC6" />
                    <path d="M19.1564 17.1136C18.6967 17.1136 18.2802 16.8263 18.1365 16.3666C17.9498 15.8064 18.2658 15.1887 18.826 15.0163C21.0239 14.2981 23.8969 14.0683 26.5256 14.3699C27.1146 14.4417 27.5455 14.9732 27.4737 15.5622C27.4019 16.1512 26.8704 16.5678 26.2814 16.5103C23.9543 16.2374 21.4117 16.4528 19.4868 17.0705C19.3719 17.0993 19.257 17.1136 19.1564 17.1136Z" fill="#655DC6" />
                </svg>
                Get free health resources to improve your life.
            </IonItem>
        </div>
    </SwiperSlide>
)