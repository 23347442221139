import { IonContent, IonItem, IonPage, IonText, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

export const TermsConditionsPage = () => {
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
  };
  return (
    <IonPage>
      <IonContent fullscreen className="lc-content-footer lc-terms">
        <IonItem lines="none">
          <IonText color="primary" className="lc-terms-title">
            <h2>Terms and conditions</h2>
          </IonText>
        </IonItem>

        <IonItem lines="none" className="lc-scrolling-box">
          <IonText color="dark">
            <p>
              These Terms &amp; Conditions (&ldquo;Terms&rdquo;) tell you terms on which you are permitted to use our website
              (&ldquo;Site&rdquo;). You should read these Terms carefully. By using the Site, you agree to comply with these
              Terms.
            </p>
            <h3>Information About the Site; Copyright</h3>
            <p>
              The Site is operated by Live Chair, Inc. (&ldquo;Live Chair&rdquo;). The Site and information contained on the Site
              is protected by copyright and other intellectual property rights. You may not create modified versions of the
              content of the Site, or copy the Site, without our prior written permission. You may not use information from the
              Site for commercial purposes without permission.
            </p>
            <h3>No Warranty; No Liability</h3>
            <p>
              All information contained on, or accessible through, this website is provided to you on an &ldquo;as is&rdquo;
              basis. Live Chair does not warrant that it is accurate, complete, current, or reliable. To the fullest extent
              permitted by law, Live Chair disclaims all warranties, either express or implied, statutory or otherwise. You should
              not rely on information on the Site, and you use the Site at your own risk. We disclaim all liability related to
              your use of the Site, or your inability to use the Site.
            </p>
            <h3>Not Medical Advice</h3>
            <p>
              The Site is not intended to provide medical advice. Always seek the advice of your physician or other qualified
              health provider with any questions you may have regarding a medical condition.
            </p>
            <h3>Content</h3>
            <p>
              You may be able to upload certain information or content onto the Site. By uploading information onto the Site, you
              certify that you have the right to upload the information. Information uploaded onto the Site is not confidential,
              and you grant to us an unlimited, perpetual, royalty free, fully paid-up, worldwide license to use that information
              for any purpose, in any format or media. We are not responsible or liable for the content or accuracy of any content
              posted by you or any other user of the Site. We have the right to remove any content or information posted on the
              Site at any time.
            </p>
            <p>Any content or information posted on the Site by a user must:</p>
            <ul>
              <li>Not be illegal, inflammatory, defamatory, obscene, or hateful</li>
              <li>Not promote violence</li>
              <li>Not be sexually explicit</li>
              <li>
                Not promote discrimination based on race, religion, gender, nationality, disability, age, or similar
                characteristics
              </li>
              <li>Not infringe or breach the rights of any third party</li>
              <li>Not threaten, abuse, harass, embarrass, alarm, or seek to annoy another person</li>
              <li>Not seek to incite another to engage in any of the prohibited behaviors</li>
              <li>Not seek to sell goods or services of a third party</li>
              <li>Not misrepresent the source of the information</li>
              <li>Be truthful, fair and appropriate given the nature and purpose of the Site</li>
            </ul>
            <h3>Permitted Uses of the Site</h3>
            <p>The Site may be used only for lawful purposes. The Site may not be used:</p>
            <ul>
              <li>To violate the law</li>
              <li>To harm or defraud anyone</li>
              <li>To send or transmit materials that could not be uploaded as content</li>
              <li>To transmit unsolicited promotional or advertising materials or spam</li>
              <li>
                To transmit any computer code, including (without limitation) any virus, worm, Trojan, time-bomb, logger, spyware,
                ransomware, or other harmful program or code that is intended, designed, or anticipated to cause harm or
                disruption to computers or networks
              </li>
              <li>
                To participate in a denial of service or other activity that is intended or likely to disrupt the efficient
                operation of the Site or any other website or computer network
              </li>
            </ul>
            <h3>Links to Other Websites</h3>
            <p>
              There may be links to other websites on the Site. The presence of a link to another website does not indicate any
              endorsement or approval of goods or services offered by the company or through the linked website. Each website has
              its own terms of use and privacy policy, which you should review before using the website. We are not responsible
              for the operation, terms or privacy policy of linked websites.
            </p>
            <h3>Enforcement of Terms</h3>
            <p>
              We have the right to determine whether there has been a violation of these Terms. If we determine the Terms have
              been violated, we have the right to determine the action that will be taken. The action to be taken may include (but
              not be limited to):
            </p>
            <ul>
              <li>Immediate termination of your right to use the Site. The termination may be temporary or permanent.</li>
              <li>Immediate removal of any content posted by you to the Site.</li>
              <li>Issuance of a warning or other communication to you.</li>
              <li>
                Legal proceedings against you, including seeking of damages (including indemnity) recovering all costs and losses
                suffered as a result of your violation of the Terms.
              </li>
              <li>Other forms of legal proceedings against you.</li>
              <li>Referral to law enforcement agencies for further action.</li>
            </ul>
            <p>We will not be liable for any damages which you may suffer as a result of the foregoing actions.</p>
            <h3>Jurisdiction and Applicable Law</h3>
            <p>The law of the State of Maryland will apply to all proceedings related to the Site and these Terms &amp; Use.</p>
            <h3>Changes to the Terms &amp; Use</h3>
            <p>
              We have the right to change, amend and modify the Terms at any time. We will post the revised Terms on the Site,
              with a changed &ldquo;Revision Date&rdquo;. You are expected to check this page from time to time for changes to the
              Terms, as they are binding on you. If you have questions, we can be contacted at{' '}
              <a href="mailto:health@livechairapp.com" target="_blank" rel="noopener noreferrer">
                health@livechairapp.com
              </a>{' '}
              or text/call 18038241150
            </p>
            <h3>Personal Data</h3>
            <p>
              Live Chair will: a) maintain reasonable safeguards to protect any personal information you input into the Site and
              b) refrain from disclosing your information to third parties without your prior consent.
            </p>
            <h3>General Release and Waiver of Liability</h3>
            <p>
              You may receive services from a barber that has a relationship with Live Chair. The services you receive from the
              barber are provided by the barber as an independent service provider and not as an agent of Live Chair. Such
              services may include receiving barber services as well as measurement of your blood pressure using an{' '}
              <em>OMRON 7 Series Wireless Bluetooth&reg; Wrist home blood pressure monitor </em>
              (&ldquo;Activities&rdquo;). Your agreement to these Terms includes a release and waiver related to the Activities
              for the benefit of Live Chair, the barber, and each of their directors, officers, employees, agents, successors and
              assigns (&ldquo;Released Parties&rdquo;). Accordingly:
            </p>
            <ol>
              <li>
                <strong>Waiver and Release</strong>. You release and forever discharge and hold harmless the Released Parties from
                all liability, claims and demands of whatever nature, either in law or in equity, which may arise from your
                participation in the Activities. You understand and acknowledge that this Waiver and Release discharges the
                Released Parties from any liability or claim that you may have against them with respect to any bodily injury,
                personal injury, illness, death or property damage that may result from activities involved in your business
                relationship with the barber.
              </li>
              <li>
                <strong>Assumption of Risk</strong>. You understand that the Activities may include measurement of your blood
                pressure and elevated blood pressure measurements may indicate the need to seek medical advice. You hereby
                expressly and specifically assume the risk of injury or harm and the responsibility to seek any necessary health
                care services and release the Released Parties from all liability for injury, illness, death or property damage
                resulting from the Activities.
              </li>
              <li>
                <strong>Insurance</strong>. You understand that the Released Parties do not assume any responsibility for or
                obligation to carry or maintain health insurance (or any other type of insurance whatsoever) coverage for you.
              </li>
              <li>
                I HAVE READ THIS RELEASE AND WAIVER, ACKNOWLEDGE THAT I HAD THE OPPORTUNITY TO DISCUSS ITS CONTENTS WITH MY
                BARBER, FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY AGREEING TO IT, AND HAVE
                AGREED TO IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT, ASSURANCE OR GUARANTEE.
              </li>
            </ol>
          </IonText>
        </IonItem>

        <IonGrid className="ion-no-padding lc-footer">
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={handleClose} type="button">
                Close
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
