import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonButton, IonCard, IonIcon } from '@ionic/react';
import { logEvent } from 'firebase/analytics';

import { analytics } from '../../../../common/firebase/firebase';
import { URL_SHARE_TOPIC_OF_THE_MONTH } from '../../../../common/constants/routers';
import { FindHelpModal } from '../../../../components/externalLinkModal/FindHelpModal';
import shareIcon from '../../../../assets/img/share-icon-white.svg';
import './styles.scss';

interface ITopicOfTheMonthProps {
  article: {
    category?: string;
    createdAt: Date;
    id: string;
    summary?: string;
    title?: string;
    topicOfTheMonth: boolean;
    url?: string;
  };
}

export const TopicOfTheMonth: React.FC<ITopicOfTheMonthProps> = ({
  article: { title, summary, createdAt, id, topicOfTheMonth, category, url },
}) => {
  const [isOpenArticleModal, setOpenArticleModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenArticleModal = () => {
    logEvent(analytics, 'page_view', { page_title: 'Find Help' });
    setOpenArticleModal(true);
  };

  return (
    <>
      <IonCard>
        <div className="lch-topic-of-the-month">
          <h5>{t('findhelp_container_title')}</h5>
          <div className="summary">
            <p>{t('findhelp_modal_description')}</p>
          </div>
          <IonButton onClick={() => handleOpenArticleModal()} type="submit" expand="block">
            {t('findhelp_call_to_action_button')}
          </IonButton>
        </div>
        <FindHelpModal show={isOpenArticleModal} handleOnhideModal={() => setOpenArticleModal(false)} />
      </IonCard>
      <div className="container">
        <div className="share-topic-of-the-month-container">
          <div className="label">
            <p className="reward-amount">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.99167 0.666504C4.39167 0.666504 0.666668 4.39984 0.666668 8.99984C0.666668 13.5998 4.39167 17.3332 8.99167 17.3332C13.6 17.3332 17.3333 13.5998 17.3333 8.99984C17.3333 4.39984 13.6 0.666504 8.99167 0.666504ZM11.6833 13.4915L9 11.8748L6.31667 13.4915C6 13.6832 5.60833 13.3998 5.69167 13.0415L6.4 9.9915L4.04167 7.94984C3.76667 7.70817 3.91667 7.24984 4.28333 7.2165L7.4 6.94984L8.61667 4.07484C8.75834 3.73317 9.24167 3.73317 9.38334 4.07484L10.6 6.9415L13.7167 7.20817C14.0833 7.2415 14.2333 7.69984 13.95 7.9415L11.5917 9.98317L12.3 13.0415C12.3833 13.3998 12 13.6832 11.6833 13.4915Z"
                  fill="#FFC833"
                />
              </svg>
              <a href="">$</a>5
            </p>
            <p className="referral-label">{t('learning_center_tab_topic_of_the_month-reward-banner.label')}</p>
          </div>
          <div className="share-button">
            <Link to={URL_SHARE_TOPIC_OF_THE_MONTH}>
              <IonButton className="share-topic-of-the-month-button" size="small" fill="clear">
                <IonIcon src={shareIcon} />
                <span>{t('button_title_share')}</span>
              </IonButton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
