import { clsx } from 'clsx';
import { useIonModal } from '@ionic/react';
import { Event, AppointmentDetails } from '@live-chair-health/lch-core';

import { checkAttendance } from '../utils/functions';
import { EventModalCard } from '../../upcoming-events/components/EventModalCard';
import '../styles.scss';

interface CellProps {
  className?: string;
  today?: boolean;
  eventsToRender?: Event[];
  appointmentsToRender?: AppointmentDetails[];
  userId?: string;
  onClick?: () => void;
}

const CurrentMonthCell: React.FC<CellProps> = ({
  children,
  className,
  eventsToRender,
  appointmentsToRender,
  userId,
  today = false,
}) => {
  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(EventModalCard, {
    onDismiss: handleDismiss,
    eventList: [...eventsToRender || [], ...appointmentsToRender || []],
    profileId: userId,
    attending: checkAttendance(eventsToRender as any, String(userId)),
  });

  return (
    <div
      onClick={() => {
        if (eventsToRender?.length || appointmentsToRender?.length) {
          present({
            swipeToClose: true,
            cssClass: 'event-modal-container',
          });
        }
      }}
      className={clsx(
        className,
        'calendar-cell',
        'calendar-cell-clickable',
        today && 'calendar-cell-today',
        eventsToRender?.length && checkAttendance(eventsToRender as any, String(userId)) && 'calendar-cell-confirmed',
        eventsToRender?.length && !checkAttendance(eventsToRender as any, String(userId)) && 'calendar-cell-unconfirmed'
      )}
    >
      {children}
    </div>
  );
};

export default CurrentMonthCell;
