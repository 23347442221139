import api from './api';

export const getRewards = async () => {
  return await api.get('/rewards/hp');
};

export const getPayoutAccountType = async () => {
  return await api.get('/rewards/payout-account/type');
};

export const addPayPalAccount = async (oAuthCode:string) => {
  return await api.post('/rewards/payout-account/paypal?code=' + oAuthCode);
};

export const addStripeAccount = async () => {
  return await api.post('/rewards/payout-account/stripe');
};

export const payoutRewards = async () => {
  return await api.post('/rewards/payout');
};

