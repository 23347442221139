export enum ActionCard {
  HEALTH_QUESTIONAIRIE = 'Health_Questionaire',
  INSURANCE_APPOINTMENT = 'InsuranceTrigger',
  REWARD_REDIRECT = 'RewardRedirect',
  PCP_LIST_REDIRECT = 'PCPTrigger',
  CHRONIC_DISEASES_APPOINTMENT = 'ChronicDiseasesAppointmentTrigger',
  QUIT_SMOKING_APPOINTMENT = 'QuitSmokingAppointmentTrigger',
  OPEN_ARTICLE_TRIGGER = 'OpenArticleTrigger',
  OPEN_EXTERNAL_ARTICLE_TRIGGER = 'OpenExternalArticleTrigger'
}
