/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import { validatePositiveNumbers } from '../../common/utils/positive-number';

interface IInputNumber {
  name: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  register: UseFormRegister<any>;
  initValue?: string | null;
}
export const InputNumber = ({ name, className, maxLength, disabled = false, initValue, register }: IInputNumber) => {
  const inputClass = classNames({ className }, 'lc-input');
  const value = initValue || undefined;
  const handleKeywordKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    return validatePositiveNumbers(event);
  };

  return (
    <input
      className={inputClass}
      type="text"
      maxLength={maxLength || undefined}
      disabled={disabled}
      {...register(name)}
      // @ts-ignore
      {...value}
      autoComplete="off"
      onKeyPress={handleKeywordKeypress}
    />
  );
};
