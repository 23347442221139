import { IonButton, IonContent, IonGrid, IonRow, IonCol, IonPage } from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { Network } from '@capacitor/network';
import { useState } from 'react';
import * as yup from 'yup';

import { URL_LOGIN, URL_NO_CONNECTION, URL_PROFILE_REGISTER } from '../../common/constants/routers';
import { registerWithEmailAndPassword } from '../../common/firebase/sign-in';
import { MessageToastHooks } from '../../common/hooks/message-toast';
import { EmailPassword } from '../../common/models/email-password';
import { UserState } from '../../common/store-atom/user-atom';
import { User } from '../../common/models/user';

import { ItemFormGroup } from '../../components/item-form-group/item-form-group';
import { InputText } from '../../components/inputs/input-text';
import { Spinner } from '../../components/spinner/spinner';
import { Header } from './components/header';
import './styles.scss';

export const EmailRegisterPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { messageToastErrorCustom } = MessageToastHooks();
  const setPatient = useSetRecoilState(UserState);
  const { t } = useTranslation();
  const history = useHistory();
  const messageToastErrorButtonTitle = t('register_email.customError_button_title');
  const messageToastErrorMessage = t('register_email.customError_message');
  const messageToastErrorHeader = t('register_email.customError_header');

  const schema = yup.object().shape({
    email: yup.string().required(t('message_field_required')).email(t('message_invalid_email')),
    password: yup.string().required(t('message_field_required')).min(8, t('message_length_password')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailPassword>({
    resolver: yupResolver(schema),
  });

  const errorCallback = () => {
    setIsLoading(false);
    messageToastErrorCustom(messageToastErrorHeader, messageToastErrorMessage, messageToastErrorButtonTitle, () =>
      history.push(URL_LOGIN)
    );
  };

  const postRegisterAction = (user: User) => {
    setPatient(user);
    setIsLoading(false);
    history.push(URL_PROFILE_REGISTER);
  };

  const onSubmit = async (data: EmailPassword) => {
    const { email, password } = data;
    const stats = await Network.getStatus();
    if (stats.connected === false) {
      return history.push(URL_NO_CONNECTION);
    }
    setIsLoading(true);
    await registerWithEmailAndPassword(email, password, postRegisterAction, errorCallback);
  };

  const handleOnClickCancel = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="block-center-email-register">
          <div className="lc-email-register-buttons-container">
            <Spinner isOpen={isLoading} />

            <Header />

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <ItemFormGroup label={t('register_email.label_email')} errorMessage={errors.email?.message}>
                <InputText name="email" type="email" register={register} />
              </ItemFormGroup>

              <ItemFormGroup label={t('register_email.label_password')} errorMessage={errors.password?.message}>
                <InputText name="password" type="password" register={register} />
              </ItemFormGroup>

              <IonGrid className="lc-footer register-email-footer-container">
                <IonRow>
                  <IonCol>
                    <IonButton expand="block" onClick={handleOnClickCancel} fill="outline" type="button">
                      {t('button_title_cancel')}
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand="block" type="submit">
                      {t('button_title_next')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
