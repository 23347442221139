import React, { ReactNode } from 'react';
import { IonCard, IonCardHeader, IonText } from '@ionic/react';

import './styles.scss';

export interface BlankCardProps {
  children?: ReactNode;
  options: {
    showHeaderImage?: boolean;
    title?: string;
    description?: string;
  } | null;
}

const BlankCard: React.FC<BlankCardProps> = ({ children, options }) => {
  return (
    <IonCard>
      {options?.showHeaderImage && <IonCardHeader />}
      <section className="blank-card">
        {options?.title && (
          <IonText>
            <h3>{options?.title}</h3>
          </IonText>
        )}

        {options?.description && (
          <IonText>
            <p>{options?.description}</p>
          </IonText>
        )}

        {children}
      </section>
    </IonCard>
  );
};

export default BlankCard;
