import { useState, useEffect, createContext } from 'react';
import { remoteConfig } from './firebase';

import 'firebase/remote-config';
import { defaultFlags, getFlags } from './remote-config';

interface IFlags {
  [value: string]: boolean;
}

export const FlagsContext = createContext<IFlags | null>(null);

const FlagsProvider = ({ defaults = defaultFlags, children }) => {
  const [flags, setFlags] = useState<IFlags>(defaults);

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 43200000,
    fetchTimeoutMillis: 60000,
  };

  remoteConfig.defaultConfig = defaults;

  useEffect(() => {
    getFlags()
      .then((remoteFlags) => {
        setFlags(remoteFlags || {});
      })
      .catch((error) => console.error(error));
  }, []);

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>;
};

export default FlagsProvider;
