import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonRouterLink,
} from '@ionic/react';
import { alertCircle } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { payoutRewards } from '../../../../common/adapters/earnings-adapter';
import {
  URL_MAIN_EARNINGS,
  URL_MAIN_EARNINGS_CONFIRMATION,
} from '../../../../common/constants/routers';
import { Spinner } from '../../../../components/spinner/spinner';
import './../styles.scss';

export const EarningsErrorPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const mutation = useMutation(payoutRewards, {
    onSuccess: () => {
      history.push(URL_MAIN_EARNINGS_CONFIRMATION);
    },
    onError: () => {
      setShowModal(true);
    },
  });

  const handleTrayAgain = () => {
    setShowModal(false);
    mutation.mutate();
  };

  const [showModal, setShowModal] = useState(true);

  return (
    <IonPage>
      <IonContent fullscreen>
        <Spinner isOpen={mutation.isLoading} />

        <IonModal isOpen={showModal} cssClass="lc-modal-backdrop lc-earning-error">
          <IonCard className="ion-text-center">
            <IonCardHeader>
              <IonIcon icon={alertCircle} color="danger"></IonIcon>
              <h4>{t('provider_earnings_error.title')}</h4>
              <p>
                {t('provider_earnings_error.sub_title')}
              </p>
            </IonCardHeader>

            <IonCardContent>
              <IonRouterLink href={URL_MAIN_EARNINGS}>Close</IonRouterLink>
              <IonButton fill="clear" size="small" onClick={handleTrayAgain}>
                {t('provider_earnings_error_button.label')}
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
