import { useIonToast } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCellphoneNumberVerificationCode } from '../../../common/adapters/user-profile-adapter';
import { VerifyCodeView } from './VerifyCodeView';

export const VerifyCodeContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [present, dismiss] = useIonToast();

  const onError = () => {
    setIsLoading(false);
    present({
      color: 'danger',
      buttons: [{ text: 'Ok', handler: () => dismiss() }],
      message: t('mobile_verification_error_toast'),
      duration: 2500,
    });
  };

  const onSuccess = () => {
    setIsLoading(false);
    present({
      color: 'success',
      buttons: [{ text: 'Ok', handler: () => dismiss() }],
      message: t('mobile_verification_confirmation_toast'),
      duration: 2500,
    });
  };

  const createVerificationCode = () => {
    setIsLoading(true);
    getCellphoneNumberVerificationCode().then(onSuccess).catch(onError);
  };

  return <VerifyCodeView createVerificationCode={createVerificationCode} isLoading={isLoading} />;
};
