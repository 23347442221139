import React from 'react';
import { IonButton, IonCard, IonCardContent, IonModal, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

interface IModalWarningProps {
  isOpen: boolean;
  titleHeader: string;
  subTitleHeader: string;
  redirect: string;
  callback: () => void;
  language?: string;
}

export const ModalWarning = ({ isOpen, titleHeader, subTitleHeader, redirect, callback, language }: IModalWarningProps) => {
  const hideModal = () => {
    callback();
  };
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleOnLeave = () => {
    if (language) {
      i18n.changeLanguage(language);
    }
    history.push(redirect);
  };

  return (
    <IonModal isOpen={isOpen} cssClass="lc-modal-backdrop">
      <div className="lc-modal-backdrop-inner">
        <IonCard className="ion-text-center">
          <br />
          <h4>{titleHeader}</h4>
          <p>{subTitleHeader}</p>

          <IonCardContent>
            <IonRouterLink onClick={hideModal}>{t('ModalWarning_button_cancel')}</IonRouterLink>
            <IonButton onClick={handleOnLeave} fill="clear">
              {t('ModalWarning_button_leave')}
            </IonButton>
          </IonCardContent>
        </IonCard>
      </div>
    </IonModal>
  );
};
