import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';
import { IonText } from '@ionic/react';
import { buildGraphData, options, plugin } from './metaData';
import { BiometricsResponse } from '../../common/models/biometrics';
import './styles.scss';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

interface BiometricsGraphProps {
  data: BiometricsResponse[];
}

const BiometricsGraph: React.FC<BiometricsGraphProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="graph-container">
      <div className="graph-view">
        {!!data.length ? (
          <Line data={buildGraphData(data)} plugins={[plugin]} options={options} />
        ) : (
          <IonText>
            <h5>{t('no_biometrics_found')}</h5>
          </IonText>
        )}
      </div>
    </div>
  );
};

export default BiometricsGraph;
