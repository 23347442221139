/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface IInputText {
  name: string;
  type?: 'password' | 'text' | 'number' | 'email';
  className?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  initValue?: string | null;
}
export const InputPhone = ({ name, type = 'text', className, disabled = false, register }: IInputText) => {
  const inputClass = classNames({ className }, 'lc-input');

  return (
    <InputMask
      mask={'(999) 999 - 9999'}
      className={inputClass}
      type={type}
      disabled={disabled}
      {...register(name)}
      autoComplete="off"
    />
  );
};
