import { IonButton, IonContent, IonItem, IonPage, IonIcon, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { checkmarkCircle } from 'ionicons/icons';

import { URL_MAIN_HOME } from '../../../../common/constants/routers';
import './../styles.scss';

export const AppointmentConfirmationPage = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="confirmation-page">
          <div className="confirmation-container">
            <section className="icon-container">
              <IonIcon icon={checkmarkCircle} className="icon" />
            </section>

            <IonText>
              <h2>{t('appointment_requested')}</h2>
            </IonText>

            <IonText className="ion-text-center">
              <p>{t('live_chair_will_contact_you')}</p>
            </IonText>

            <IonButton routerLink={URL_MAIN_HOME} type="button" expand="block">
              {t('got_it')}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
