import { collection, getDocs, SnapshotOptions } from 'firebase/firestore';
import { QUESTIONNAIRE_QUESTIONS } from '../constants/firestore-collections';
import { db } from './firebase';

export const getCollectionQuestions = async () => {
  const collectionQuestions: Array<any> = [];
  const snapshotOptions : SnapshotOptions = {serverTimestamps: "estimate"}

  const querySnapshot = await getDocs(collection(db, QUESTIONNAIRE_QUESTIONS)).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      let document = { id: doc.id, data: doc.data() };
      collectionQuestions.push(document);
    });
  });

return collectionQuestions
}
