import React from 'react';
import { mail } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { URL_REGISTER_EMAIL } from '../../../common/constants/routers';

export const EmailButton: React.FC = () => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(URL_REGISTER_EMAIL);
  };

  return (
    <IonButton onClick={handleOnClick} className="register-button" expand="block">
      <IonIcon slot="start" icon={mail} />
      Email
    </IonButton>
  );
};
