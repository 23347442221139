import { useEffect } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { getCurrentUser } from './common/firebase/session-management';

/* Theme variables */
import './theme/rebrand.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Import Swiper styles
import 'swiper/swiper.scss';
import '@ionic/react/css/ionic-swiper.css';

import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/keyboard/keyboard.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/modules/zoom/zoom.min.css';
import '@ionic/react/css/ionic-swiper.css';

/* Main Styles */
import './styles/text.scss';
import './styles/form.scss';
import './styles/background.scss';
import './styles/modal.scss';
import './styles/utils.scss';
import './styles/ion-footer.scss';
import './styles/ion-button.scss';
import './styles/ion-content.scss';
import './styles/ion-item.scss';
import './styles/ion-alert.scss';
import './styles/ion-toast.scss';
import './styles/ion-progress-bar.scss';
import './styles/ion-card.scss';
import './styles/ion-header.scss';
import './styles/ion-tab-bar.scss';
import './styles/ion-avatar.scss';
import './styles/components/empty-section.scss';
import './styles/components/confirmation.scss';

import { QueryClient, QueryClientProvider } from 'react-query';

/** Components */
import { RecoilRoot } from 'recoil';
import PWAPrompt from 'react-ios-pwa-prompt';
import { EmailRegisterPage } from './pages/email-register';
import { ProfileRegisterPage } from './pages/profile-register';
import { MenuTabRoot } from './pages/menu';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ForgotPasswordPage } from './pages/forgot-password';
import { userUrlProvider } from './common/hooks/useUrlProvider';
import {
  URL_ACCOUNT_SETTINGS,
  URL_CHANGE_PASSWORD,
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_MAIN,
  URL_PROFILE_REGISTER,
  URL_REGISTER,
  URL_REGISTER_EMAIL,
  URL_AUTH_PHONE,
  URL_TERMS_CONDITIONS,
  URL_CARD_PAGE,
  URL_500_INTERNAL_ERROR_PAGE,
  URL_NO_CONNECTION,
  URL_REFERRAL_ARTICLE_LANDING,
  URL_MAIN_HOME_HEALTH_QUESTIONNAIRE,
  URL_CLIENT_ONBOARDING,
  URL_HEALTH_QUESTIONNAIRE_CONFIRMATION,
  URL_INVALID_CHECK_IN,
  REPRESENTATIVE_URL_LOGIN,
  URL_REPRESENTATIVE_BASE,
  URL_REGISTER_PHONE_VERIFY,
  URL_EXPIRED_LINK,
  URL_DOB_VERIFICATION,
  URL_INVALID_UNIQUE_LINK,
} from './common/constants/routers';
import { ChangePasswordPage } from './pages/change-password';
import { TermsConditionsPage } from './pages/terms-conditions';
import { LoginPage } from './pages/login/index.';
import { RegisterPage } from './pages/register';
import { NotFoundPage } from './components/error-page/404/index';
import { NoConnectionPage } from './components/error-page/no-connection/index';
import { InternalServerErrorPage } from './components/error-page/50x/index';
import { ReferralArticleLandingPageContainer } from './pages/referral-article-landing/components/ReferralArticleLandingPageContainer';

import { AccountSettings } from './pages/account-settings';
import { PageCard } from './pages/cards-pages';
import { HealthQuestionnairePage } from './pages/tabs/home/components/take-next-step-card/components/health-questionnaire';
import { OnboardingSliderBody } from './components/client-onboarding';
import { SliderConfirmation } from './pages/questionares/components/sliders/slider-confirmation';
import { InvalidCheckInPage } from './components/error-page/invalid-checkin';
import { RepresentativeLoginPage } from './pages/representatives/pages/login';
import { ExpiredLink } from './pages/expired-link';

import FlagsProvider from './common/firebase/flags-providers';
import { PhoneRegisterPage } from './pages/phone-register';
import { PhoneCodeVerify } from './pages/phone-code-verify/PhoneCodeVerify';
import { identifyUser } from './logrocketSetup';
import { DobVerification } from './pages/dob-verification';
import { InvalidUniqueLink } from './components/error-page/invalid-unique-link';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const App = () => {
  const { redirectTo } = userUrlProvider();

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  serviceWorkerRegistration.register({ onUpdate: onSWUpdate });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    }
  }, []);

  useEffect(() => {
    getCurrentUser().then((user) => {
      if (user) {
        identifyUser(user?.uid);
      }
    });
  });

  const redirectSetup = {
    pathname: redirectTo,
    search: window.location.search,
    hash: window.location.hash,
  };

  return (
    <>
      <PWAPrompt
        timesToShow={6}
        permanentlyHideOnDismiss
        copyAddHomeButtonLabel="2) Press 'Add to Home Screen'. You may have to scroll."
      />
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <FlagsProvider>
            <IonApp className="theme">
              <IonReactRouter>
                <Switch>
                  <Route exact path="/">
                    <Redirect to={redirectSetup} />
                  </Route>
                  <Route exact path={URL_LOGIN} component={LoginPage} />
                  <Route exact path={REPRESENTATIVE_URL_LOGIN} component={RepresentativeLoginPage} />
                  <Route path={URL_REPRESENTATIVE_BASE} component={MenuTabRoot} />
                  <Route exact path={URL_REFERRAL_ARTICLE_LANDING} component={ReferralArticleLandingPageContainer} />
                  <Route exact path={URL_REGISTER} component={RegisterPage} />
                  <Route exact path={URL_REGISTER_EMAIL} component={EmailRegisterPage} />
                  <Route exact path={URL_AUTH_PHONE} component={PhoneRegisterPage} />
                  <Route exact path={URL_REGISTER_PHONE_VERIFY} component={PhoneCodeVerify} />
                  <Route exact path={URL_PROFILE_REGISTER} component={ProfileRegisterPage} />
                  <Route exact path={URL_FORGOT_PASSWORD} component={ForgotPasswordPage} />
                  <Route exact path={URL_HEALTH_QUESTIONNAIRE_CONFIRMATION} component={SliderConfirmation} />
                  <Route exact path={URL_TERMS_CONDITIONS} component={TermsConditionsPage} />
                  <Route exact path={URL_CHANGE_PASSWORD} component={ChangePasswordPage} />
                  <Route exact path={URL_ACCOUNT_SETTINGS} component={AccountSettings} />
                  <Route exact path={URL_EXPIRED_LINK} component={ExpiredLink} />
                  <Route exact path={URL_DOB_VERIFICATION} component={DobVerification} />
                  <Route exact path={URL_INVALID_UNIQUE_LINK} component={InvalidUniqueLink} />
                  <Route path={URL_MAIN_HOME_HEALTH_QUESTIONNAIRE} component={HealthQuestionnairePage} exact />
                  <Route path={URL_CLIENT_ONBOARDING} component={OnboardingSliderBody} exact />
                  <Route exact path={URL_CARD_PAGE} component={PageCard} />
                  <Route path={URL_MAIN} component={MenuTabRoot} />
                  <Route exact path={URL_500_INTERNAL_ERROR_PAGE} component={InternalServerErrorPage} />
                  <Route exact path={URL_NO_CONNECTION} component={NoConnectionPage} />
                  <Route exact path={URL_INVALID_CHECK_IN} component={InvalidCheckInPage} />
                  <Route exact path={URL_INVALID_CHECK_IN} component={InvalidCheckInPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </IonReactRouter>
            </IonApp>
          </FlagsProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </>
  );
};
