import i18next from 'i18next';
import { getPatient } from './user-profile-adapter';
import { getBarberShops, getHairProfessionals } from './barber-adapter';
import { BarberShop } from '../models/barber-shop';
import { OptionsModel } from '../models/option-model';
import { HairProfessional } from '../models/HairProfessional';

export const getAccountSettings = async (userType: Number | null) => {
  const profile = await (await getPatient()).data.data;
  profile.language = profile.language || 'en';

  const barberShops = (await getBarberShops(profile.language)).map((shop: BarberShop, index: number) => {
    const optionsModel: OptionsModel = {
      value: String(shop.id),
      text: index === 0 && userType === 1 ? i18next.t('independent_hair_professional') : shop.name,
    };

    return optionsModel;
  });

  const hairProfessional = (await getHairProfessionals(profile.barberShop, profile.language)).map((hp: HairProfessional) => {
    const optionsModel: OptionsModel = { value: String(hp.id), text: `${hp.firstName} ${hp.lastName}` };
    return optionsModel;
  });
  return { profile, barberShops, hairProfessional };
};
