import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { TakeNextStepCard } from '../tabs/home/components/take-next-step-card';
import { StatusCard } from '../tabs/home/enums/status-card';
import { TypeCard } from '../tabs/home/enums/type-cards';

import { ICardProps } from '../tabs/home/interfaces/ICardProps';
import { DiseaseType } from '../tabs/home/enums/disease-type';
import { ActionCard } from '../tabs/home/enums/action_card';

export const PageCard = () => {
  const genericCardCase1: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.ASSIGNED,
    title: 'Chronic Disease',
    type: TypeCard.STEP,
    action: 'ChronicDiseasesTrigger',
  };
  const genericCardCase2: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.ASSIGNED,
    title: 'Message around our why + outline rewards system',
    type: TypeCard.GENERIC,
  };
  const genericCardCase3: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.PENDING,
    action: 'link',
    title: 'Message around our why + outline rewards system',
    type: TypeCard.GENERIC,
  };
  const genericCardCase4: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.DONE,
    title: 'Message around our why + outline rewards system',
    type: TypeCard.GENERIC,
  };

  const takeNextStepCardCase1: ICardProps = {
    action: ActionCard.OPEN_ARTICLE_TRIGGER,
    createdAt: "2021-09-30T19:50:20.969Z",
    detail: "As it's a disease that affected members of your family, it's important you learn about it",
    status: StatusCard.ASSIGNED,
    title: "Learn about Cardiovascular disease",
    type: TypeCard.STEP,
    customProperties: {diseaseType: DiseaseType.DIABETES, url: "https://www.heart.org/en/health-topics/consumer-healthcare/what-is-cardiovascular-disease/top-10-myths-about-cardiovascular-disease"}
  };
  const takeNextStepCardCase2: ICardProps = {
    action: ActionCard.OPEN_ARTICLE_TRIGGER,
    createdAt: "2021-09-28T17:26:51.215Z",
    detail: "As it's a disease that affected members of your family, it's important you learn about it",
    status: StatusCard.DONE,
    title: "Learn about Hypertension",
    type: TypeCard.STEP,
    customProperties: {diseaseType: DiseaseType.CARDIOVASCULAR_DISEASE, url: "https://www.heart.org/en/health-topics/diabetes/understand-your-risk-for-diabetes"}
  };

  const takeNextStepCardCase3: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.ASSIGNED,
    rewardAmount: 100,
    action: 'QuitSmokingAppointmentTrigger',
    title: 'Quit Smoking',
    detail: 'Schedule a call to check programs to quit smoking',
    type: TypeCard.STEP,
  };

  const takeNextStepCardCase4: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.PENDING,
    rewardAmount: 50,
    action: 'InsuranceTrigger',
    title: 'Health Insurance',
    detail: 'Schedule a call to learn more about your options for Health Insurance',
    type: TypeCard.STEP,
  };
  const takeNextStepCardCase5: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.PENDING,
    rewardAmount: 100,
    action: 'ChronicDiseasesAppointmentTrigger',
    title: 'Chronic Diseases',
    detail: 'Schedule an appointment to check programs for chronic diseases',
    type: TypeCard.STEP,
  };

  const takeNextStepCardCase6: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.PENDING,
    rewardAmount: 100,
    action: 'QuitSmokingAppointmentTrigger',
    title: 'Quit Smoking',
    detail: 'Schedule a call to check programs to quit smoking',
    type: TypeCard.STEP,
  };

  const takeNextStepCardCase7: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.DONE,
    rewardAmount: 50,
    action: 'InsuranceTrigger',
    title: 'Health Insurance',
    detail: 'Schedule a call to learn more about your options for Health Insurance',
    type: TypeCard.STEP,
  };
  const takeNextStepCardCase8: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.DONEMAYUS,
    rewardAmount: 100,
    action: 'ChronicDiseasesAppointmentTrigger',
    title: 'Chronic Diseases',
    detail: 'Schedule an appointment to check programs for chronic diseases',
    type: TypeCard.STEP,
  };

  const takeNextStepCardCase9: ICardProps = {
    createdAt: '2021-11-9',
    status: StatusCard.DONEMAYUS,
    rewardAmount: 100,
    action: 'QuitSmokingAppointmentTrigger',
    title: 'Quit Smoking',
    detail: 'Schedule a call to check programs to quit smoking',
    type: TypeCard.STEP,
  };

  //const RewardCardCase1: ICardProps = { createdAt: '2021-11-9', status: StatusCard.DONE, action: 'link', title: 'Take the next step!', detail: 'Schedule an appointment with a PCP', type: 4 };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle color="primary">Cards</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="lc-full-spot-bg lc-homepage">
        <div className="lc-margin-top-md lc-justify-center">
          <TakeNextStepCard {...takeNextStepCardCase1} />
          <TakeNextStepCard {...takeNextStepCardCase2} />
          <TakeNextStepCard {...takeNextStepCardCase3} />
          <TakeNextStepCard {...takeNextStepCardCase4} />
          <TakeNextStepCard {...takeNextStepCardCase5} />
          <TakeNextStepCard {...takeNextStepCardCase6} />
          <TakeNextStepCard {...takeNextStepCardCase7} />
          <TakeNextStepCard {...takeNextStepCardCase8} />
          <TakeNextStepCard {...takeNextStepCardCase9} />
        </div>
      </IonContent>
    </IonPage>
  );
};
