import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../../common/firebase/session-management';
import { ICalendar } from '../../common/models/events';
import EventCard from './components/EventCard';
import { checkAttendance } from '../events-calendar/utils/functions';

import './styles.scss';

interface UpcomingEventsProps {
  calendar?: ICalendar;
}

const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ calendar = {
  events: [],
  scheduledAppointments: [],
} }) => {
  const [userId, setUserId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    getCurrentUser().then((response) => {
      if (response) {
        setUserId(response.uid);
      }
    });
  }, []);

  const renderCalendar = () => (
    // @ts-ignore
    Object.keys(calendar).reduce((acc: any, curr: any) => {
      if (curr === 'events') {
        calendar[curr].forEach((eventItem) => {
          acc.push(
            <EventCard
              key={eventItem.id}
              eventData={eventItem}
              attending={!!checkAttendance(eventItem, userId)}
              eventList={[...calendar.events, ...calendar.scheduledAppointments]}
              type='event'
            />
          );
        });
      } else {
        calendar[curr].forEach((appointment) => {
          acc.push(
            <EventCard
              key={appointment.id}
              appointmentData={appointment}
              attending={true}
              eventList={[...calendar.events, ...calendar.scheduledAppointments]}
              type='appointment'
            />
          );
        });
      }
      return acc;
    }, [])
  );

  return (
    <section className="events-list">
      {calendar.events.length || calendar.scheduledAppointments.length ? (
        <>
          {renderCalendar()}
        </>
      ) : (
        <span className="event-empty-state">{t('events-management-empty-state')}</span>
      )}
    </section>
  );
};

export default UpcomingEvents;
