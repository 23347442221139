import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import { getSignedUpClients } from '../../../../../common/adapters/csr-adapter';
import { RepresentativeUsersView } from './RepresentativeUsersView';

export interface CSRClient {
  client: {
    firstName: string;
    lastName: string;
    createdAt: string;
  };
  nextStep: string;
}

export const RepresentativeUsersContainer = () => {
  const { t } = useTranslation();
  const { isLoading, data, isSuccess } = useQuery('getSignedUpClients', getSignedUpClients);
  const history = useHistory();

  const clientsList = useMemo<Array<CSRClient>>(() => {
    if (!isSuccess) return [];

    return data?.data.data.map((client) => ({
      client,
      nextStep: null,
    }));
  }, [isSuccess, data]);

  return <RepresentativeUsersView t={t} clientsList={clientsList} isLoading={isLoading} history={history} />;
};