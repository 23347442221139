import firebase from 'firebase/compat';
import { auth } from './firebase';
import { reauthenticateWithCredential, updatePassword } from 'firebase/auth';

export const changePassword = async (oldPassword: string, newPassword: string) => {
  if (auth.currentUser) {
    const email = auth.currentUser.email;
    if (email) {
      const authCredential: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
      await reauthenticateWithCredential(auth.currentUser, authCredential);
      return updatePassword(auth.currentUser, newPassword);
    }
  } else {
    throw new Error('The session expired, please log in again.');
  }
};
