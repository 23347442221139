import { useEffect, useState } from 'react';
import { Storage } from '@capacitor/storage';

import { ReferralArticleLandingPageView } from './ReferralArticleLandingPageView';
import api from '../../../common/adapters/api';

export const ReferralArticleLandingPageContainer = () => {
  const currentUrl = window.location.search;
  const urlParams = new URLSearchParams(currentUrl);
  const [articleUrl, setArticleUrl] = useState('');
  const [articleTitle, setArticleTitle] = useState('');

  const getArticle = async (articleId) => {
    const response = await api.get(`/articles/${articleId}`);
    if (response.status === 200) {
      setArticleUrl(response.data.data.url);
      setArticleTitle(response.data.data.title);
    }
  };

  const setReferralCode = async (referralCode) => {
    await Storage.set({
      key: 'referralCode',
      value: referralCode,
    });
  };

  const checkReferralCode = async () => {
    const { value } = await Storage.get({ key: 'referralCode' });
  };

  const getProfileIdFromUrl = async () => {
    const profileId = urlParams.get('profileId');
    const articleId = urlParams.get('articleId') ? urlParams.get('articleId') : '';
    await setReferralCode(profileId);
    await checkReferralCode();
    await getArticle(articleId);
  };

  useEffect(() => {
    getProfileIdFromUrl();
  }, [currentUrl]);
  return (
    <>
      {' '}
      <ReferralArticleLandingPageView articleTitle={articleTitle} articleUrl={articleUrl} />
    </>
  );
};
