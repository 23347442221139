import { IonButton, IonContent, IonItem, IonLoading, IonPage, IonText, IonFooter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import { URL_MAIN_HOME, URL_REGISTER } from '../../../common/constants/routers';
import './styles.scss';

export const ReferralArticleLandingPageView = ({ articleUrl, articleTitle }) => {
  const { t } = useTranslation();
  const user = getAuth().currentUser;

  return (
    <>
      <IonPage className="referral-page">
        <IonContent className="lc-modal">
          <div
            className="lc-modal-wrapper no-padding"
            style={{
              position: 'relative',
              overflow: 'scroll',
              width: '100%',
              height: '100%',
              borderRadius: '15px',
              overflowX: 'hidden',
              overflowY: 'hidden',
              padding: '0px',
              marginTop: '0',
              marginBottom: '0px',
              marginLeft: '0px',
              marginRight: '0px',
            }}
          >
            <IonItem lines="none" className="lc-modal-header">
              <IonText color="primary">
                <h3>{articleTitle! ? articleTitle : t('referral_article_landing_page_default_title')}</h3>
              </IonText>
            </IonItem>
            <iframe
              style={{
                border: '0px',
                width: '100%',
                height: '100%',
                left: '0px',
                position: 'relative',
                top: '0px',
                padding: '0px',
                marginTop: '0',
                marginBottom: '0px',
                marginLeft: '0px',
                marginRight: '0px',
              }}
              src={articleUrl}
            >
              <IonLoading isOpen={true} backdropDismiss={true} duration={2000} showBackdrop={true} translucent={true} />
            </iframe>
          </div>
        </IonContent>
        <IonFooter className="banner-container ion-text-center">
          <h3>{t('referral_article_landing_page_footer-title')}</h3>
          <p>{t('referral_article_landing_page_footer-sub-title')}</p>

          {user ? (
            <Link to={URL_MAIN_HOME}>
              <IonButton className="banner-button" expand="block">
                {t('referral_article_landing_page_footer_logged_in_button')}
              </IonButton>
            </Link>
          ) : (
            <>
              {' '}
              <Link to={URL_REGISTER}>
                <IonButton className="banner-button" expand="block">
                  {t('referral_article_landing_page_footer_button')}
                </IonButton>
              </Link>
            </>
          )}
        </IonFooter>
      </IonPage>
    </>
  );
};
