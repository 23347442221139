import api from './api';

export const fetchCalendar = async (lat: number, lng: number): Promise<any> => {
  return await api.get(`/calendar?lat=${lat}&lng=${lng}`);
};

export const confirmAttendance = async (eventId: string) => {
  return await api.patch(`/events/${eventId}/attendees`);
};

export const cancelAttendance = async (eventId: string) => {
  return await api.patch(`/events/${eventId}/remove-attendee`);
};
