import { IonPage, IonContent, IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { URL_MAIN_HOME } from '../../../common/constants/routers';
import '../styles.scss';

export const NoConnectionPage = () => {
  const history = useHistory();
  const handleOnClick = () => {
    return history.push(URL_MAIN_HOME);
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="block-center-error-page">
          <div className="lc-error-page-buttons-container">
            <div className="lch-error-container">
              <div className="error-code">
                <h6>Error</h6>
              </div>
              <div className="error-title">
                <h6>
                  NO <br /> INTERNET
                </h6>
              </div>
              <div className="error-message">
                <h6>
                  You have no internet connection. <br />
                  Please try again later
                </h6>
              </div>
              <div className="lch-error-footer">
                <IonButton
                  onClick={() => {
                    handleOnClick();
                  }}
                  className="lch-error-redirect-button"
                >
                  Back to Home
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
