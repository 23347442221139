import { IonButton, IonContent, IonGrid, IonRow, IonCol, IonPage } from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Network } from '@capacitor/network';
import * as yup from 'yup';

import { URL_LOGIN, URL_NO_CONNECTION, URL_REGISTER_PHONE_VERIFY } from '../../common/constants/routers';
import { MessageToastHooks } from '../../common/hooks/message-toast';
import { PhonePassword } from '../../common/models/phone-password';
import { ItemFormGroup } from '../../components/item-form-group/item-form-group';
import { Spinner } from '../../components/spinner/spinner';
import { Header } from './components/header';
import { InputPhone } from '../../components/inputs/input-phone';
import { signInWithPhoneNumber } from 'firebase/auth';
import { generateRecaptcha, phoneAuth } from '../../common/firebase/phone-auth';
import { phoneNumberState, comesFromAccountSettingsState } from '../../common/store-atom/register-atom';
import { auth } from '../../common/firebase/firebase';
import { addCountryCode } from '../../common/utils/cel-phone';

import './styles.scss';

export const PhoneRegisterPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { messageToastErrorCustom } = MessageToastHooks();
  const setPhoneNumber = useSetRecoilState(phoneNumberState);
  const setComesFromAccountSettingsPage = useSetRecoilState(comesFromAccountSettingsState);
  const { t } = useTranslation();
  const history = useHistory();
  const messageToastErrorButtonTitle = t('register_email.customError_button_title');
  const messageToastErrorMessage = t('register_email.customError_message');
  const messageToastErrorHeader = t('register_email.customError_header');

  const schema = yup.object().shape({
    phone: yup
      .string()
      .transform((value) => value.replace(/[^A-Z0-9]+/gi, ''))
      .required(t('message_field_required'))
      .min(10, t('page_profile_register.field_validation_error.phoneNumber')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ phone: string; password: string }>({
    resolver: yupResolver(schema),
  });

  const errorCallback = () => {
    setIsLoading(false);
    messageToastErrorCustom(messageToastErrorHeader, messageToastErrorMessage, messageToastErrorButtonTitle, () =>
      history.push(URL_LOGIN)
    );
  };

  const onSubmit = async (data: PhonePassword) => {
    setIsLoading(true);
    const { phone } = data;
    setPhoneNumber({ phone: addCountryCode(phone) });
    const stats = await Network.getStatus();
    if (stats.connected === false) {
      return history.push(URL_NO_CONNECTION);
    }

    generateRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(phoneAuth, addCountryCode(phone), appVerifier)
      .then((confirmationResult) => {
        setIsLoading(false);
        window.confirmationResult = confirmationResult;
        setComesFromAccountSettingsPage({ comesFromAccountSettings: false });
        history.push(URL_REGISTER_PHONE_VERIFY);
      })
      .catch((e) => {
        errorCallback();
      });
  };

  const handleOnClickCancel = () => {
    history.replace('/register');
  };

  useEffect(() => {
    auth.signOut();
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="block-center-phone-register">
          <div className="lc-phone-register-buttons-container">
            <Spinner isOpen={isLoading} />

            <Header />

            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <ItemFormGroup label={t('page_profile_register.label_cell_phone')} errorMessage={errors.phone?.message}>
                <InputPhone name="phone" register={register} />
              </ItemFormGroup>

              <div id="sign-in-button" />
              <IonGrid className="lc-footer register-phone-footer-container">
                <IonRow>
                  <IonCol>
                    <IonButton expand="block" onClick={() => handleOnClickCancel()} fill="outline" type="button">
                      {t('button_title_cancel')}
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand="block" type="submit">
                      {t('button_title_next')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
