import { IonButton, IonContent, IonItem, IonPage, IonRouterLink, IonText } from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import * as yup from 'yup';
import { sendPasswordResetEmail } from 'firebase/auth';

import { MessageToastHooks } from '../../common/hooks/message-toast';
import { EmailPassword } from '../../common/models/email-password';
import { InputText } from '../../components/inputs/input-text';
import { URL_LOGIN } from '../../common/constants/routers';
import { Spinner } from '../../components/spinner/spinner';
import { auth } from '../../common/firebase/firebase';

import { ItemFormGroup } from '../../components/item-form-group/item-form-group';

export const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const { t } = useTranslation();
  const { messageToastError, messageToastSuccess } = MessageToastHooks();

  const schema = yup.object().shape({
    email: yup.string().required(t('message_field_required')).email(t('message_invalid_email')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: EmailPassword) => {
    setIsLoading(true);
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        setIsLoading(false);
        setSentEmail(true);
        messageToastSuccess(undefined, t('forgotPassword.message_success_check_your_inbox'));
      })
      .catch(() => {
        setIsLoading(false);
        setSentEmail(false);
        messageToastError(null, t('forgotPassword.message_error_email_not_found'));
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <Spinner isOpen={isLoading} />

        <IonItem lines="none">
          <IonText>
            <h1>{t('forgotPassword.header_title')}</h1>
          </IonText>
        </IonItem>

        {/* @ts-ignore */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ItemFormGroup label={t('forgotPassword.label_email')} errorMessage={errors && errors.email && errors.email.message ? String(errors.email.message) : ''}>
            <InputText name="email" register={register} />
          </ItemFormGroup>

          <IonButton className={sentEmail ? 'cash-out-disabled' : ''} disabled={sentEmail} expand="block" type="submit">
            {t('forgotPassword.button_title_send_recovery_password')}
          </IonButton>

          <IonItem className="ion-text-center ion-margin-top" lines="none">
            <IonText>
              <IonRouterLink color="primary" routerLink={URL_LOGIN}>
                <h4 className="lc-bold ion-margin-top">{t('forgotPassword.title_back')}</h4>
              </IonRouterLink>
            </IonText>
          </IonItem>
        </form>
      </IonContent>
    </IonPage>
  );
};
