/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import { validatePositiveNumbers } from '../../common/utils/positive-number';

interface IInputText {
  name: string;
  type?: 'password' | 'text' | 'number' | 'email';
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  register: UseFormRegister<any>;
  initValue?: string | null;
}
export const InputText = ({ name, type = 'text', className, maxLength, disabled = false, initValue, register }: IInputText) => {
  const inputClass = classNames({ className }, 'lc-input');
  const maxLengthProps = maxLength || {};
  const value = initValue || undefined;
  const handleKeywordKeypress = (event: React.KeyboardEvent<HTMLImageElement>) => {
    return validatePositiveNumbers(event);
  };

  return (
    <input
      className={inputClass}
      type={type}
      disabled={disabled}
      {...register(name)}
      {...maxLengthProps}
      // @ts-ignore
      {...value}
      autoComplete="off"
      {...(type === 'number' ? { onKeyPress: () => handleKeywordKeypress } : undefined)}
    />
  );
};
