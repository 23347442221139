/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { URL_MAIN_HOME } from '../../common/constants/routers';
import { InputPassword } from '../../components/inputs/input-password';
import { changePassword } from '../../common/firebase/change-password';
import { ModalWarning } from '../../components/modal-warning';
import { isEmpty } from '../../common/utils/string-empty';
import { handleErrorPassword } from '../../common/exceptions/handle-error';

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().nullable(true).required('message_field_required').min(8, 'message_length_password'),
  newPassword: yup.string().nullable(true).required('message_field_required').min(8, 'message_length_password'),
  confirmPassword: yup
    .string()
    .nullable(true)
    .oneOf([yup.ref('newPassword'), null], 'message_password_unmatch')
    .required('message_field_required'),
});

export const ChangePasswordPage = () => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModalWarning, setOpenModalWarning] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);

    changePassword(data.oldPassword, data.newPassword)
      .then(() => {
        reset();
        present({
          color: 'success',
          message: t('changes_saved_confirmation.message'),
          duration: 800,
          position: 'top',
          cssClass: 'ion-text-center lc-toast-top',
        });
        history.replace(URL_MAIN_HOME);
      })
      .catch((error) => {
        const errorMessage = handleErrorPassword(error.message);
        present({
          color: 'danger',
          buttons: [{ text: 'Ok', handler: () => dismiss() }],
          message: errorMessage,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isformEmpty = () => {
    const fields = getValues();
    return isEmpty(fields.oldPassword) && isEmpty(fields.newPassword);
  };

  const redirectLastPage = () => {
    const formStateChanged = isformEmpty();
    if (formStateChanged) {
      history.goBack();
    } else setOpenModalWarning(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonItem lines="none" className="lc-back-arrow" slot="start" onClick={redirectLastPage}>
            <IonIcon icon={arrowBack} color="primary" />
          </IonItem>
          <IonTitle color="primary">{t('page_change_password_header.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="lc-full-spot-bg lc-content-padding">
        <IonLoading isOpen={isLoading} message={t('spinner.message')} />

        <ModalWarning
          isOpen={openModalWarning}
          titleHeader={t('leave_without_saving_modal.message')}
          subTitleHeader={t('if_you_leave_without_saving_confirmation.message')}
          redirect={URL_MAIN_HOME}
          callback={() => setOpenModalWarning(false)}
        />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <IonItem lines="none" className={errors.oldPassword?.message ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}>
            <IonLabel position="stacked" color={errors.oldPassword?.message ? 'danger' : 'primary'}>
              {t('page_change_password_old_password.label')}
            </IonLabel>
            <InputPassword
              name="oldPassword"
              errorMessage={errors.oldPassword?.message ? t(errors.oldPassword?.message) : ''}
              register={register}
            />
          </IonItem>

          <IonItem lines="none" className={errors.newPassword?.message ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}>
            <IonLabel position="stacked" color={errors.newPassword?.message ? 'danger' : 'primary'}>
              {t('page_change_password_new_password.label')}
            </IonLabel>
            <InputPassword
              name="newPassword"
              errorMessage={errors.newPassword?.message ? t(errors.newPassword?.message) : ''}
              register={register}
            />
          </IonItem>

          <IonItem
            lines="none"
            className={errors.confirmPassword?.message ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}
          >
            <IonLabel position="stacked" color={errors.confirmPassword?.message ? 'danger' : 'primary'}>
              {t('page_change_password_confirm.label')}
            </IonLabel>
            <InputPassword
              name="confirmPassword"
              errorMessage={errors.confirmPassword?.message ? t(errors.confirmPassword?.message) : ''}
              register={register}
            />
          </IonItem>

          <IonFooter className="lc-footer">
            <IonButton type="submit" expand="block">
              {t('button_title_save')}
            </IonButton>
          </IonFooter>
        </form>
      </IonContent>
    </IonPage>
  );
};
