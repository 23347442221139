import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import FamilyMember, { FamilyMemberProps } from './FamilyMember';
import '../styles.scss';

interface FamilyMemberListProps {
  list: FamilyMemberProps[];
  handleModal({}): void;
}

const FamilyMemberList: React.FC<FamilyMemberListProps> = ({ list, handleModal }) => {
  const { t } = useTranslation();

  return (
    <div className="lc-care-list">
      {list?.map((member: FamilyMemberProps, index: number) => {
        return <FamilyMember key={member.id} {...member} icon={index} />;
      })}

      <IonButton
        onClick={() => {
          handleModal({
            cssClass: 'add-family',
            showBackdrop: true,
            swipeToClose: true,
            backdropDismiss: true,
            keyboardClose: true,
          });
        }}
        fill="clear"
      >
        <IonIcon slot="start" icon={addOutline} />
        {t('care_tab_add_member')}
      </IonButton>
    </div>
  );
};

export default FamilyMemberList;
