/**Public urls */
export const URL_PROFILE_REGISTER = '/register/profile';
export const URL_REGISTER = '/register';
export const URL_TERMS_CONDITIONS = '/terms-conditions';
export const URL_PRIVACY_POLICY = '/privacy-policy';
export const URL_REGISTER_EMAIL = '/register/email';
export const URL_AUTH_PHONE = '/auth/phone';
export const URL_REGISTER_PHONE_VERIFY = '/auth/phone/verify';
export const URL_FORGOT_PASSWORD = '/forgot/password';
export const URL_REFERRAL_ARTICLE_LANDING = '/topic-of-the-month';
export const URL_CLIENT_ONBOARDING = '/onboarding';
export const URL_HEALTH_QUESTIONNAIRE_CONFIRMATION = '/questionnaire-confirmation';
export const URL_MAIN = '/main';
export const URL_LOGIN = URL_MAIN.concat('/login');

/* Unique Links */
export const URL_EXPIRED_LINK = '/expired-link';
export const URL_DOB_VERIFICATION = '/register-with-unique-link';
export const URL_INVALID_UNIQUE_LINK = '/invalid-unique-link';


/*Tab questionnaire */
export const URL_MAIN_HOME_HEALTH_QUESTIONNAIRE = '/health-questionnaire';

/*Tab home */
export const URL_MAIN_HOME = URL_MAIN.concat('/home');

/*Tab care */
export const URL_MAIN_CARE = URL_MAIN.concat('/care');

/*Tab health */
export const URL_MAIN_HEALTH = URL_MAIN.concat('/health');
export const URL_HEALTH_BIOMETRICS = URL_MAIN_HEALTH.concat('/biometrics');
export const URL_MAIN_HEALTH_RECORDS = URL_MAIN_HEALTH.concat('/records');
export const URL_MAIN_HEALTH_BP_CHART = URL_MAIN_HEALTH.concat('/chart');
export const URL_MAIN_HEALTH_APPOINTMENT = URL_MAIN_HEALTH.concat('/appointment');
export const URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION = URL_MAIN_HEALTH_APPOINTMENT.concat('/confirmation');

/* To Do Tab */
export const URL_MAIN_TODO = URL_MAIN.concat('/to-do');

/*Tab impact */
export const URL_MAIN_IMPACT = URL_MAIN.concat('/impact');

/*Tab earnings */
export const URL_MAIN_EARNINGS = URL_MAIN.concat('/earnings');
export const URL_MAIN_EARNINGS_CONFIRMATION = URL_MAIN_EARNINGS.concat('/confirmation');
export const URL_MAIN_EARNINGS_ERROR = URL_MAIN_EARNINGS.concat('/error');
export const URL_PAYPAL_OAUTH_PAGE = URL_MAIN.concat('/paypal/oauth2-callback');

/*Tab rewards */
export const URL_MAIN_REWARDS = URL_MAIN.concat('/rewards');
export const URL_MAIN_REWARDS_PAY_OUT_CONFIRMATION = URL_MAIN_REWARDS.concat('/payout/confirmation/:amount/:to');

/*Tab about-your-health */
export const URL_ABOUT_YOUR_HEALTH = URL_MAIN.concat('/about-your-health');
export const URL_SHARE_TOPIC_OF_THE_MONTH = URL_ABOUT_YOUR_HEALTH.concat('/share-tom');

/*Private urls */
export const URL_CHANGE_PASSWORD = '/change-password';
export const URL_ACCOUNT_SETTINGS = '/account-settings';
/*View only for testing Card Components*/
export const URL_CARD_PAGE = '/pagecard';

/*Error pages */
export const URL_404_NOT_FOUND_PAGE = '/404';
export const URL_500_INTERNAL_ERROR_PAGE = '/500';
export const URL_NO_CONNECTION = '/noconnection';
export const URL_INVALID_CHECK_IN = '/invalid-checkIn';

/*Phone Verification*/
export const URL_MAIN_PHONE_NUMBER_VERIFICATION = URL_MAIN.concat('/phoneVerification');
export const URL_PHONE_VERIFICATION_VERIFY_CODE = URL_MAIN_PHONE_NUMBER_VERIFICATION.concat('/verifyCode');
export const URL_PHONE_VERIFICATION_CONFIRMATION = URL_PHONE_VERIFICATION_VERIFY_CODE.concat('/confirmation');

export const URL_MAIN_CHECK_IN = URL_MAIN.concat('/check-in');

/* REPRESENTATIVE */
export const URL_REPRESENTATIVE_BASE = '/internal';
export const REPRESENTATIVE_URL_LOGIN = URL_REPRESENTATIVE_BASE.concat('/login');
export const REPRESENTATIVE_URL_HOME = URL_REPRESENTATIVE_BASE.concat('/home');
export const REPRESENTATIVE_URL_PROFILE_REGISTER = URL_REPRESENTATIVE_BASE.concat('/hra-questionnaire');
export const REPRESENTATIVE_URL_DONE = URL_REPRESENTATIVE_BASE.concat('/hra-done');
export const REPRESENTATIVE_URL_USERS = URL_REPRESENTATIVE_BASE.concat('/users');
export const REPRESENTATIVE_URL_ORGANIZATIONS = URL_REPRESENTATIVE_BASE.concat('/organizations');
