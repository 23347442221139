/**
 *
 * @param value delete blanck spaces and ( )
 * @returns
 */
export const formatCelPhone = (value: string) => {
  return value.replace(/[''()]/g, '').replace(/\s/g, '');
};

export const formatGoogleCellPhone = (value: string) => value.replace(/^\+[0-9]/, '');

export const addCountryCode = (phone: string, countryCode = '+1') => phone.replace(/^/, countryCode);
