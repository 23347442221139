import { SwiperSlide } from 'swiper/react/swiper-react.js';
import './styles.scss'

export const SliderTwo = () => (

    <SwiperSlide className="slide-layout">
        <img className="lc-spot-with-logo-image" src='assets\img\health-is-wealth-logo.svg' alt="Health is Wealth" />
        <br />
        <div className="slide-two-title-container">
            <h2>Watch our intro video <br /> for more details</h2>
        </div>
        <div className="slide-two-video-container">
            <iframe width="320" height="172" src="https://www.youtube.com/embed/TRe0ep6QqLY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>
        </div>
    </SwiperSlide>
)