import api from './api';

/**
 *
 * @returns list of cards
 */
export const getHomeCards = async () => {
  const response = await api.get('home');
  return response?.data?.data;
};
