import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
/**Icon */
import { logoGoogle } from 'ionicons/icons';

/**Common */
import { SocialNetworkButton } from '../social-network-button';

export const GoogleButton: React.FC = () => {
  /**Constants */
  const googleTitle = 'Google';
  const googleProvider = new firebase.auth.GoogleAuthProvider();

  return (
    <SocialNetworkButton
      title={googleTitle}
      className="register-button"
      icon={logoGoogle}
      provider={googleProvider}
    />
  );
};
