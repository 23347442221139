import { Address } from '@live-chair-health/lch-core';

export const placeToAddress = (place: google.maps.places.PlaceResult) => {
  let address: Address = {};

  if (place.address_components) {
    place.address_components.forEach((c) => {
      switch (c.types[0]) {
        case 'street_number':
          address.number = c.long_name;
          break;
        case 'route':
          address.streetLine1 = c.long_name;
          break;
        case 'subpremise':
          address.streetLine2 = c.long_name;
          break;
        case 'neighborhood':
        case 'locality':
          address.city = c.long_name;
          break;
        case 'administrative_area_level_1':
          address.state = c.long_name;
          break;
        case 'administrative_area_level_2':
            address.county = c.long_name;
            break;
        case 'postal_code':
          address.zip = c.long_name;
          break;
        case 'country':
          address.country = c.long_name;
          break;
      }
    });

    return address;
  }
};
