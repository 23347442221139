import { IonItem, IonText } from '@ionic/react';

import './styles.scss';

interface ISliderHeaderProps {
  description: string;
}

export const SliderHeader = ({ description }: ISliderHeaderProps) => {
  return (
    <IonItem lines="none" className="lc-slider-header" data-public>
      <IonText color="primary">
        <h2>{description}</h2>
      </IonText>
    </IonItem>
  );
};
