import React, { useRef, useState } from 'react';
import { IonPage, IonContent, IonButton, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import ReactCodeInput from 'react-verification-code-input';
import { Spinner } from '../../../components/spinner/spinner';
import { verifyCellphoneNumberVerificationCode } from '../../../common/adapters/user-profile-adapter';
import { URL_PHONE_VERIFICATION_CONFIRMATION } from '../../../common/constants/routers';
import { ProfileState } from '../../../common/store-atom/profile-atom';
import './styles/verifyCode.scss';

interface ViewProps {
  isLoading: boolean;
  createVerificationCode(): void;
}

export const VerifyCodeView: React.FC<ViewProps> = ({ isLoading, createVerificationCode }) => {
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [profileState, setProfileState] = useRecoilState(ProfileState);
  const codeRef: any = useRef(undefined);
  const { t } = useTranslation();
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const defaultValues = ['', '', '', ''];
  const { handleSubmit } = useForm();
  const lastFourDigitsOfCellPhoneNumber = Array.isArray(profileState.phones) ? profileState?.phones[0].slice(-4) : '';

  const mutationPostVerificationCode = useMutation(verifyCellphoneNumberVerificationCode, {
    onSuccess: () => {
      const verifiedProfile = { ...profileState, cellPhoneVerified: true };
      setProfileState(verifiedProfile);

      setIsViewLoading(false);
      codeRef.current.__clearvalues__();
      history.push(URL_PHONE_VERIFICATION_CONFIRMATION);
    },

    onError: (error: any) => {
      setIsViewLoading(false);
      present({
        color: 'danger',
        buttons: [{ text: 'Ok', handler: () => dismiss() }],
        message: error.response.data.message ? error.response.data.message : error.response.data.exceptionMessage,
        duration: 2500,
      });
      codeRef.current.__clearvalues__();
    },
  });

  const handlePostVerifyCode = (data) => {
    mutationPostVerificationCode.mutate({ ...data });
  };

  const onSubmit = (data) => {
    data.code == data;
    setIsViewLoading(true);
    handlePostVerifyCode(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage>
        <IonContent fullscreen ion-text-center>
          <Spinner isOpen={isLoading} />
          <Spinner isOpen={isViewLoading} />
          <div className="title-container-verify-Code ion-text-center">
            <h4>{t('mobile_verification_verify_code_title')}</h4>
          </div>
          <div className="sub-title-container-verify-code">
            <p>
              {t('mobile_verification_verify_code_message')}
              {lastFourDigitsOfCellPhoneNumber}
            </p>
          </div>
        {/* @ts-ignore */}
          <ReactCodeInput
            onComplete={(value: string) => onSubmit(value)}
            fields={4}
            autoFocus={true}
            fieldWidth={71}
            fieldHeight={62}
            values={defaultValues}
            ref={codeRef}
          />
          <div className="buttons-container">
            <div className="block-center-profile-register">
              <div className="lc-profile-register-buttons-container">
                {' '}
                <IonButton type="submit" color="primary" expand="block">
                  {t('mobile_verification_verify_code_verify_now_button')}
                </IonButton>
                <IonButton
                  onClick={() => {
                    createVerificationCode();
                    codeRef.current.__clearvalues__();
                  }}
                  className="lch-edit-number-button"
                  expand="block"
                >
                  {t('mobile_verification_verify_code_resend_code_button')}
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </form>
  );
};
