import { BarberShop } from '../models/barber-shop';
import { ProfileState } from '../store-atom/profile-atom';
import { HairProfessional } from '../models/HairProfessional';
import api, { requestsApi } from './api';

  const setLanguage = () => {
    let userLang = navigator.language
    let isEs = userLang.includes('es') || userLang.includes('ES')
    let spanish = 'es'
    let english = 'en'
    if(isEs === true){
      return spanish
    }
    return english
  }
  const language = setLanguage();

export const getAllBarberShop = async (language) => {
  return await api.get(`shops/${language}`);
};

export const getAllHairProfessional = async (idBarberShopSelected: number, lang?) => {
  return await api.get(`shops/${idBarberShopSelected}/HPs/${lang? lang : language}`);
};

export const getHairProfessionals = (idBarberShopSelected: number, lang?): Promise<HairProfessional[]> => {
  return requestsApi.get(`shops/${idBarberShopSelected}/HPs/${lang? lang : language}`);
};

export const getBarberShops = async (lang?): Promise<BarberShop[]> => {
  return requestsApi.get(`shops/?language=${language}`)
};
