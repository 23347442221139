import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { addPayPalAccount } from "../../../../common/adapters/earnings-adapter";
import { URL_MAIN_EARNINGS_ERROR, URL_MAIN_EARNINGS } from "../../../../common/constants/routers";
import { Spinner } from "../../../../components/spinner/spinner";

export const PaypalPostRequestPage = () => {
    const oAuthCode = new URLSearchParams(window.location.search).get('code');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();

    const onSuccess = () => {
        setIsLoading(false)
        history.push(URL_MAIN_EARNINGS)
    }

    const onError = () => {
        setIsLoading(false);
        history.push(URL_MAIN_EARNINGS_ERROR);
    };

    const onFinally = () => {
        setIsLoading(false);
    };

    const getProfileData = async (oAuthCode) => {
        setIsLoading(true)
        await addPayPalAccount(oAuthCode)
            .then(onSuccess)
            .catch(onError)
            .finally(onFinally);
    };

    useEffect(() => {
        if (oAuthCode) {
            getProfileData(oAuthCode);
        }
    }, [])

    return (
        <>
            <IonPage>
                <IonContent fullscreen>
                    <Spinner isOpen={isLoading} />
                </IonContent>
            </IonPage>
        </>
    )
}