import { IonButton, IonRouterLink, IonItem, IonCard, IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { URL_MAIN_HOME } from '../../../../common/constants/routers';
import { SliderBody } from '../slider-body';

import './styles.scss';

export const SliderConfirmation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="lc-slider-confirmation">
      <SliderBody>
        <div className="lc-slider-wrapper">
          <IonItem color="success ion-text-center">
            <article>
              <IonIcon slot="start" icon={checkmarkCircle} />
              <h4>
                {t('questionnaire_confirm_heading_1')}
                <br />
                {t('questionnaire_confirm_heading_2')}
              </h4>
            </article>
          </IonItem>

          <div className="lc-slider-confirmation-coin">
            <span>$5</span>
          </div>

          <div className="block-center-slider-confirmation">
            <IonCard color="light">
              <h5>
                {t('questionnaire_confirm_reward_part_one')}
                <strong>$5</strong>
                {t('for_word')}
                {t('questionnaire_confirm_reward_part_two')}✊
              </h5>
            </IonCard>
          </div>
        </div>
      </SliderBody>
      <footer>
        <IonRouterLink routerLink={URL_MAIN_HOME}>
          <IonButton expand="block">{t('button_title_back_home')}</IonButton>
        </IonRouterLink>
      </footer>
    </div>
  );
};
