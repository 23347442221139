import React from 'react';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { URL_MAIN_HEALTH_APPOINTMENT } from '../../../../common/constants/routers';
import BlankCard from '../../../../components/blank-card';

const CardInfo: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <BlankCard
        options={{
          showHeaderImage: true,
          title: `🙏🏽 ${t('care_tab_info_card_marker_1')}`,
          description: t('care_tab_info_card_marker_2'),
        }}
      >
        <IonButton
          onClick={() => {
            history.push(URL_MAIN_HEALTH_APPOINTMENT);
          }}
        >
          {t('care_tab_get_appointment')}
        </IonButton>
      </BlankCard>
    </>
  );
};

export default CardInfo;
