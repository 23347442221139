import { User, signInAnonymously, getAuth } from 'firebase/auth';
import { auth } from './firebase';

/**
 * Returns the current User if it exists after Firebase initialization.
 *
 * @remarks
 * This `PromiseLike<T>` return after subscription prevents getting null instead of User object
 * in cases where Firebase instance is still (re)initializing, such as on page refresh (F5)
 *
 * @returns {Promise<User | null>} object represents signed in User
 */
export const getCurrentUser = (): Promise<User | null> => {
  return new Promise<User | null>((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export const getNewToken = async () => {
  const currentUser = await getCurrentUser();
  return await currentUser?.getIdToken(true);
};


export const getAnonymousToken = async () => {
  try {
    const auth = getAuth();
    await signInAnonymously(auth);
    const currentUser = await getCurrentUser();
    return await currentUser?.getIdToken(true);
  } catch (err) {
    // @ts-ignore
    throw new Error(err);
  }
};