import { ReactNode } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import classNames from 'classnames';

interface IItemFormGroup {
  label?: string;
  errorMessage?: string;
  children?: ReactNode;
  helperText?: string;
}
export const ItemFormGroup = ({ label, errorMessage, children, helperText = '' }: IItemFormGroup) => {
  const ionLabelColor = errorMessage ? 'danger' : 'primary';
  const ionItemClass = classNames({ 'lc-form-group lc-form-group-error': errorMessage }, { 'lc-form-group': !errorMessage });

  return (
    <IonItem lines="none" className={ionItemClass}>
      <IonLabel position="stacked" color={ionLabelColor} data-public>
        {label}
      </IonLabel>
      {helperText && (
        <IonLabel position="stacked" color="medium">
          {helperText}
        </IonLabel>
      )}
      {children}
      {errorMessage && <span>{errorMessage}</span>}
    </IonItem>
  );
};
