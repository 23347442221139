import React from 'react';
import {
  IonButton,
  IonContent,
  IonItem,
  IonLoading,
  IonModal,
  IonText,
} from '@ionic/react';
import { DiseaseType } from '../../pages/tabs/home/enums/disease-type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../common/adapters/api';
import { URL_MAIN_HOME } from './../../common/constants/routers';

interface ExternalLinkModalProps {
  externalURL?: string;
  show?: boolean;
  handleOnhideModal: () => void;
  linkTitle?: string;
  diseaseType?: DiseaseType
}
export const ExternalLinkModal = ({
  externalURL,
  show = false,
  handleOnhideModal,
  linkTitle,
  diseaseType
}: ExternalLinkModalProps) => {
  useEffect(() => {
  }, []);

  const { t } = useTranslation();

  const handleNotifyReadArticle = async () => {
    if (diseaseType){
    await api.put(`articles/diseases/${diseaseType}`);
      redirectHome();
  }
  return;
}

  const redirectHome = () => {
    return window.location.href = URL_MAIN_HOME;
  }

  return (
    <>
      <IonContent className="lc-modal">
        <IonModal isOpen={show}>
          <div
            className="lc-modal-wrapper"
            style={{ position: 'relative', overflow: 'scroll', width: '100%', height: '100%' }}
          >
            <IonItem lines="none" className="lc-modal-header">
              <IonText color="primary">
                <h3>{linkTitle! ? linkTitle : t('external_link_modal_default.title')}</h3>
              </IonText>
              <IonButton
                fill="clear"
                onClick={() => {
                  handleOnhideModal();
                  handleNotifyReadArticle();
                }}
              >
                {t('button_title_close').toUpperCase()}
              </IonButton>
            </IonItem>
            <iframe
              style={{
                border: '0px',
                width: '100%',
                height: '100%',
                left: '0px',
                position: 'relative',
                top: '0px',
              }}
              src={externalURL}
            >
              <IonLoading
                isOpen={true}
                backdropDismiss={true}
                duration={2000}
                message={t('spinner.message')}
                showBackdrop={true}
                translucent={true}
              />
            </iframe>
          </div>
        </IonModal>
      </IonContent>
    </>
  );
};
