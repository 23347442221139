import { useEffect } from 'react';
import { IonContent, IonItem, IonPage, IonRouterLink, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Storage } from '@capacitor/storage';

import { UserAccess } from '../../common/hooks/user-access';
import { useRegistrationData } from '../../common/hooks/use-registration-data';
import { URL_LOGIN, URL_TERMS_CONDITIONS } from '../../common/constants/routers';
import { GoogleButton } from '../../components/buttons/google-button';
import { EmailButton } from '../../components/buttons/email-button';
import { AppleButton } from '../../components/buttons/apple-button';
import { PhoneButton } from '../../components/buttons/phone-button';
import lc_logo from '../../assets/img/lc_rebrand_logo.svg';
import lc_footer_asset from '../../assets/img/register_footer_rebrand.png';

import './styles.scss';

export const RegisterPage = () => {
  const { isClient } = UserAccess();
  const { shopId, campaignId } = useRegistrationData(window.location.search);
  const contentClassName = isClient ? 'lc-spot-with-logo-bg lc-register' : 'lc-spot-with-logo-ptns lc-register';
  const { t } = useTranslation();

  const setDefaultRegister = async () => {
    await Storage.set({
      key: 'defaultRegister',
      value: 'true',
    });
  };

  useEffect(() => {
    setDefaultRegister();
  }, [campaignId, shopId]);

  return (
    <IonPage>
      <IonContent fullscreen className={contentClassName}>
        <div className="block-center">
          <div className="lc-login-container ion-justify-content-center">
            <section>
              <div className="ion-text-start">
                <img className="lc-register-logo" alt="live chair logo" src={lc_logo} />
              </div>

              <IonItem lines="none">
                <IonText className="lc-register-headline">{t('register.today_healthier')}</IonText>
              </IonItem>

              <IonItem lines="none">
                <IonText className="ion-text-start">
                  <IonRouterLink className="lc-underline" routerLink={URL_LOGIN}>
                    {t('register.title_already_account')}
                  </IonRouterLink>
                </IonText>
              </IonItem>

              <article>
                <GoogleButton />

                <AppleButton />

                <EmailButton />

                <PhoneButton />
              </article>

              <div className="ion-text-center lc-register-footer-asset">
                <img src={lc_footer_asset} alt="footer image" />
              </div>

              <IonItem lines="none" className="lc-register-terms-link ion-text-center">
                <IonText>
                  {t('register.title_accept_LCH')}{' '}
                  <IonRouterLink color="dark" routerLink={URL_TERMS_CONDITIONS} className="lc-underline">
                    {t('register.title_accept_terms_and_conditions')}
                  </IonRouterLink>
                </IonText>
              </IonItem>
            </section>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
