import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent, useIonViewDidEnter } from '@ionic/react';

import { URL_MAIN_HEALTH } from '../../../../common/constants/routers';
import BlankCard from '../../../../components/blank-card';
import bpInfo from '../../../../assets/img/bp_info.svg';
import TopCard from '../../../../components/top-card';
import './styles.scss';

const BloodPressureChartPage: React.FC = () => {
  const { t } = useTranslation();
  const imgContainer = useRef<HTMLElement>(null!);

  useIonViewDidEnter(() => {
    setTimeout(() => {
      imgContainer.current.scrollLeft += 200;
    }, 1000);

    setTimeout(() => {
      imgContainer.current.scrollLeft -= 200;
    }, 2000);
  }, [imgContainer]);

  return (
    <IonPage>
      <IonContent fullscreen className="lc-full-spot-bg">
        <div className="page-container">
          <div className="content-chart">
            <TopCard message={`💪🏾 ${t('say_hello_t0_healthy')}`} redirectTo={URL_MAIN_HEALTH} />

            <BlankCard
              options={{
                title: t('bp_categories'),
                description: t('chart_adapted_from'),
              }}
            >
              <section ref={imgContainer} className="chart-container">
                <img style={{ maxWidth: '980px' }} src={bpInfo} alt={t('bp_info_alt')} />
              </section>
            </BlankCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BloodPressureChartPage;
