import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import {
  REPRESENTATIVE_URL_DONE,
  REPRESENTATIVE_URL_HOME,
  REPRESENTATIVE_URL_ORGANIZATIONS,
  REPRESENTATIVE_URL_PROFILE_REGISTER,
  REPRESENTATIVE_URL_USERS,
  URL_REPRESENTATIVE_BASE,
} from '../../../common/constants/routers';
import { setNavigationPage } from '../../../common/storage';
import { TabMenuState } from '../../../common/store-atom/tab-menu-atom';
import GuardedRoute from '../../../components/guarded-route';
import { RepresentativeDonePage } from '../../representatives/pages/done';
import { RepresentativeHomePage } from '../../representatives/pages/home';
import { RepresentativeOrganizationsPage } from '../../representatives/pages/organizations';
import { RepresentativeProfileRegisterPage } from '../../representatives/pages/profile-register';
import { RepresentativeUsersPage } from '../../representatives/pages/users';

export const LCTabsRepresentatives = () => {
  const history = useHistory();
  const setTabMenuState = useSetRecoilState(TabMenuState);
  const { t } = useTranslation();

  const handleTabsDidChange = async (event: CustomEvent) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    await setNavigationPage(URL_REPRESENTATIVE_BASE.concat('/').concat(event.detail.tab));
    setTabMenuState(event.detail.tab);
    history.push(`${URL_REPRESENTATIVE_BASE}/${event.detail.tab}`);
  };

  return (
    <>
      <IonTabs>
        <IonRouterOutlet id="main-content">
          <GuardedRoute validTypes={['CSR', 'EXTERNAL_CSR']} exact path={REPRESENTATIVE_URL_HOME} component={RepresentativeHomePage} />
          <GuardedRoute
            validTypes={['CSR', 'EXTERNAL_CSR']}
            exact
            path={REPRESENTATIVE_URL_PROFILE_REGISTER}
            component={RepresentativeProfileRegisterPage}
          />
          <GuardedRoute validTypes={['CSR', 'EXTERNAL_CSR']} exact path={REPRESENTATIVE_URL_DONE} component={RepresentativeDonePage} />
          <GuardedRoute validTypes={['CSR', 'EXTERNAL_CSR']} exact path={REPRESENTATIVE_URL_USERS} component={RepresentativeUsersPage} />
          <GuardedRoute
            validTypes={['CSR']}
            exact
            path={REPRESENTATIVE_URL_ORGANIZATIONS}
            component={RepresentativeOrganizationsPage}
          />
        </IonRouterOutlet>

        <IonTabBar onIonTabsDidChange={handleTabsDidChange} slot="bottom">
          <IonTabButton tab="users" href={REPRESENTATIVE_URL_USERS}>
            <IonIcon src="/assets/icon/menu/icon-users.svg" />
            <IonLabel>{t('menu_tab.title_users')}</IonLabel>
          </IonTabButton>

          <IonTabButton tab="organizations" href={REPRESENTATIVE_URL_ORGANIZATIONS}>
            <IonIcon src="/assets/icon/menu/icon-organizations.svg" />
            <IonLabel>{t('menu_tab.title_organizations')}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};