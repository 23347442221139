import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAvatarProvider } from '../../../../common/hooks/use-avatar-provider';
import { IFamilyMember } from '../../../../common/models/households';
import { formatDate } from '../../../../common/utils/date';
import '../styles.scss';

export interface FamilyMemberProps extends IFamilyMember {
  icon?: any;
}

const FamilyMember: React.FC<FamilyMemberProps> = ({ icon, firstName, lastName, dob, relationship }) => {
  const { t } = useTranslation();
  const { getAvatar } = useAvatarProvider();
  return (
    <section className="lc-care-family-item-container">
      <img src={getAvatar(icon)} />

      <section className="lc-care-family-item-data">
        <span>
          <strong>{t('care_tab_appointment_name')}: </strong>
          {`${firstName} ${lastName}`}
        </span>
        <span>
          <strong>{t('page_setting_account.label_date_of_birth')}:</strong> {dob ? formatDate(new Date(dob)) : ''}
        </span>
        <span>
          <strong>{t('care_tab_relationship')}: </strong> {relationship}
        </span>
      </section>
    </section>
  );
};

export default FamilyMember;
