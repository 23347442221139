import { IonProgressBar } from '@ionic/react';
import { useRecoilState } from 'recoil';
import { ProgressBarState } from '../../../../common/store-atom/progress-bar-atom';

export const Steps = () => {
  const [progressBarState, setProgressBarState] = useRecoilState(ProgressBarState);

  return (
    <div>
      <IonProgressBar value={progressBarState}></IonProgressBar>
    </div>
  );
};
