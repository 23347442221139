import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Storage } from '@capacitor/storage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { format} from 'date-fns'
import 'firebase/auth';
import { Patient as Profile, Address } from '@live-chair-health/lch-core';

import { UserAccess } from '../../../common/hooks/user-access';
import { User } from '../../../common/models/user';
import { schemaProfileRegister } from '../utils';
import { ProfileRegisterView } from './ProfileRegisterView';
import { comesFromUniqueLinkState, phoneNumberState, registrationStateAtom, uniqueLinkIdState, urlTypeSelector } from '../../../common/store-atom/register-atom';
import { ProfileState } from '../../../common/store-atom/profile-atom';
import { auth } from '../../../common/firebase/firebase';
import { MessageToastHooks } from '../../../common/hooks/message-toast';
import {
  getPatient,
  savePatient,
  updateLastLoginTimeStamp,
  updatePatient
} from '../../../common/adapters/user-profile-adapter';
import { formatCelPhone } from '../../../common/utils/cel-phone';
import { URL_MAIN, URL_PROFILE_REGISTER } from '../../../common/constants/routers';
import { FlagsContext } from '../../../common/firebase/flags-providers';
import { UserState } from '../../../common/store-atom/user-atom';

export const ProfileRegisterContainer = () => {
  const currentURL = window.location.search;
  const history = useHistory();
  const { t } = useTranslation();
  const { messageToastError } = MessageToastHooks();
  const { isClient } = UserAccess();
  const flags = useContext(FlagsContext);

  const [isDefaultRegister, setDefaultRegister] = useState(true);
  const [referralCode, setReferralCode] = useState('');

  const phoneNumber = useRecoilValue(phoneNumberState);
  const userState = useRecoilValue(UserState);

  const { comesFromUniqueLink } = useRecoilValue(comesFromUniqueLinkState);
  const { campaignId, shopId, questionnaireId } = useRecoilValue(registrationStateAtom);
  const userSelector = useRecoilValue(ProfileState);
  const { isFromShop } = useRecoilValue(urlTypeSelector);
  const setUser = useSetRecoilState(ProfileState);
  const { uniqueLinkId } = useRecoilValue(uniqueLinkIdState);

  const schema = schemaProfileRegister({
    isClient, 
    isZipCode: flags?.isAddressAZipCode || false, 
    isPhoneAuth: !!phoneNumber.phone,
    comesFromUniqueLink,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm<Profile>({
    resolver: yupResolver(schema),
  });

  const getLanguageData = () => {
    let userLang = navigator.language;
    let isEs = userLang.includes('es') || userLang.includes('ES');
    let spanish = 'es';
    let english = 'en';
    if (isEs) {
      setValue('app_language', spanish);
      return spanish;
    }
    setValue('app_language', english);
    return english;
  };

  const checkDefaultRegisterVariable = async () => {
    const { value } = await Storage.get({ key: 'defaultRegister' });
    if (value) return;

    await Storage.set({
      key: 'defaultRegister',
      value: 'true',
    });
  };

  const checkIsDefaultRegister = async () => {
    if (shopId !== 'not_from_shop' || campaignId !== 'not_from_campaign') {
      setDefaultRegister(false);
    }
  };

  const checkReferralCode = async () => {
    const { value } = await Storage.get({ key: 'referralCode' });

    setReferralCode(value || '');
  };

  const getEmail = (fireBaseDataEmail) => {
    // @ts-ignore
    setValue('email', fireBaseDataEmail.currentUser?.email);
  };

  const setFormDefaultValues = () => {
    if (comesFromUniqueLink) {
      const { first_name, last_name, emails } = userState;
      setValue('first_name', first_name || '');
      setValue('last_name', last_name || '');
      // @ts-ignore
      setValue('email', Array.isArray(emails) && emails.length ? emails[0] : '');
    } else {
      const { first_name, last_name } = getValues();
      if (userSelector && !first_name && !last_name) {
        setValue('first_name', '');
        setValue('last_name', '');
      }
    }
  };

  const addCurrentUserIntoStorage = (data: Profile) => {
    setUser({ ...data, user_type: userSelector.user_type });
  };

  const setErrorFields = (error: any) => {
    if (error) {
      error.forEach((fieldName) =>
        setError(fieldName, { type: 'manual', message: t('page_profile_register.field_validation_error.' + fieldName) })
      );
    }
  };

  const redirectToHome = async () => {
    const result = await getPatient();

    addCurrentUserIntoStorage(result.data.data);
    history.push(URL_MAIN);
  };

  const cleanLocalStorage = async () => {
    const { value } = await Storage.get({ key: 'urlType' });
    switch (value) {
      case 'campaign':
        await Storage.remove({ key: 'campaignId' });
        await Storage.remove({ key: 'isFromCampaign' });
        await Storage.remove({ key: 'urlType' });
        break;

      case 'shop':
        await Storage.remove({ key: 'shopId' });
        await Storage.remove({ key: 'isFromShop' });
        await Storage.remove({ key: 'urlType' });
    }
  };

  const onSuccess = async () => {
    await updateLastLoginTimeStamp();
    cleanLocalStorage();
    history.push(URL_MAIN);
  };

  const onError = (error: AxiosError) => {
    switch (error.response?.status) {
      case 500:
        history.push(URL_PROFILE_REGISTER);
        break;
      case 409:
        redirectToHome();
        break;

      case 410:
        // @ts-ignore
        setError('phone', { type: 'manual', message: t('page_profile_register.field_validation_error.duplicatedCellPhone') });
        break;

      case 600:
        setErrorFields(error.response.data.messages);
        console.error(error);
        break;

      case 604:
        break;

      default:
        messageToastError(error);
    }
  };

  const snakeToPascal = (word: string) =>
    word
      .split('/')
      .map((snake: string) =>
        snake
          .split('_')
          .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
          .join('')
      )
      .join('/');

  const onSubmit = async (data: User, parsedAddress?: Address) => {
    if (phoneNumber.phone) {
      Object.assign(data, { phones: [phoneNumber.phone] });
    }

    const userPhone = Array.isArray(data.phones) ? data.phones : [formatCelPhone(data.phones || '')];
    const userMails = data.email || userState.email || '';

    delete data.termsAndConditions;

    if (comesFromUniqueLink) {
      const obj = {
        first_name: data.first_name,
        last_name: data.last_name,
        emails: [data.email],
        questionnaire_id: snakeToPascal(questionnaireId || ''),
        comes_from_unique_link: true,
        unique_link_id: uniqueLinkId,
        partners_ids: userSelector.partners_ids || [],
      }
      mutationUpdatePatient.mutate(obj);
    } else {
      delete data.email;

      const obj = {
        ...data,
        isClient: true,
        created_at: new Date().getTime(),
        phones: userPhone,
        emails: [userMails],
        app_language: getLanguageData(),
        ...(!flags?.isAddressAZipCode && {
          address: parsedAddress,
        }),
        questionnaire_id: snakeToPascal(questionnaireId || ''),
        validated: false,
        origin: 'app',
        partners_ids: ['app'],
        date_of_birth: data.date_of_birth ? format(new Date(data.date_of_birth), 'yyyy-MM-dd') : 'Undisclosed'
      };
      mutationSavePatient.mutate(obj);
    }
  };

  const mutationSavePatient = useMutation(savePatient, {
    onSuccess,
    onError,
  });

  const mutationUpdatePatient = useMutation(updatePatient, {
    onSuccess,
    onError,
  });

  useEffect(() => {
    checkDefaultRegisterVariable();
    checkIsDefaultRegister();
    getLanguageData();
    getEmail(auth);
    checkReferralCode();
  }, [currentURL]);

  useEffect(() => {
    setFormDefaultValues();
  }, []);

  return (
    <ProfileRegisterView
      t={t}
      errors={errors}
      register={register}
      isClient={isClient}
      isDefaultRegister={isDefaultRegister}
      isLoading={mutationSavePatient.isLoading}
      handleSubmit={handleSubmit}
      isFromShop={isFromShop}
      onSubmit={onSubmit}
      comesFromUniqueLink={comesFromUniqueLink}
    />
  );
};
