import React from 'react';
import { useQuery } from 'react-query';
import { IonContent, IonPage } from '@ionic/react';

import { getHomeCards } from '../../../common/adapters/home-adapter';
import { GenericCard } from '../home/components/generic-card';
import { TakeNextStepCard } from '../home/components/take-next-step-card';
import { TypeCard } from '../home/enums/type-cards';
import { ICardProps } from '../home/interfaces/ICardProps';
import { Spinner } from '../../../components/spinner/spinner';
import './styles.scss';

const ToDoPage: React.FC = () => {
  const { data, isFetching } = useQuery('homeCards', getHomeCards, { enabled: true });

  return (
    <IonPage>
      <Spinner isOpen={isFetching} />
      <IonContent className="lc-full-spot-bg">
        {!isFetching && (
          <div className="lc-journey-container">
            {data &&
            data?.healthJourney &&
              data?.healthJourney.map((result: ICardProps, index: number) => {
                switch (result.type) {
                  case TypeCard.GENERIC:
                    return <GenericCard {...result} key={result.id} />;
                  case TypeCard.REWARD:
                    return;

                  case TypeCard.STEP:
                    Object.defineProperty(result, 'language', {
                      enumerable: true,
                      configurable: true,
                      writable: true,
                      value: {},
                    });
                    return <TakeNextStepCard {...result} key={result.id} />;
                  default:
                    return <></>;
                }
              })}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ToDoPage;
