import { useEffect } from "react";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { NextJourneyStepRewardBadge } from "./NextJourneyStepRewardBadge";
import '../../styles/next-journey-step-card.scss'

export const CheckInNextJourneyStepCard =
    ({ title,
        detail,
        reward_amount,
        type,
        next_card_title
    }) => {

        const { t } = useTranslation();

        return (
            <>
                <IonCard className="lch-check-in-card">
                    <div className="reward-badge-container">
                        <NextJourneyStepRewardBadge rewardAmount={reward_amount} />
                    </div>
                    <IonCardHeader>
                        <IonCardTitle className='lch-ion-card-title-check-in' color="primary"> <h4>{title}</h4></IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent className="lch-ion-card-content-check-in">
                        {detail}
                    </IonCardContent>

                    <div className="next-activity-section">
                        <IonCardSubtitle><strong>{t('check-in-next-journey-step-next-activity-section-title')}</strong></IonCardSubtitle>
                        <p>{next_card_title}</p>

                    </div>
                </IonCard>
            </>
        )
    }