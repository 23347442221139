import { IFamilyMember, IHousehold } from '../models/households';
import api from './api';

export const fetchFamily = async (householdId: string): Promise<IHousehold> => {
  return await api.get(`/households/${householdId}`);
};

export const addFamilyMember = async (householdId: string, familyMember: Partial<IFamilyMember>): Promise<string> => {
  return await api.put(`/households/${householdId}/members`, familyMember);
};
