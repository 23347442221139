import api from './unique-links-api-interceptor';
import { Patient as Profile } from '@live-chair-health/lch-core';

export const verifyBirthYear = async ({ randomString, birthYear }: {
  randomString: string;
  birthYear: string;
}): Promise<{
  data: {
    data: {
    isValid: boolean;
    patientData?: Profile;
    uniqueLinkId?: string;
  }}
}> => {
  return await api.get(`/profile/verify-birth-year/${randomString}/${birthYear}`);
};

export const verifyUniqueLinkExpirationDate = async ({ randomString }: {
  randomString: string;
}): Promise<{
  data: {
    data: {
      isValid: boolean;
      hasAccountBeenCreated?: boolean;
      phoneNumber?: string;
      firstName?: string;
      patientId?: string;
    }
  }
}> => {
  return await api.get(`/profile/verify-unique-link-expiration/${randomString}`);
};

export const sendUniqueLink = async (patientId: {
  patientId: string;
}): Promise<{
  data: {
    data: boolean;
  }
}> => {
  return await api.post(`/profile/send-unique-link`, { patientId });
};
