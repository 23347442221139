import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { getPatient } from '../adapters/user-profile-adapter';
import { User } from '../models/user';

const { persistAtom } = recoilPersist({
  key: 'user-persist',
  storage: localStorage,
});

const INIT_VALUE: User = {
  uid: null,
  email: null,
  fullName: null,
  urlPicture: null,
  id: null,
  barberId: null,
  barber_shop: 0,
  date_of_birth: null,
  phones: [],
  first_name: null,
  isClient: null,
  last_name: null,
  zipCode: null,
  createdAt: null,
  authType: null,
  language: null,
  smsNotifications: null,
  validated: null,
};

export const UserState = atom<User>({
  key: 'userState',
  default: INIT_VALUE,
  effects_UNSTABLE: [persistAtom],
});

export const loggedUserState = selector({
  key: 'loggedUserState',
  get: ({ get }) => {
    const user = get(UserState);
    return user;
  },
});

export const userNameSelector = selector({
  key: 'userNameSelector',
  get: async ({ get }) => {
    const response = await getPatient();
    return response.data.data.firstName;
  },
});

export const userEmailSelector = selector({
  key: 'userEmailSelector',
  get: async ({ get }) => {
    const response = await getPatient();
    return response.data.data.language;
  },
});
