import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { getImpacts } from '../../../common/adapters/impact-adapter';
import { TabMenuState } from '../../../common/store-atom/tab-menu-atom';
import { Spinner } from '../../../components/spinner/spinner';

import './styles.scss';

export const TabImpactPage = () => {
  const tabMenuState = useRecoilValue(TabMenuState);
  const query = useQuery('impactCard', getImpacts);
  const { t } = useTranslation();

  useEffect(() => {
    if (tabMenuState === 'impact') {
      query.refetch();
    }
  }, [tabMenuState]);

  return (
    <IonPage>
      <IonContent fullscreen className="lc-impact lc-full-spot-bg">
        <Spinner isOpen={query.isLoading} />

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText color="primary" className="ion-text-center">
                <h5 className="lc-bold">{t('impact_detail')}</h5>
              </IonText>
            </IonCol>
          </IonRow>
          <div className="impact-card-container">
            <IonRow>
              <IonCol className="ion-align-self-center">
                <IonCard>
                  <IonCardContent>
                    <IonText color="primary" className="ion-text-center">
                      <h3>{query.data?.data.data.monthImpact}</h3>
                      <p>{t('impact_detail_month')}</p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol className="ion-align-self-center">
                <IonCard>
                  <IonCardContent>
                    <IonText color="primary" className="ion-text-center">
                      <h3>{query.data?.data.data.hpTotal}</h3>
                      <p>{t('impact_detail_total')}</p>
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </div>

          <IonRow>
            <IonCol className="ion-align-self-center">
              <div className="block-center-impact">
                <div className="lc-impact-buttons-container">
                  <IonCard className="lc-impact-circles">
                    <IonCardContent>
                      <div className="lc-impact-circles-graph">
                        <div className="lc-impact-circles-graph2">
                          <div className="lc-impact-circles-graph3">
                            <IonText className="ion-text-center">
                              <p>{t('impact_you')}</p>
                              <h4>{query.data?.data.data.hpTotal}</h4>
                            </IonText>
                          </div>

                          <IonText className="ion-text-center">
                            <p>{t('impact_org')}</p>
                            <h4>{query.data?.data.data.shopTotal}</h4>
                          </IonText>
                        </div>

                        <IonText className="ion-text-center">
                          <p>{t('impact_community')}</p>
                          <h4>{query.data?.data.data.community}</h4>
                        </IonText>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
