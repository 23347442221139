import { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { Address } from '@live-chair-health/lch-core';

import { HRARegisterState } from '../../../../../common/store-atom/hra-register-atom';
import { ProfileRegisterView } from '../../../../profile-register/components/ProfileRegisterView';
import { schemaProfileRegister } from '../../../../profile-register/utils';
import { HRARegister } from '../../../../../common/models/hra-register';
import { URL_MAIN_HOME_HEALTH_QUESTIONNAIRE } from '../../../../../common/constants/routers';
import { FlagsContext } from '../../../../../common/firebase/flags-providers';

export const RepresentativeProfileRegisterContainer = () => {
  const history = useHistory();
  const flags = useContext(FlagsContext);
  const [registerState, setRegisterState] = useRecoilState(HRARegisterState);
  const { t, i18n } = useTranslation();
  const schema = schemaProfileRegister({
    isClient: false,
    isZipCode: false,
    isPhoneAuth: false,
    isRepresentativeHRA: true,
    comesFromUniqueLink: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<HRARegister>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    i18n.changeLanguage(registerState.language);
  }, [registerState.language]);

  const onSubmit = (data: HRARegister, parsedAddress?: Address) => {
    delete data.address;
    setRegisterState({
      ...data,
      ...(!flags?.isAddressAZipCode && {
        address: parsedAddress,
      }),
      language: registerState.language,
    });

    history.push(URL_MAIN_HOME_HEALTH_QUESTIONNAIRE, {
      isRepresentativeHRA: true,
    });
  };

  return (
    <ProfileRegisterView
      t={t}
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      goBack={history.goBack}
      isRepresentativeHRA
    />
  );
};
