import { useIonToast } from '@ionic/react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { URL_500_INTERNAL_ERROR_PAGE } from './../../constants/routers';
import { useTranslation } from 'react-i18next';

export const MessageToastHooks = () => {
  const [present, dismiss] = useIonToast();
  const history = useHistory();
  const { t } = useTranslation()

  const messageToastErrorCustom = (
    header: string,
    message: string,
    titleButton?: string,
    handlerOnCallback?: () => void
  ) => {
    present({
      color: 'light',
      header,
      message,
      position: 'middle',
      buttons: [{ text: titleButton || 'Ok', handler: handlerOnCallback }],
    });
  };

  const messageToastError = (error?: AxiosError | null, customError?: string) => {
    if ((error && error.response?.status === 500) || error?.response?.status === 501) {
      history.push(URL_500_INTERNAL_ERROR_PAGE);
    }
    present({
      color: 'danger',
      position: 'top',
      buttons: [{ text: 'Ok', handler: () => dismiss() }],
      message: error ? error.message : customError,
    });
  };

  const messageToastSuccess = (
    color?: string,
    messageSuccess?: string,
    position?: 'top' | 'bottom' | 'middle',
    duration?: number,
    cssClass?: string
  ) => {
    present({
      color: color || 'success',
      message: messageSuccess || t('changes_saved_confirmation.message'),
      duration: duration || 800,
      position: position || 'top',
      cssClass: `ion-text-center lc-toast-top ${cssClass}`,
    });
  };

  return { messageToastError, messageToastSuccess, messageToastErrorCustom };
};
