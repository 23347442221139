import { clsx } from 'clsx';
import '../styles.scss';

interface CellProps {
  className?: string;
  disabled?: boolean;
  clickable?: boolean;
  info?: boolean;
  onClick?: () => void;
}

const Cell: React.FC<CellProps> = ({ onClick, children, className, clickable = false, disabled = false }) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={clsx(className, 'calendar-cell', clickable && 'calendar-cell-clickable', disabled && 'calendar-cell-disabled')}
    >
      {children}
    </div>
  );
};

export default Cell;
