import avatar1 from '../../assets/family-members/avatars/avatar1.svg';
import avatar2 from '../../assets/family-members/avatars/avatar2.svg';
import avatar3 from '../../assets/family-members/avatars/avatar3.svg';
import avatar4 from '../../assets/family-members/avatars/avatar4.svg';

const avatarList = {
  0: avatar1,
  1: avatar2,
  2: avatar3,
  3: avatar4,
};

export const useAvatarProvider = () => {
  const getAvatar = (indexValue: number) => {
    const totalAvatars = Object.keys(avatarList).length;
    return avatarList[indexValue % totalAvatars];
  };

  return { getAvatar };
};
