/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonButton, IonContent, IonItem, IonPage, IonRouterLink, IonText, useIonToast } from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import {
  URL_FORGOT_PASSWORD,
  URL_MAIN_HOME,
  URL_NO_CONNECTION,
  URL_PROFILE_REGISTER,
  URL_REGISTER,
} from '../../common/constants/routers';
import { logInWithEmailAndPassword, signOut } from '../../common/firebase/sign-in';
import { getPatient } from '../../common/adapters/user-profile-adapter';
import { MessageToastHooks } from '../../common/hooks/message-toast';
import { UserState } from '../../common/store-atom/user-atom';
import { UserAccess } from '../../common/hooks/user-access';
import { deleteToken } from '../../common/storage';
import { User } from '../../common/models/user';
import { getNewToken } from '../../common/firebase/session-management';

import { ItemFormGroup } from '../../components/item-form-group/item-form-group';
import { GoogleButton } from '../../components/buttons/google-button';
import { AppleButton } from '../../components/buttons/apple-button';
import { PhoneButton } from '../../components/buttons/phone-button';
import { EmailPassword } from '../../common/models/email-password';
import { InputText } from '../../components/inputs/input-text';

import { Spinner } from '../../components/spinner/spinner';
import { AuthType } from '../../common/enums/auth-type';
import { LANGUAGE_ENGLISH, LANGUAGE_SPANISH } from '../../common/constants/language';
import { Network } from '@capacitor/network';

import { isEmailOrPasswordInvalid } from '../../common/exceptions/handle-error';
import lc_logo from '../../assets/img/lc_rebrand_logo.svg';
import lc_footer_asset from '../../assets/img/register_footer_rebrand.png';
import './styles.scss';
import '../register/styles.scss';
import { comesFromUniqueLinkState } from '../../common/store-atom/register-atom';

export const LoginPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [present, dismiss] = useIonToast();
  const { messageToastError } = MessageToastHooks();
  const setUser = useSetRecoilState(UserState);
  const { isClient, isPartner } = UserAccess();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { comesFromUniqueLink } = useRecoilValue(comesFromUniqueLinkState);

  const schema = yup.object().shape({
    email: yup.string().required(t('message_field_required')).email(t('message_invalid_email')),
    password: yup.string().required(t('message_field_required')).min(8, t('message_length_password')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailPassword>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (comesFromUniqueLink) {
      present({
        color: 'warning',
        buttons: [{ text: 'Ok', handler: () => dismiss() }],
        message: t('uniqueLink.account_already_created'),
      });
    }
  }, [comesFromUniqueLink]);

  const onError = (error: AxiosError) => {
    setIsLoading(false);
    if (error.response?.status === 604) {
      history.push(URL_PROFILE_REGISTER);
    }
  };

  const logOut = async () => {
    setIsLoading(false);
    await signOut();
    await deleteToken();
    history.push(URL_MAIN_HOME);
  };

  const setUserRecoilState = (profile: any, userType: AuthType) => {
    const patient: User = {
      ...profile,
      fullName: null,
      uid: null,
      urlPicture: null,
      authType: userType,
    };
    setUser(patient);
  };

  const onSuccess = async (response: any) => {
    return response.data?.data;
  };

  const onFinally = () => {
    setIsLoading(false);
  };
  const validatePatient = async (user) => {
    setIsLoading(true);
    const profile = await getPatient()
      .then(onSuccess)
      .catch((error: AxiosError) => {
        return onError(error);
      })
      .finally(onFinally);

    getPatient().then(async (result) => {
      setIsLoading(false);
      if (
        profile &&
        ((isClient && result.data.data.user_type === AuthType.emailAndPassword) ||
          (isPartner && result.data.data.user_type === AuthType.socialNetwork))
      ) {
        setUserRecoilState(profile, user.authType || 0);
        if (profile.app_language === LANGUAGE_ENGLISH || profile.app_language === LANGUAGE_SPANISH) {
          i18n.changeLanguage(profile.app_language);
        } else {
          i18n.changeLanguage(LANGUAGE_ENGLISH);
        }
        history.push(URL_MAIN_HOME);
      } else {
        setIsLoading(false);
        await signOut();
        await deleteToken();
        present({
          color: 'danger',
          buttons: [{ text: 'Ok', handler: () => dismiss() }],
          message: 'Oops, looks like the account you are trying to use is already connected to another LCH program.',
        });
      }
    });
  };

  const errorCallback = (error: any) => {
    setIsLoading(false);
    if (isEmailOrPasswordInvalid(error)) {
      present({
        color: 'danger',
        position: 'top',
        buttons: [{ text: 'Ok', handler: () => dismiss() }],
        message: t('auth-incorrect-credentials'),
      });
      return;
    }
    messageToastError(error);
  };

  const onSubmit = async (data: EmailPassword) => {
    setIsLoading(true);
    const { email, password } = data;
    const stats = await Network.getStatus();
    if (stats.connected === false) {
      return history.push(URL_NO_CONNECTION);
    }
    await logInWithEmailAndPassword(email, password, validatePatient, errorCallback);
  };

  const contentClassName = isClient ? 'lc-spot-with-logo-bg' : 'lc-spot-with-logo-ptns';

  useEffect(() => {
    if (!comesFromUniqueLink) {
      getNewToken().then((token) => {
        if (token) {
          history.replace(URL_MAIN_HOME);
        }
      });
    }
  }, []);

  return (
    <>
      <IonPage>
        <IonContent fullscreen className={contentClassName}>
          <div className="block-center">
            <div className="lc-login-container ion-justify-content-center">
              <Spinner isOpen={isLoading} />

              <div className="ion-text-start">
                <img className="lc-register-logo" alt="live chair logo" src={lc_logo} />
              </div>

              <IonItem lines="none">
                <IonText className="lc-register-headline">{t('login.healthier_life')}</IonText>
              </IonItem>

              <IonItem lines="none">
                <IonText className="ion-text-start">
                  <IonRouterLink className="lc-underline" routerLink={URL_REGISTER}>
                    {t('register.need_account')}
                  </IonRouterLink>
                </IonText>
              </IonItem>

              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <ItemFormGroup label={t('login.label_email')} errorMessage={errors.email?.message}>
                  <InputText name="email" type="email" register={register} />
                </ItemFormGroup>

                <ItemFormGroup label={t('login.label_password')} errorMessage={errors.password?.message}>
                  <InputText name="password" type="password" register={register} />
                </ItemFormGroup>

                <IonItem lines="none">
                  <IonText className="ion-text-start">
                    <IonRouterLink className="lc-underline lc-register-terms-link" routerLink={URL_FORGOT_PASSWORD}>
                      {t('login.title_forgot_password')}
                    </IonRouterLink>
                  </IonText>
                </IonItem>

                <IonButton className="login-btn" expand="block" type="submit">
                  {t('login.button_title')}
                </IonButton>
              </form>

              <section className="lc-login-with">
                <div className="ion-text-start">
                  <span>{t('login.title_login_with')}</span>
                </div>
                <hr />
              </section>

              <article>
                <GoogleButton />
                <AppleButton />
                <PhoneButton />
              </article>

              <div className="ion-text-center lc-register-footer-asset">
                <img src={lc_footer_asset} alt="footer image" />
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
