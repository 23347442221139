import { Address } from '@live-chair-health/lch-core';

export const addressToString = (address: Address): string => {
  let addressStr = '';
  const addressKeys = ['number', 'streetLine1', 'streetLine2', 'city', 'state', 'zip', 'country'];

  addressKeys.forEach((key) => {
    if (address[key]) {
      addressStr += addressStr.length ? `, ${address[key]}` : address[key];
    }
  });

  return addressStr;
};
