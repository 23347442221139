import i18next from 'i18next';
import * as yup from 'yup';

import { checkUniqueEmail } from '../../../common/adapters/questionnaire-adapter';
import { validateZipCode } from '../../../common/adapters/zip-code';
import { REQUIRED_MESSAGE } from '../../../common/constants/validation-message-error';
import { OptionsModel } from '../../../common/models/option-model';
import { isEmpty } from '../../../common/utils/string-empty';

export const validateHairProfessional = (isClient: boolean, value: string | undefined) => {
  return !isClient || !isEmpty(value);
};

export const schemaProfileRegister = (
  {
    isClient,
    isZipCode,
    isPhoneAuth = false, 
    isRepresentativeHRA,
    comesFromUniqueLink,
  }: {
    isClient?: boolean;
    isZipCode?: boolean;
    isPhoneAuth?: boolean;
    isRepresentativeHRA?: boolean;
    comesFromUniqueLink?: boolean;
  }
) => {
  const now = new Date();
  const eighteenYearsAgo = now.setFullYear(now.getFullYear() - 18);

  const yupSchema = yup.object().shape({
    first_name: yup.string().required(i18next.t('message_field_required')),
    last_name: yup.string().required(i18next.t('message_field_required')),
    phones: yup.string(),
    email: isPhoneAuth
      ? yup
          .string()
          .nullable()
          .notRequired()
          .email(i18next.t('message_invalid_email'))
          .test('email_async_validation', async (value, { createError }) => {
            if (!isRepresentativeHRA || !value?.length) {
              return true;
            }
            return checkUniqueEmail(value || '')
              .then((res) => {
                const message = res;
                return message?.data?.data
                  ? true
                  : createError({
                      message: i18next.t('page_profile_register.field_validation_error.duplicatedEmail'),
                    });
              })
              .catch(() => {
                return false;
              });
          })
      : yup.string(),
    ...(!isRepresentativeHRA && { barber_shop: yup.number() }),
    ...(!isRepresentativeHRA && { barberId: yup.string() }),
    ...(isZipCode && {
      zipCode: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .test('isZipCode', i18next.t('message_valid_us_zip_code'), async (value) => validateZipCode(value)),
    }),
    ...(!isZipCode && !comesFromUniqueLink && {
      address: yup.string().required(i18next.t('message_field_required')),
    }),
    ...(!comesFromUniqueLink && {
      date_of_birth: yup
        .date()
        .required(i18next.t('message_field_required'))
        .max(new Date(eighteenYearsAgo), i18next.t('message_18_years_old')),
    }),
    app_language: yup.string(),
    ...(isRepresentativeHRA && { registrationId: yup.string() }),
    ...(!isRepresentativeHRA && {
      termsAndConditions: yup
        .boolean()
        .required(i18next.t('message_field_required'))
        .oneOf([true], i18next.t('message_field_required')),
    }),
  });

  return yupSchema;
};

export const formatBarberShop = (data: any[], isClient: boolean): OptionsModel[] => {
  const optionsModel: OptionsModel[] = [];

  data.forEach((shop: any, index: number) => {
    const newOptionModel: OptionsModel = {
      value: shop.id,
      text: index === 0 && isClient ? i18next.t('independent_hair_professional') : shop.name,
    };
    optionsModel.push(newOptionModel);
  });

  return optionsModel;
};

export const formatHairProfessional = (data: any[]): OptionsModel[] => {
  const optionsModel: OptionsModel[] = [];

  data.forEach((professional: any) => {
    const newOptionModel: OptionsModel = {
      value: professional.id,
      text: `${professional.firstName} ${professional.lastName}`,
    };
    optionsModel.push(newOptionModel);
  });

  return optionsModel;
};

interface LCHRepresentative {
  id: string;
  firstName: string;
  lastName?: string | null;
}

export const formatLchRepresentative = (reps: LCHRepresentative[]): OptionsModel[] =>
  reps.map(({ id, firstName, lastName }) => ({
    value: id,
    text: `${firstName}${lastName ? ` ${lastName}` : ''}`,
  }));

export const setTitleSelectBarberShop = (isClient: boolean) => {
  return isClient ? i18next.t('page_setting_account.label_shop') : i18next.t('page_setting_account.label_shop_I_work_for');
};
