import React, { useState, useEffect } from 'react';
import { IonItem, IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import './styles.scss';

export const SharePopover = ({ onHide, event, article, profile, isShareable, isOpen, setIsOpen }) => {
  var TinyURL = require('tinyurl');
  const { t } = useTranslation();

  const currentURL = 'https://dev-clients.livechair.co/topic-of-the-month';
  const [hashedLink, setHashedLink] = useState('');
  const articleId = article.id;
  const id = profile.id;
  const unHashedShareLink = `${currentURL}/?profileId=${id}&articleId=${articleId}`;
  //https://dev-clients.livechair.co/register
  //https://staging-clients.livechair.co/register

  const hashShareLink = async (url) => {
    await TinyURL.shorten(url).then((res: string) => {
      setHashedLink(res);
    });
  };

  const copyLink = async () => {
    copy(hashedLink);
    onHide();
  };

  useEffect(() => {
    hashShareLink(unHashedShareLink);
  });
  return (
    <>
      <IonList>
        <IonItem className="popOverItem" button onClick={copyLink}>
          {t('button_title_share_copy_to_clipboard')}
        </IonItem>
      </IonList>
    </>
  );
};
