import { IonButton } from '@ionic/react';
import styled from 'styled-components';

/**
 * customColor property define button's color
 */
export const LCButton = styled(IonButton)<{ customColor?: string }>`
  &::part(native) {
    background: ${(props) => (props.customColor ? props.customColor : '#230870')};
  }
`;
