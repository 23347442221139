import { useTranslation } from 'react-i18next';
import '../../styles/next-journey-step-reward-badge.scss'

export const NextJourneyStepRewardBadge = ({ rewardAmount }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='reward-badge'>
                <p><svg width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.49212 0.293945C4.12212 0.293945 0.583374 3.84061 0.583374 8.21061C0.583374 12.5806 4.12212 16.1273 
8.49212 16.1273C12.87 16.1273 16.4167 12.5806 16.4167 8.21061C16.4167 3.84061 12.87 
0.293945 8.49212 0.293945ZM11.0492 12.4777L8.50004 10.9419L5.95087 12.4777C5.65004 12.6598 5.27796 
12.3906 5.35712 12.0502L6.03004 9.1527L3.78962 7.21311C3.52837 6.98353 3.67087 6.54811 4.01921 6.51645L6.98004 
6.26311L8.13587 3.53186C8.27046 3.20728 8.72962 3.20728 8.86421 3.53186L10.02 6.2552L12.9809 6.50853C13.3292 6.5402 
13.4717 6.97561 13.2025 7.2052L10.9621 9.14478L11.635 12.0502C11.7142 12.3906 11.35 12.6598 11.0492 12.4777Z"
                        fill="#230870" />
                </svg>
                    {t('check-in-reward-badge-text')} <strong>${rewardAmount}</strong></p>
            </div>
        </>
    )
}
