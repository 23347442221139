import React from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { ShareTopicOfTheMonthView } from './ShareTopicOfTheMonthView';
import { ArticleOfTheMonthState, LearningCenterState } from './../../../common/store-atom/learning-center-atom';

export const ShareTopicOfTheMonthContainer = () => {
  const articleTopicOfTheMonth = useRecoilValue(ArticleOfTheMonthState);
  const learningCenterState = useRecoilValue(LearningCenterState);
  const isReferenceLimitReached = learningCenterState.referencesLimitReached;

  useEffect(() => {}, []);

  return (
    <>
      <ShareTopicOfTheMonthView isReferenceLimitReached={isReferenceLimitReached} article={articleTopicOfTheMonth} />
    </>
  );
};
