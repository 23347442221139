import { IonContent, IonFooter, IonPage, IonText } from '@ionic/react';
import { RepresentativeGoogleButton } from '../../../components/Buttons/representative-google-button';
import './styles.scss';

export const HRALoginView = ({ t }) => (
  <IonPage>
    <IonContent fullscreen>
      <div className="representative-block-center">
        <div className="lc-login-container ion-justify-content-center">
          <svg id="splash-icon" width="360" height="353" viewBox="0 0 360 353" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M359.676 168.458C355.229 220.384 296.881 244.737 257.27 278.605C222.67 308.189 192.226 344.036 147.122 350.197C95.7653 357.213 38.4269 350.454 2.12047 313.459C-33.9932 276.661 -33.0249 219.985 -31.2369 168.458C-29.5308 119.291 -23.9369 66.9429 11.846 33.1814C46.9418 0.0681979 98.8777 -0.640801 147.122 0.174193C194.261 0.970501 240.941 8.41155 277.904 37.6759C319.809 70.8528 364.237 115.204 359.676 168.458Z"
              fill="#F7F7FF"
            />
          </svg>
          <div className="representative-login-title">
            <IonText>
              <h1>Login</h1>
            </IonText>
          </div>
          <div className="representative-login-sub-title">
            <p>{t('representative-login-sub-title')}</p>
          </div>
          <RepresentativeGoogleButton />
        </div>
      </div>
    </IonContent>
    <IonFooter className="representative-login-footer">
      <p>{t('representative-login-footer-title')}</p>
      <p style={{ textDecorationLine: 'underline' }}>{t('representative-login-footer-sub-title')}</p>
    </IonFooter>
  </IonPage>
);
