import { atom, selector } from 'recoil';

export const QuestionsState = atom<Array<any>>({
  key: 'questionsState',
  default: [],
});

export const havePrimaryCareProvider = selector({
  key: 'havePrimaryCareProvider',
  get: ({ get }) => {
    const havePrimaryCare = get(QuestionsState)
      ? get(QuestionsState).find((element) => element.id == 'havePrimaryCareProvider')
      : null;
    return havePrimaryCare ? { id: havePrimaryCare.id, data: havePrimaryCare.data } : null;
  },
});

export const healthConditions = selector({
  key: 'healthConditions',
  get: ({ get }) => {
    const botheredSection = get(QuestionsState).find((element) => element.id == 'healthConditions');
    return botheredSection ? { id: botheredSection.id, data: botheredSection.data } : null;
  },
});

export const healthInsurance = selector({
  key: 'healthInsurance ',
  get: ({ get }) => {
    const botheredSection = get(QuestionsState).find((element) => element.id == 'healthInsurance');
    return botheredSection ? { id: botheredSection.id, data: botheredSection.data } : null;
  },
});

export const closeBloodHealthConditions = selector({
  key: 'closeBloodHealthConditions',
  get: ({ get }) => {
    const bloodHealthConditions = get(QuestionsState).find(
      (element) => element.id == 'closeBloodHealthConditions'
    );
    return bloodHealthConditions
      ? { id: bloodHealthConditions.id, data: bloodHealthConditions.data }
      : null;
  },
});

export const smokeSelector = selector({
  key: 'smoke',
  get: ({ get }) => {
    const smoke = get(QuestionsState).find((element) => element.id == 'smoke');
    return smoke ? { id: smoke.id, data: smoke.data } : null;
  },
});

export const covidVaccineSelector = selector({
  key: 'covidVaccine',
  get: ({ get }) => {
    const covidVaccine = get(QuestionsState).find((element) => element.id == 'covidVaccine');
    return covidVaccine ? { id: covidVaccine.id, data: covidVaccine.data } : null;
  },
});

export const EthnicitySelector = selector({
  key: 'ethnicity',
  get: ({ get }) => {
    const ethnicity = get(QuestionsState).find((element) => element.id == 'ethnicity');
    return ethnicity ? { id: ethnicity.id, data: ethnicity.data } : null;
  },
});

export const botheredSectionSelector = selector({
  key: 'botheredSection',
  get: ({ get }) => {
    const bothered = get(QuestionsState).find((element) => element.id == 'bothered_section');
    return bothered ? { id: bothered.id, data: bothered.data } : null;
  },
});
