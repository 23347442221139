import { auth } from './firebase';
import { User } from '../models/user';
import { splitFullName } from '../utils/user-profile';
import { AuthType } from '../enums/auth-type';
import { identifyUser } from '../../logrocketSetup';
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithCredential,
  AuthCredential,
} from 'firebase/auth';
import { updateLastLoginTimeStamp } from "../adapters/user-profile-adapter";

/**
 *
 * @param provider
 * @param callback
 * @returns
 */
export const signInWithSocialNetwork = async (provider: any, callback: Function, callbackError?: Function) =>
  signInWithPopup(auth, provider)
    .then(async (userCredential) => {
      if (userCredential.user) {
        await updateLastLoginTimeStamp();
        const user = getPatient(userCredential.user, AuthType.socialNetwork);
        identifyUser(user.uid);
        userCredential.user.getIdTokenResult().then(async (_) => {
          callback(user);
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });

export const registerWithEmailAndPassword = async (
  email: string,
  password: string,
  callback: Function,
  callbackError: Function
) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      if (userCredential.user) {
        await updateLastLoginTimeStamp();
        const user = getPatient(userCredential.user, AuthType.emailAndPassword);
        identifyUser(String(user.uid));
        userCredential.user.getIdTokenResult().then(async (_) => {
          callback(user);
        });
      }
    })
    .catch((error) => {
      callbackError(error);
    });

export const logInWithEmailAndPassword = async (email: string, password: string, callback: Function, callbackError: Function) =>
  signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      if (userCredential.user) {
        await updateLastLoginTimeStamp();
        const user = getPatient(userCredential.user, AuthType.emailAndPassword);
        identifyUser(String(user.uid));
        userCredential.user.getIdTokenResult().then(async (_) => {
          callback(user);
        });
      }
    })
    .catch((error) => {
      callbackError(error);
    });

export const loginWithCredential = async (credentials: AuthCredential, errorCallback: Function) => {
  return await signInWithCredential(auth, credentials)
    .then(async (res) => {
      await updateLastLoginTimeStamp();
      return res.user;
    })
    .catch((e) => {
      if (!e?.message?.includes('auth/code-expired')) {
        errorCallback(e.message);
      }
    });
};

export const signOut = async () => {
  return auth.signOut();
};

const getPatient = (user: any, authType: AuthType): User => {
  const { firstName, lastName } = splitFullName(user.displayName);
  return {
    uid: user.uid,
    email: user.email || user.emails[0],
    fullName: user.displayName,
    urlPicture: user.photoURL,
    id: null,
    barberId: null,
    barber_shop: null,
    date_of_birth: null,
    first_name: firstName,
    isClient: null,
    last_name: lastName,
    zipCode: null,
    createdAt: null,
    authType: authType,
    language: null,
    smsNotifications: null,
    validated: null,
  };
};
