import React from 'react';
import { useTranslation } from 'react-i18next';
import { checkmarkCircle } from 'ionicons/icons';
import { IonButton, IonCard, IonContent, IonFooter, IonIcon, IonItem, IonPage } from '@ionic/react';
import { useHistory } from 'react-router';
import { URL_MAIN_EARNINGS } from '../../../common/constants/routers';
import './styles/verifyConfirmation.scss';

export const ConfirmationPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onConfirmationClick = () => {
    return history.replace(URL_MAIN_EARNINGS);
  };

  return (
    <IonPage>
      <IonContent fullscreen className="lc-content-footer-with-tabs lc-verify-code-confirmation ion-no-padding">
        <IonItem color="success" className="ion-text-center">
          <article>
            <IonIcon slot="start" icon={checkmarkCircle} />
            <h4>{t('mobile_verification_confirmation_message')}</h4>
          </article>
        </IonItem>
        <div className="lc-cellphone-confirmation-buttons-container">
          <IonCard color="light" className="ion-text-center">
            <p>{t('mobile_verification_confirmation_message_card')}</p>
          </IonCard>
        </div>
      </IonContent>

      <IonFooter className="lc-footer-with-tabs">
        <div className="block-center-profile-register">
          <div className="lc-profile-register-buttons-container">
            <IonButton onClick={onConfirmationClick} expand="block">
              {t('page_home_done_status')}
            </IonButton>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  );
};
