import { Address } from "@live-chair-health/lch-core";

export enum HRALanguageType {
  EN = 'en',
  ES = 'es',
}

export interface HRARegister {
  firstName: string | null;
  lastName: string | null;
  cellPhone: string | null;
  email: string | null;
  zipCode?: string | null;
  birthday: string | null;
  createdAt?: string | null;
  language: HRALanguageType;
  address?: string | null | Address;
}
