import React from "react";

export const ShareTopicOfTheMonthCentralBanner = () => {

    return (
        <svg width="269" height="170" viewBox="0 0 269 170" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.15138 59.8132C-4.37019 88.0933 -3.14846 124.629 40.8033 151.781C40.8033 151.781 80.8684 171.03 135.045 169.14C194.604 167.056 227.354 151.781 227.354 151.781C227.354 151.781 266.381 140.608 268.038 99.7259C269.694 58.8433 254.858 3.24577 202.69 6.92448C150.522 10.6032 125.767 24.1685 91.2073 15.1585L89.0235 14.6124C52.0203 5.818 14.2383 26.2737 4.15138 59.8132Z" fill="#E6E6FF"/>
        <path d="M164.084 131.037V141.569C164.084 146.335 149.974 150.161 132.577 150.161C115.18 150.161 101.097 146.242 101.142 141.467V130.944L164.084 131.037Z" fill="#FFD200"/>
        <path d="M164.066 143.736V154.268C164.066 159.034 149.947 162.86 132.55 162.86C115.153 162.86 101.07 158.941 101.115 154.174V143.643L164.066 143.736Z" fill="#FFD200"/>
        <path opacity="0.08" d="M164.066 143.736V154.268C164.066 159.034 149.947 162.86 132.55 162.86C115.153 162.86 101.07 158.941 101.115 154.174V143.643L164.066 143.736Z" fill="black"/>
        <path d="M163.476 143.41C163.485 138.683 149.523 134.828 132.29 134.799C115.058 134.771 101.082 138.58 101.073 143.307C101.064 148.034 115.027 151.889 132.259 151.917C149.491 151.945 163.467 148.136 163.476 143.41Z" fill="#FFD200"/>
        <path opacity="0.08" d="M164.084 131.037V141.569C164.084 146.335 149.974 150.161 132.577 150.161C115.18 150.161 101.097 146.242 101.142 141.467V130.944L164.084 131.037Z" fill="black"/>
        <path d="M161.83 118.474V129.006C161.83 133.772 147.702 137.598 130.314 137.598C112.926 137.598 98.8251 133.679 98.8341 128.904V118.381L161.83 118.474Z" fill="#FFD200"/>
        <path d="M164.129 105.793V116.316C164.129 121.091 150.019 124.909 132.631 124.909C115.243 124.909 101.142 120.998 101.151 116.223V105.708L164.129 105.793Z" fill="#FFD200"/>
        <path d="M163.503 130.833C163.512 126.106 149.55 122.251 132.317 122.223C115.085 122.194 101.109 126.003 101.1 130.73C101.091 135.457 115.054 139.312 132.286 139.34C149.518 139.369 163.494 135.56 163.503 130.833Z" fill="#FFD200"/>
        <path opacity="0.08" d="M161.83 118.474V129.006C161.83 133.772 147.702 137.598 130.314 137.598C112.926 137.598 98.8251 133.679 98.8341 128.904V118.381L161.83 118.474Z" fill="black"/>
        <path d="M161.245 118.367C161.254 113.64 147.291 109.785 130.059 109.756C112.827 109.728 98.8507 113.537 98.8419 118.264C98.8331 122.991 112.795 126.846 130.028 126.874C147.26 126.902 161.236 123.094 161.245 118.367Z" fill="#FFD200"/>
        <path opacity="0.08" d="M164.129 105.793V116.316C164.129 121.091 150.019 124.909 132.631 124.909C115.243 124.909 101.142 120.998 101.151 116.223V105.708L164.129 105.793Z" fill="black"/>
        <path d="M164.141 105.807C164.15 101.08 150.051 97.2251 132.65 97.1965C115.25 97.1678 101.136 100.977 101.128 105.703C101.119 110.43 115.218 114.285 132.619 114.314C150.02 114.343 164.133 110.534 164.141 105.807Z" fill="#FFD200"/>
        <path d="M237.256 128.913V140.697C237.256 146.03 221.449 150.339 201.986 150.306C182.523 150.272 166.743 145.92 166.752 140.578V128.803L237.256 128.913Z" fill="#FFD200"/>
        <path d="M237.229 143.119V154.903C237.229 160.245 221.431 164.545 201.959 164.52C182.487 164.494 166.716 160.135 166.725 154.793V143L237.229 143.119Z" fill="#FFD200"/>
        <path opacity="0.08" d="M237.229 143.119V154.903C237.229 160.245 221.431 164.545 201.959 164.52C182.487 164.494 166.716 160.135 166.725 154.793V143L237.229 143.119Z" fill="black"/>
        <path d="M236.562 142.737C236.572 137.445 220.946 133.128 201.66 133.097C182.375 133.065 166.733 137.33 166.723 142.622C166.713 147.915 182.339 152.231 201.625 152.263C220.91 152.295 236.553 148.03 236.562 142.737Z" fill="#FFD200"/>
        <path opacity="0.08" d="M237.256 128.913V140.697C237.256 146.03 221.449 150.339 201.986 150.306C182.523 150.272 166.743 145.92 166.752 140.578V128.803L237.256 128.913Z" fill="black"/>
        <path d="M234.741 114.834V126.61C234.741 131.96 218.943 136.252 199.471 136.227C179.999 136.202 164.228 131.85 164.237 126.5V114.724L234.741 114.834Z" fill="#FFD200"/>
        <path d="M237.301 100.646V112.43C237.301 117.763 221.503 122.064 202.031 122.03C182.559 121.996 166.788 117.653 166.797 112.311V100.536L237.301 100.646Z" fill="#FFD200"/>
        <path d="M236.58 128.676C236.59 123.384 220.964 119.067 201.678 119.036C182.393 119.004 166.751 123.269 166.741 128.561C166.731 133.854 182.357 138.17 201.643 138.202C220.929 138.234 236.571 133.969 236.58 128.676Z" fill="#FFD200"/>
        <path opacity="0.08" d="M234.741 114.834V126.61C234.741 131.96 218.943 136.252 199.471 136.227C179.999 136.202 164.228 131.85 164.237 126.5V114.724L234.741 114.834Z" fill="black"/>
        <path d="M234.07 114.718C234.08 109.425 218.454 105.109 199.168 105.077C179.883 105.045 164.24 109.31 164.231 114.603C164.221 119.896 179.847 124.212 199.133 124.243C218.418 124.275 234.06 120.01 234.07 114.718Z" fill="#FFD200"/>
        <path opacity="0.08" d="M237.301 100.646V112.43C237.301 117.763 221.503 122.064 202.031 122.03C182.559 121.996 166.788 117.653 166.797 112.311V100.536L237.301 100.646Z" fill="black"/>
        <path d="M237.309 100.649C237.318 95.356 221.539 91.0395 202.065 91.0074C182.591 90.9754 166.796 95.2399 166.786 100.533C166.777 105.825 182.556 110.142 202.03 110.174C221.504 110.206 237.299 105.941 237.309 100.649Z" fill="#FFD200"/>
        <path d="M198.223 7.66697C198.223 7.66697 202.345 0.267948 203.145 0.792822C203.38 0.998915 203.557 1.25684 203.661 1.54349C203.764 1.83015 203.791 2.13656 203.737 2.43517C203.737 2.43517 206.082 -1.09505 207.878 0.504965C209.674 2.10498 207.114 7.11668 202.938 8.97067L198.223 7.66697Z" fill="#9B6448"/>
        <path d="M140.849 29.9145C140.849 29.9145 178.841 26.4774 197.792 5.5332L205.39 8.82637C205.39 8.82637 197.217 22.7694 186.529 32.4203C167.057 49.9951 144.927 52.0438 144.927 52.0438L140.849 29.9145Z" fill="#68E1FD"/>
        <path d="M140.849 29.9145C140.849 29.9145 178.841 26.4774 197.792 5.5332L205.39 8.82637C205.39 8.82637 197.217 22.7694 186.529 32.4203C167.057 49.9951 144.927 52.0438 144.927 52.0438L140.849 29.9145Z" fill="#F4F4F4"/>
        <path d="M49.2566 5.97376C49.2566 5.97376 47.0112 1.67319 46.2118 2.21499C45.9771 2.42154 45.8002 2.67955 45.6968 2.96607C45.5934 3.25259 45.5667 3.55874 45.6191 3.85734C45.6191 3.85734 43.2749 0.327126 41.4876 1.93561C39.7003 3.54409 42.242 8.53039 46.3825 10.4013L49.2566 5.97376Z" fill="#9B6448"/>
        <path d="M141.334 10.3505C141.998 12.6346 142.333 14.9921 142.331 17.3602C142.312 17.6375 142.235 17.9086 142.104 18.1579C141.973 18.4072 141.792 18.6297 141.57 18.8129C141.348 18.996 141.09 19.1362 140.811 19.2254C140.532 19.3146 140.236 19.3511 139.942 19.3327C138.976 19.3296 138.032 19.0624 137.223 18.5635C136.415 18.0646 135.778 17.3555 135.389 16.5221L133.251 13.085C132.918 12.3641 132.832 11.5635 133.004 10.7943C133.177 10.0251 133.599 9.32558 134.212 8.79287C136.394 6.65951 140.688 7.82775 141.334 10.3505Z" fill="#9B6448"/>
        <path d="M134.14 13.957L130.602 27.4852L138.649 28.7889L138.011 18.6894L134.14 13.957Z" fill="#9B6448"/>
        <path d="M141.864 12.9746L143.418 14.363C143.487 14.4261 143.542 14.5018 143.58 14.5854C143.618 14.669 143.637 14.7589 143.637 14.8497C143.637 14.9406 143.618 15.0305 143.58 15.1141C143.542 15.1978 143.487 15.2734 143.418 15.3365C143.35 15.4095 143.264 15.4649 143.167 15.4974L141.523 16.09L141.864 12.9746Z" fill="#9B6448"/>
        <path d="M138.029 18.977C138.029 18.977 135.335 17.7748 134.535 16.7251C134.535 16.7251 134.688 22.2786 138.343 23.904L138.029 18.977Z" fill="#774B37"/>
        <path d="M136.844 14.1772C136.844 14.1772 136.493 12.5772 135.236 12.9243C133.979 13.2714 134.464 15.5995 136.251 15.464L136.844 14.1772Z" fill="#9B6448"/>
        <path d="M131.895 12.6871L132.245 12.8734C133.175 12.9705 134.042 13.3614 134.706 13.9824C134.975 13.9824 135.317 13.9824 135.604 13.9824L135.712 13.9232C135.843 13.8624 135.978 13.8087 136.116 13.7623C135.986 13.1781 135.947 12.5793 135.999 11.9845C136.561 11.9871 137.114 11.8604 137.612 11.6155C138.11 11.3706 138.537 11.0149 138.855 10.5792L140.652 9.73262C141.049 9.53676 141.398 9.2633 141.675 8.93044C141.952 8.59757 142.151 8.21294 142.258 7.80211C142.365 7.39127 142.378 6.96365 142.296 6.5477C142.214 6.13175 142.04 5.737 141.783 5.38971C141.475 4.95421 141.078 4.58045 140.615 4.2899C140.153 3.99935 139.634 3.79773 139.089 3.69657C135.972 3.22249 128.617 7.65005 131.571 12.2131L131.895 12.6871Z" fill="#24285B"/>
        <path d="M134.203 12.5514C134.203 12.5514 132.02 11.3324 131.194 12.9747C130.368 14.6171 132.829 15.9038 134.49 14.9049C134.097 14.1786 133.995 13.343 134.203 12.5514Z" fill="#9B6448"/>
        <path opacity="0.08" d="M138.344 138.657L140.858 107.655L151.331 109.162L154.456 121.057L152.139 138.657H138.344Z" fill="black"/>
        <path d="M139.655 134.576C139.655 134.576 140.777 137.514 143.589 137.742C146.4 137.971 147.136 140.561 144.603 141.23C142.071 141.899 135.55 138.885 135.55 138.885L135.739 135.059L139.655 134.576Z" fill="#101328"/>
        <path d="M147.469 74.1228C141.559 84.6795 143.319 104.066 143.319 104.066L111.615 103.219C110.812 101.848 110.052 100.465 109.333 99.0712C98.8341 78.9059 96.2295 56.3448 97.7833 43.7309C98.0066 41.0617 98.7018 38.4464 99.84 35.9933C104.519 27.3582 131.275 27.3159 131.275 27.3159L139.358 28.6196C171.736 41.1235 153.369 63.5407 147.469 74.1228Z" fill="#E6E6E6"/>
        <path d="M111.615 103.21L127.108 101.001C129.345 100.678 131.632 100.842 133.791 101.482C135.951 102.122 137.926 103.219 139.565 104.691C141.204 106.162 142.464 107.968 143.247 109.97C144.03 111.972 144.316 114.117 144.083 116.239L142.062 134.813H134.464L135.119 117.424C135.16 116.45 134.971 115.48 134.566 114.583C134.16 113.686 133.549 112.885 132.776 112.237C132.003 111.589 131.087 111.111 130.095 110.837C129.102 110.563 128.058 110.5 127.036 110.652C120.443 111.71 112.27 111.278 111.615 103.21Z" fill="#24285B"/>
        <path d="M155.435 134.576C155.435 134.576 156.558 137.514 159.378 137.742C162.198 137.971 162.917 140.561 160.384 141.23C157.851 141.899 151.331 138.885 151.331 138.885L151.519 135.059L155.435 134.576Z" fill="#101328"/>
        <path opacity="0.08" d="M137.409 102.381L111.615 103.228L109.405 99.0967L109.333 99.0205C98.8341 78.8552 96.2295 56.2941 97.7833 43.6802L114.327 39.0664C114.327 39.0664 123.12 46.3215 121.36 54.3047C121.063 55.6084 120.776 57.1661 120.461 58.8846C119.319 65.5352 119.747 72.3415 121.716 78.8181C123.684 85.2948 127.145 91.2823 131.85 96.3538L137.409 102.381Z" fill="black"/>
        <path d="M127.395 103.211L142.888 101.001C145.126 100.68 147.412 100.846 149.571 101.486C151.73 102.126 153.705 103.224 155.344 104.695C156.983 106.166 158.244 107.971 159.029 109.972C159.814 111.973 160.102 114.117 159.872 116.239L157.851 134.813H150.253L150.908 117.425C150.977 115.683 150.31 113.987 149.054 112.708C147.798 111.429 146.056 110.672 144.208 110.601C143.745 110.583 143.282 110.611 142.825 110.686C136.224 111.71 128.051 111.278 127.395 103.211Z" fill="#24285B"/>
        <path d="M115.746 30.2788C115.746 30.2788 83.2602 24.5983 49.7864 4.96631L44.7119 11.0616C44.7119 11.0616 79.6228 45.8472 108.525 50.588C121.036 52.6452 131.94 35.282 115.746 30.2788Z" fill="#68E1FD"/>
        <path d="M115.746 30.2788C115.746 30.2788 83.2602 24.5983 49.7864 4.96631L44.7119 11.0616C44.7119 11.0616 79.6228 45.8472 108.525 50.588C121.036 52.6452 131.94 35.282 115.746 30.2788Z" fill="#F4F4F4"/>
        <path d="M95.4211 128.041V139.816C95.4211 145.158 79.6138 149.459 60.151 149.425C40.6882 149.391 24.9078 145.048 24.9168 139.706V127.931L95.4211 128.041Z" fill="#FFD200"/>
        <path d="M95.3672 142.212V153.988C95.3672 159.33 79.5689 163.631 60.0971 163.597C40.6253 163.563 24.8539 159.211 24.8629 153.878V142.094L95.3672 142.212Z" fill="#FFD200"/>
        <path opacity="0.08" d="M95.3672 142.212V153.988C95.3672 159.33 79.5689 163.631 60.0971 163.597C40.6253 163.563 24.8539 159.211 24.8629 153.878V142.094L95.3672 142.212Z" fill="black"/>
        <path d="M94.7314 141.855C94.7412 136.562 79.115 132.246 59.8293 132.214C40.5437 132.183 24.9016 136.447 24.8918 141.74C24.882 147.033 40.5082 151.349 59.7939 151.381C79.0795 151.412 94.7216 147.148 94.7314 141.855Z" fill="#FFD200"/>
        <path opacity="0.08" d="M95.4211 128.041V139.816C95.4211 145.158 79.6138 149.459 60.151 149.425C40.6882 149.391 24.9078 145.048 24.9168 139.706V127.931L95.4211 128.041Z" fill="black"/>
        <path d="M92.8794 113.954V125.738C92.8794 131.071 77.081 135.381 57.6093 135.347C38.1375 135.313 22.3661 130.961 22.3751 125.628V113.844L92.8794 113.954Z" fill="#FFD200"/>
        <path d="M95.4661 99.7655V111.541C95.4661 116.875 79.6677 121.184 60.1959 121.158C40.7242 121.133 24.9528 116.765 24.9617 111.431V99.647L95.4661 99.7655Z" fill="#FFD200"/>
        <path d="M94.7584 127.794C94.7682 122.502 79.142 118.186 59.8564 118.154C40.5707 118.122 24.9286 122.387 24.9188 127.68C24.909 132.972 40.5352 137.288 59.8209 137.32C79.1065 137.352 94.7486 133.087 94.7584 127.794Z" fill="#FFD200"/>
        <path opacity="0.08" d="M92.8794 113.954V125.738C92.8794 131.071 77.081 135.381 57.6093 135.347C38.1375 135.313 22.3661 130.961 22.3751 125.628V113.844L92.8794 113.954Z" fill="black"/>
        <path d="M92.2302 113.844C92.24 108.551 76.6139 104.235 57.3282 104.203C38.0425 104.171 22.4005 108.436 22.3907 113.729C22.3809 119.021 38.0071 123.338 57.2927 123.369C76.5784 123.401 92.2204 119.136 92.2302 113.844Z" fill="#FFD200"/>
        <path opacity="0.08" d="M95.4661 99.7655V111.541C95.4661 116.875 79.6677 121.184 60.1959 121.158C40.7242 121.133 24.9528 116.765 24.9617 111.431V99.647L95.4661 99.7655Z" fill="black"/>
        <path opacity="0.08" d="M94.0829 165.832C108.537 165.832 120.255 154.787 120.255 141.163C120.255 127.538 108.537 116.494 94.0829 116.494C79.6285 116.494 67.911 127.538 67.911 141.163C67.911 154.787 79.6285 165.832 94.0829 165.832Z" fill="black"/>
        <path opacity="0.08" d="M76.6679 116.434C76.6679 116.434 49.2745 124.307 53.6754 163.419C53.6754 163.419 71.5395 163.986 80.018 161.937C87.5893 160.134 76.6679 116.434 76.6679 116.434Z" fill="black"/>
        <path d="M95.4776 99.7668C95.4874 94.4741 79.7084 90.1576 60.2343 90.1256C40.7601 90.0936 24.9652 94.3581 24.9554 99.6507C24.9456 104.943 40.7246 109.26 60.1988 109.292C79.6729 109.324 95.4678 105.059 95.4776 99.7668Z" fill="#FFD200"/>
        <path d="M94.0829 165.832C108.537 165.832 120.255 154.787 120.255 141.163C120.255 127.538 108.537 116.494 94.0829 116.494C79.6285 116.494 67.911 127.538 67.911 141.163C67.911 154.787 79.6285 165.832 94.0829 165.832Z" fill="#FFD200"/>
        <path opacity="0.08" d="M94.0829 165.832C108.537 165.832 120.255 154.787 120.255 141.163C120.255 127.538 108.537 116.494 94.0829 116.494C79.6285 116.494 67.911 127.538 67.911 141.163C67.911 154.787 79.6285 165.832 94.0829 165.832Z" fill="black"/>
        <g opacity="0.08">
        <path opacity="0.08" d="M95.3583 158.408C109.813 158.408 121.53 147.363 121.53 133.738C121.53 120.114 109.813 109.069 95.3583 109.069C80.9039 109.069 69.1864 120.114 69.1864 133.738C69.1864 147.363 80.9039 158.408 95.3583 158.408Z" fill="black"/>
        </g>
        <path d="M95.3583 158.408C109.813 158.408 121.53 147.363 121.53 133.738C121.53 120.114 109.813 109.069 95.3583 109.069C80.9039 109.069 69.1864 120.114 69.1864 133.738C69.1864 147.363 80.9039 158.408 95.3583 158.408Z" fill="#FFD200"/>
        <path d="M96.2025 122.708C96.948 122.708 97.1636 122.945 97.1636 123.639V125.129C98.4673 125.257 99.7087 125.72 100.754 126.466C101.798 127.212 102.607 128.213 103.091 129.362C103.478 130.081 103.316 130.479 102.445 130.852L100.864 131.512C100.127 131.85 99.7682 131.69 99.355 130.97C99.027 130.216 98.4496 129.581 97.7087 129.16C96.9677 128.739 96.1025 128.554 95.2415 128.634C92.5022 128.634 91.2448 129.387 91.2448 131.114C91.3087 131.643 91.5644 132.135 91.9686 132.506C92.3728 132.878 92.9007 133.107 93.4632 133.154C94.2303 133.311 95.0073 133.421 95.7894 133.484C97.2473 133.551 98.6873 133.816 100.065 134.272C100.752 134.484 101.394 134.811 101.96 135.237C103.1 136.379 103.714 137.901 103.666 139.47C103.666 142.771 101.322 145.023 97.1636 145.599V147.165C97.1636 147.868 96.948 148.113 96.2025 148.113H94.4601C93.7147 148.113 93.4632 147.868 93.4632 147.165V145.658C92.0215 145.566 90.641 145.073 89.4952 144.243C88.3494 143.413 87.4894 142.283 87.0235 140.993C86.9066 140.753 86.8947 140.479 86.9905 140.23C87.0863 139.981 87.282 139.778 87.5354 139.664C87.6097 139.63 87.6881 139.605 87.7689 139.588L89.3407 139.046C90.149 138.775 90.4993 138.953 90.8586 139.707C91.5651 141.4 93.0141 142.246 95.2056 142.246C98.1605 142.246 99.6424 141.4 99.6424 139.571C99.6649 139.158 99.5413 138.749 99.291 138.409C99.0407 138.069 98.6779 137.817 98.2593 137.692C97.2325 137.353 96.158 137.159 95.0709 137.116C93.5957 137.048 92.1409 136.763 90.7598 136.27C90.1037 136.038 89.4957 135.7 88.9635 135.271C88.3851 134.733 87.9315 134.089 87.6315 133.378C87.3316 132.666 87.1918 131.904 87.2211 131.139C87.2211 127.872 89.4036 125.687 93.5081 125.213V123.723C93.5081 123.029 93.7685 122.792 94.505 122.792L96.2025 122.708Z" fill="white"/>
        <path opacity="0.58" d="M52.5258 89.5894C52.5258 89.5894 44.9454 87.6338 43.3018 80.9967C43.3018 80.9967 55.0406 78.7532 55.3729 90.1819L52.5258 89.5894Z" fill="#F77B28"/>
        <path opacity="0.73" d="M53.4329 88.8872C53.4329 88.8872 48.1339 80.9972 52.7952 73.6489C52.7952 73.6489 61.7318 78.9823 57.753 88.9295L53.4329 88.8872Z" fill="#F77B28"/>
        <path d="M54.843 88.9379C54.843 88.9379 57.6272 80.5992 66.0877 79.0161C66.0877 79.0161 67.6774 84.4342 60.618 88.9548L54.843 88.9379Z" fill="#F77B28"/>
        <path d="M49.3463 88.7007L50.8732 98.6141L60.5552 98.6479L61.9743 88.7515L49.3463 88.7007Z" fill="#24285B"/>
        </svg>
    )
}