import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import {
  IonButton,
  IonContent,
  IonDatetime,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react';
import * as yup from 'yup';

import { appointmentProvider } from '../../../../common/adapters/provider-adapter';
import { getCurrentDate } from '../../../../common/utils/date';
import { MessageToastHooks } from '../../../../common/hooks/message-toast';
import { Spinner } from '../../../../components/spinner/spinner';
import { useHousehold } from '../../../../common/hooks/useHousehold';
import { ProfileState } from '../../../../common/store-atom/profile-atom';
import BlankCard from '../../../../components/blank-card';
import { URL_MAIN_HEALTH, URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION } from '../../../../common/constants/routers';
import TopCard from '../../../../components/top-card';
import './../styles.scss';

const preferredWeekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const RequestAppointmentPage: React.FC = () => {
  const history = useHistory();
  const { messageToastError } = MessageToastHooks();
  // const [provider, setProvider] = useRecoilState(providerSelectedState);
  const userData = useRecoilValue(ProfileState);
  const { t } = useTranslation();
  const { familyMembers, getFamilyMembers, loading, household } = useHousehold();

  const schema = yup.object().shape({
    selectedMembers: yup.array().min(1, t('message_field_required')),
    requestedDate: yup.string().required(t('message_field_required')),
    preferredDays: yup.array().min(1, t('message_field_required')),
    preferredTimes: yup.array().min(1, t('message_field_required')),
    notes: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: { selectedMembers: [], requestedDate: '', preferredDays: [], preferredTimes: [], notes: '' },
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    history.push(URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION);
  };

  const onError = (error: AxiosError) => messageToastError(error);

  const mutationAppointmentProvider = useMutation(appointmentProvider, {
    onSuccess,
    onError,
  });

  const onSubmit = (data: any) => {
    delete data.selectedMembers;
    const selectedMembers = getValues('selectedMembers');
    // data.providerId = provider.id;
    data.householdId = userData?.app_household_id;
    const householdUsersIds = household?.users.map((user) => user.id);
    const householdMembersIds = household?.members.map((user) => user.id);

    data.householdUsers = householdUsersIds?.filter((userId) => {
      return selectedMembers.find((selectedMember) => selectedMember === userId);
    });

    data.householdMembers = householdMembersIds?.filter((memberId) => {
      return selectedMembers.find((selectedMember) => selectedMember === memberId);
    });

    // setProvider({ ...provider, requestedDate: data.requestedDate });
    mutationAppointmentProvider.mutate({ ...data });
  };

  useEffect(() => {
    getFamilyMembers();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage>
        <IonContent fullscreen className="lc-full-spot-bg lc-provider-details lc-provider-request lc-content-footer-with-tabs">
          <Spinner isOpen={loading || mutationAppointmentProvider.isLoading} />

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '400px' }}>
              <TopCard message={`👩🏾 ${t('doctor_in')}, ${userData.first_name}`} redirectTo={URL_MAIN_HEALTH} />
              <BlankCard options={null}>
                {/* <ItemProvider key={provider.id} provider={provider} disabledOnClick={true} /> */}
                <div className="request-appointment-fields-container">
                  <IonItem
                    lines="none"
                    className={errors.selectedMembers ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}
                  >
                    <IonLabel position="stacked" color={errors.selectedMembers ? 'danger' : 'primary'}>
                      {t('providers_tab_request_appointment_who_is_for')}
                    </IonLabel>
                    <IonSelect
                      disabled={loading}
                      placeholder={loading ? `${t('generic-text_loading')}...` : ''}
                      multiple
                      cancelText={t('button_title_cancel')}
                      okText={t('button_title_apply')}
                      {...register('selectedMembers')}
                    >
                      {familyMembers.map((familyMember) => {
                        return (
                          <IonSelectOption key={familyMember.id} value={familyMember.id}>
                            {`${familyMember.firstName} ${familyMember.lastName}`}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                    <span>{errors.selectedMembers ? t('message_field_required') : ''}</span>
                  </IonItem>

                  <IonItem
                    lines="none"
                    className={errors.requestedDate?.message ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}
                  >
                    <IonLabel position="stacked" color={errors.requestedDate?.message ? 'danger' : 'primary'}>
                      {t('providers_tab_request_appointment_modal.title')}?
                    </IonLabel>
                    <IonDatetime
                      displayFormat="DDDD, MMM YYYY"
                      //@ts-ignore
                      min={getCurrentDate()}
                      //@ts-ignore
                      max={String(new Date().getFullYear() + 5)}
                      {...register('requestedDate')}
                    />
                    <span>{errors.requestedDate?.message}</span>
                  </IonItem>

                  <IonItem lines="none" className={errors.preferredDays ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}>
                    <IonLabel position="stacked" color={errors.preferredDays ? 'danger' : 'primary'}>
                      {t('providers_tab_request_appointment_preferred_days')}
                    </IonLabel>
                    <IonSelect
                      multiple
                      cancelText={t('button_title_cancel')}
                      okText={t('button_title_apply')}
                      {...register('preferredDays')}
                    >
                      {preferredWeekDays.map((day) => {
                        return (
                          <IonSelectOption key={day} value={day}>
                            {t(`providers_tab_request_appointment_${day.toLowerCase()}`)}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                    <span>{errors.preferredDays ? t('message_field_required') : ''}</span>
                  </IonItem>

                  <IonItem lines="none" className={errors.preferredTimes ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}>
                    <IonLabel position="stacked" color={errors.preferredTimes ? 'danger' : 'primary'}>
                      {t('providers_tab_request_appointment_preferred_times')}
                    </IonLabel>
                    <IonSelect
                      multiple
                      cancelText={t('button_title_cancel')}
                      okText={t('button_title_apply')}
                      {...register('preferredTimes')}
                    >
                      <IonSelectOption key="Morning" value="Morning">
                        {t('providers_tab_request_appointment_modal_time.select_morning')}
                      </IonSelectOption>
                      <IonSelectOption key="Afternoon" value="Afternoon">
                        {t('providers_tab_request_appointment_modal_time.select_afternoon')}
                      </IonSelectOption>
                    </IonSelect>
                    <span>{errors.preferredTimes ? t('message_field_required') : ''}</span>
                  </IonItem>

                  <IonItem lines="none">
                    <IonLabel position="stacked">{t('providers_tab_request_appointment_additional_notes.label')}</IonLabel>
                    <IonTextarea className="text-area" maxlength={255} {...register('notes')} />
                    <p>{t('providers_tab_request_appointment_additional_notes.footer')}</p>
                  </IonItem>
                </div>
                <div className="provider-details-buttons-container">
                  <IonButton expand="block" fill="solid" type="submit">
                    {t('providers_tab_request_appointment_button.title')}
                  </IonButton>
                </div>
              </BlankCard>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </form>
  );
};
