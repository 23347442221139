import React from 'react';
import { IonPage, IonContent, IonImg, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { URL_MAIN_HOME } from '../../../common/constants/routers';
import '../styles.scss';


export const InvalidUniqueLink = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent fullscreen className="lc-justify-center error-draw">
        <div className="block-center-error-page">
          <div className="lc-error-page-buttons-container">
            <div className="lch-error-container">
              <div className="error-code">
                <h6>404</h6>
              </div>
              <div className="error-title">
                <h6>
                  {t('uniqueLink.invalid_link').toUpperCase()}
                  <br />
                </h6>
                <br />
              </div>
              <div className="error-message">
                <h6>
                  {t('uniqueLink.invalid_link_description')}
                </h6>
              </div>
              <div className="lch-error-footer">
                <IonButton href={URL_MAIN_HOME} className="lch-error-redirect-button">
                  {t('page_error_404_page_back_to_home.button')}
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
