import { AccountSetting } from '../models/user';
import api from './api';

/**
 *
 * @param email
 * @returns
 */
export const getPatient = async () => {
  return await api.get('profile');
};

export const savePatient = async (patient: any) => {
  if (!patient.isClient) {
    delete patient.isClient;
    return await api.post('hpProfile', patient);
  }

  delete patient.isClient;
  return await api.post('profile', patient);
};

export const updatePatient = async (patient: any) => {
  return await api.patch('profile', patient);
};

export const updateAccountSetting = (accountSetting: AccountSetting) => {
  delete accountSetting.createdAt;
  return api.patch('profile', accountSetting);
};

export const getCellphoneNumberVerificationCode = async () => {
  return await api.post('profile/CreateCellPhoneVerificationCode');
};

export const verifyCellphoneNumberVerificationCode = async (verificationCode) => {
  let code = Object.values(verificationCode).join('');
  return await api.post('profile/VerifyCellPhoneCode', { code: Object.values(verificationCode).join('') });
};

export const updateLastLoginTimeStamp = async () => {
  return await api.post('profile/login-timestamp');
};
