import React, { useRef } from 'react';
import { IonPage, IonContent, IonText, IonButton, IonCol, IonGrid, IonRow, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as auth from 'firebase/auth'
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { phoneNumberState, comesFromAccountSettingsState, wasNewPhoneNumberVerifiedState, verificationIdState, comesFromUniqueLinkState } from '../../common/store-atom/register-atom';
import { URL_MAIN_HOME, URL_PROFILE_REGISTER, URL_REGISTER, URL_ACCOUNT_SETTINGS } from '../../common/constants/routers';
import { loginWithCredential } from '../../common/firebase/sign-in';
import { UserState } from '../../common/store-atom/user-atom';
import { getPatient } from '../../common/adapters/user-profile-adapter';
import { phoneAuth } from '../../common/firebase/phone-auth';

import './styles.scss';

export const PhoneCodeVerify = ({ totalFields = 6 }) => {
  const { t } = useTranslation();
  const fieldsContainer = useRef<HTMLDivElement>(null!);
  const phoneNumber = useRecoilValue(phoneNumberState);
  const { comesFromAccountSettings } = useRecoilValue(comesFromAccountSettingsState);
  const { comesFromUniqueLink } = useRecoilValue(comesFromUniqueLinkState);
  const setWasNewPhoneNumberVerified = useSetRecoilState(wasNewPhoneNumberVerifiedState);
  const verificationId = useRecoilValue(verificationIdState);

  const history = useHistory();
  const setUser = useSetRecoilState(UserState);
  const [present, dismiss] = useIonToast();

  const handleError = (message) => {
    present({
      color: 'danger',
      position: 'top',
      buttons: [{ text: 'Ok', handler: () => dismiss() }],
      message: message,
    });
  };

  const getFieldsValue = () => {
    const typedOtp = Array.from(fieldsContainer.current.childNodes)
      //@ts-ignore
      .map((inputElement) => inputElement.value)
      .join('');

    return typedOtp;
  };

  const verifyOtp = async () => {
    const confirmationResult = window.confirmationResult;
    if (comesFromAccountSettings) {
      const credential = await auth.PhoneAuthProvider.credential(verificationId.verificationId, getFieldsValue());

      // @ts-ignore
      auth.updatePhoneNumber(phoneAuth.currentUser, credential).then(async () => {
        setWasNewPhoneNumberVerified({ wasNewPhoneNumberVerified: true });
        history.push(URL_ACCOUNT_SETTINGS);
      }).catch((e) => {
        handleError(e.message);
        return;
      });
    } else {
      await confirmationResult
        .confirm(getFieldsValue())
        .then(async () => {
          const credential = auth.PhoneAuthProvider.credential(confirmationResult.verificationId, getFieldsValue());
  
          await loginWithCredential(credential, handleError).then((user) => user);
  
          await getPatient()
            .then((result) => {
              const patient = {
                ...result?.data?.data,
                fullName: null,
                uid: null,
                urlPicture: null,
                authType: null,
              };
  
              setUser(patient);

              history.replace(comesFromUniqueLink ? URL_PROFILE_REGISTER : URL_MAIN_HOME);
            })
            .catch((e) => {
              if (e.message.includes('604')) {
                history.replace(URL_PROFILE_REGISTER);
                return;
              }
              handleError(e.message);
            });
        })
        .catch((e) => {
          handleError(e.message);
          return;
        });
    }
  };

  const hidePhoneInfo = (phone: string) => {
    const mask = '+1 *** *** ';
    return mask.concat(phone.slice(-4));
  };

  return (
    <IonPage>
      <IonContent fullscreen className="lc-spot-bg">
        <div className="lc-phone-verify-container">
          <div className="lc-phone-verify-sub-container">
            <IonText color="primary">
              <h2>{t('mobile_verification_verify_code_title')}</h2>
            </IonText>
            <IonText color="primary">
              <h3 className="lc-phone-verify-title">{t('mobile_verification_confirmation_toast')}</h3>
              <h3 className="lc-phone-verify-title">{hidePhoneInfo(phoneNumber.phone)}</h3>
            </IonText>
          </div>

          <div ref={fieldsContainer} className="lc-phone-verify-inputs-container">
            {Array(totalFields)
              .fill(InputBox)
              .map((InputElement, index) => {
                return <InputElement key={`${index}-verifyInput`} />;
              })}
          </div>

          <IonGrid className="lc-footer register-email-footer-container">
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={() => history.replace(URL_REGISTER)} fill="outline" type="button">
                  {t('button_title_cancel')}
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton expand="block" onClick={() => verifyOtp()}>
                  {t('button_title_next')}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

const InputBox = () => {
  const focusNext = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {
      // @ts-ignore
      e.currentTarget.nextElementSibling?.focus();
    }
  };

  const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
    className: 'lc-input lc-phone-verify-input',
    maxLength: 1,
    onInput: focusNext,
    placeholder: '-',
    type: 'text',
  };

  return <input {...inputProps} />;
};
