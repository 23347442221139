import { Storage } from '@capacitor/storage';

/**Constant */
const KEY_NAME = 'token';
const KEY_LAST_NAVIGATION_PAGE = 'lastNavegationPage';

export const deleteToken = async () => {
  const removed = await Storage.remove({
    key: KEY_NAME,
  });
};

export const setNavigationPage = async (url: string) => {
  await Storage.set({
    key: KEY_LAST_NAVIGATION_PAGE,
    value: url,
  });
};

export const getLastNavigationPage = async () => {
  const { value } = await Storage.get({ key: KEY_LAST_NAVIGATION_PAGE });
  return value;
};

export const clearStore = async () => {
  await Storage.clear();
};
