import { useState } from 'react';
import { fetchFamily } from '../adapters/household-adapter';
import { getPatient } from '../adapters/user-profile-adapter';
import { IFamilyMember, IHousehold } from '../models/households';

export const useHousehold = () => {
  const [familyMembers, setFamilyMembers] = useState<IFamilyMember[]>([]);
  const [household, setHousehold] = useState<IHousehold>();
  const [loading, setLoading] = useState(true);

  const getFamilyMembers = async () => {
    getPatient().then((currentUser) => {
      const household_id = currentUser?.data?.data?.household_id || currentUser?.data?.data?.app_household_id;
      fetchFamily(household_id)
        .then((householdData: any) => {
          setHousehold(householdData?.data?.data);
          setFamilyMembers([...householdData?.data?.data?.membersData || [], ...householdData?.data?.data?.usersData || []]);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return { familyMembers, loading, getFamilyMembers, household };
};
