import { useEffect, useState } from 'react';
import { IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';
import { useRecoilState } from 'recoil';

import { UserHealthState } from '../../../../common/store-atom/user-health-atom';
import { Spinner } from '../../../../components/spinner/spinner';
import { getQuestionTitle } from '../../utils/utils';
import { SlideButtons } from '../buttons';
import { SliderBody } from '../slider-body';
import { SliderHeader } from '../slider-header';
import { ISliderProps } from '../slider';
import './styles.scss';

export const RadioQuestion = ({
  swiper,
  setActiveIndex,
  verifyLanguageSetting,
  question,
  index,
  isLast,
  isRepresentativeHRA,
}: ISliderProps) => {
  const [selected, setSelected] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userHealthState, setUserHealthState] = useRecoilState(UserHealthState);
  const lang = verifyLanguageSetting();

  const handleIonChange = (value: string) => {
    setSelected(value);
    saveSelections(value);
    setActiveIndex(question?.id);
  };

  const saveSelections = (value: string) => {
    setUserHealthState({ ...userHealthState, [question.id]: value });
  };

  useEffect(() => {
    setIsLoading(!Boolean(question));
  }, [question]);

  return (
    <>
      {isLoading ? (
        <Spinner isOpen={isLoading} />
      ) : (
        <div>
          <SliderBody>
            <div className="lc-slider-wrapper">
              <SliderHeader description={getQuestionTitle(question, lang)} />

              <IonRadioGroup value={selected} onIonChange={(e) => handleIonChange(e.detail.value)}>
                {question.options &&
                  question.options.map((element: any, index: number) => {
                    return (
                      <div
                        key={`${index}-hra-question`}
                        onClick={() => handleIonChange(element.value)}
                        className={selected === element.value ? 'lc-slider-radio-selected lc-slider-radio' : 'lc-slider-radio'}
                      >
                        <IonRadio className="lc-radio" slot="start" value={element.value} />
                        <IonLabel color="primary" className={selected === element.value ? 'lc-slider-label-selected' : ''}>
                          {lang === 'en' ? element.text_en : element.text_es}
                        </IonLabel>
                      </div>
                    );
                  })}
              </IonRadioGroup>
            </div>
          </SliderBody>
          <SlideButtons
            swiper={swiper}
            disabled={selected === null}
            showBackButton={index > 0}
            buttonFinish={isLast}
            isRepresentativeHRA={isRepresentativeHRA}
          />
        </div>
      )}
    </>
  );
};
