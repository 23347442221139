import React from 'react';
import { IonPage, IonContent, IonFooter, IonButton } from '@ionic/react';
import { TFunction } from 'react-i18next';

import { HRARegister } from '../../../../../common/models/hra-register';
import './styles.scss';

export const RepresentativeDoneView: React.FC<{
  t: TFunction;
  onSubmit(): void;
  demographics: HRARegister;
  nextStepMessage?: string;
}> = ({ t, onSubmit, demographics = {}, nextStepMessage = '' }) => {
  const { firstName = '', lastName = '' } = demographics;

  return (
    <IonPage>
      <IonContent className="HRA-done-content" fullscreen color="background">
        <div className="HRA-block-center">
          <p className="HRA-done-text">
            {t('HRA-done-text1')} {firstName} {lastName} {t('HRA-done-text2')}
          </p>
        </div>
        <div className="HRA-img-block">
          {nextStepMessage !== '' ? (
            <div className="HRA-next-step">
              Next step:&nbsp;
              <b>{nextStepMessage}</b>
            </div>
          ) : null}
          <img src="/assets/img/hra-done-img.svg" className="HRA-done-img" />
        </div>
      </IonContent>
      <IonFooter className="HRA-done-footer">
        <IonButton className="HRA-done-button" expand="block" onClick={onSubmit}>
          {t('HRA-done-button')}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
