import React from 'react';
import { Link } from 'react-router-dom';
import { IonText, IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import './styles.scss';

interface TopCardProps {
  message: string;
  redirectTo?: string;
}

const TopCard: React.FC<TopCardProps> = ({ message, redirectTo }) => {
  const { t } = useTranslation();

  return (
    <section className="top-card">
      <div>
        <span>{message}</span>
        {redirectTo && (
          <>
            <br />
            <Link to={redirectTo}>
              <IonText>
                <IonIcon icon={arrowBackOutline} />
                {t('forgotPassword.title_back')}
              </IonText>
            </Link>
          </>
        )}
      </div>
    </section>
  );
};

export default TopCard;
