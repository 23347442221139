import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { CheckInView } from '../pages/CheckInView';
import { checkInPoint } from '../../../common/models/check-in';
import { getCheckInData } from '../../../common/adapters/check-in';
import { ProfileState } from '../../../common/store-atom/profile-atom';

export const CheckInViewContainer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const profileState = useRecoilValue(ProfileState);
  const barberShop = profileState.barber_shop;
  const getData = async () => {
    setIsLoading(true);
    await getCheckInData(barberShop || 0)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  //remaining: inject response as prop to view component
  const mockData: checkInPoint = {
    shop_name: "Django's",
    journey_cards: [
      {
        title_en: 'The Journey',
        title_es: 'El viaje',
        detail_en: 'This would be a english detailed explanation of what would be going on here',
        detail_es: 'This would be a english detailed explanation of what would be going on here',
        reward_amount: '155',
        type: 'next_step',
      },
    ],
    next_card: {
      title_en: 'The next Journey Step',
      title_es: 'El siguiente paso seria',
    },
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <CheckInView
      isLoading={isLoading}
      shop_name={mockData.shop_name}
      journey_cards={mockData.journey_cards}
      next_card={mockData.next_card}
    />
  );
};
