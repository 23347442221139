import { useRecoilState, useSetRecoilState } from 'recoil';
import { Swiper as SwiperInterface } from 'swiper';
import { arrowForward, arrowBack } from 'ionicons/icons';
import { logEvent } from 'firebase/analytics';
import { useRecoilValue } from 'recoil';
import { uuid4 } from '@sentry/utils';
import { useHistory } from 'react-router-dom';

import { SlideButton } from './slide-button';
import { INIT_VALUE_PROGRESS_BAR, ProgressBarState } from '../../../../common/store-atom/progress-bar-atom';
import { UserHealthState } from '../../../../common/store-atom/user-health-atom';
import { saveUserHealth } from '../../../../common/adapters/User-healt-adapter';
import { LoadingState } from '../../../../common/store-atom/loading-atom';
import { analytics } from '../../../../common/firebase/firebase';
import { UserState } from '../../../../common/store-atom/user-atom';
import { User } from '../../../../common/models/user';
import { questionareCloseBottonState } from '../../../../common/store-atom/questionares-close-botton';
import { AxiosError } from 'axios';
import { MessageToastHooks } from '../../../../common/hooks/message-toast';
import { HRARegisterState } from '../../../../common/store-atom/hra-register-atom';
import { ActiveIndexState } from '../../../../common/store-atom/active-index-atom';
import { submitQuestionnaire } from '../../../../common/adapters/questionnaire-adapter';
import { REPRESENTATIVE_URL_DONE, URL_MAIN_HOME } from '../../../../common/constants/routers';
import './styles.scss';

interface ISlideButtonsProps {
  swiper: SwiperInterface | null;
  showBackButton?: boolean;
  showNextButton?: boolean;
  fillCancelButton?: string;
  callback?: Function;
  buttonFinish?: boolean;
  buttonBackHome?: boolean;
  disabled?: boolean;
  isRepresentativeHRA?: boolean;
}
export const SlideButtons: React.FC<ISlideButtonsProps> = ({
  swiper,
  showBackButton = true,
  showNextButton = true,
  buttonFinish = false,
  disabled = false,
  isRepresentativeHRA = false,
}: ISlideButtonsProps) => {
  const history = useHistory();
  const [, setProgressBarState] = useRecoilState(ProgressBarState);
  const [hraRegisterState] = useRecoilState(HRARegisterState);
  const [userHealthState] = useRecoilState(UserHealthState);
  const [, setShowQuestionnarieCloseButtonState] = useRecoilState(questionareCloseBottonState);
  const userSelector: User = useRecoilValue(UserState);
  const activeIndexState = useRecoilValue(ActiveIndexState);
  const setLoadingState = useSetRecoilState(LoadingState);
  const { messageToastError } = MessageToastHooks();

  const nextPage = async () => {
    logEvent(analytics, 'questionnaire_step_' + activeIndexState, {
      timeStamp: new Date(),
      userId: userSelector.uid,
    });
    swiper?.slideNext();
    incrementProgress();
  };

  const prevPage = async () => {
    swiper?.slidePrev();
    unIncrementProgress();
  };

  const incrementProgress = () => {
    setProgressBarState((prevState: number) => prevState + INIT_VALUE_PROGRESS_BAR);
  };

  const unIncrementProgress = () => {
    setProgressBarState((prevState: number) => prevState - INIT_VALUE_PROGRESS_BAR);
  };

  const saveQuestionaries = async () => {
    setLoadingState((prevState: boolean) => (prevState = !prevState));

    try {
      if (isRepresentativeHRA) {
        const questionnaireBody = {
          demographics: {
            ...hraRegisterState,
            registrationId: uuid4(),
          },
          answers: userHealthState,
        };

        const response = await submitQuestionnaire('LCH-HRA', questionnaireBody);

        setLoadingState(false);

        history.push(REPRESENTATIVE_URL_DONE, response.data.data.demographics);
        return;
      } else {
        await saveUserHealth(userHealthState);

        setShowQuestionnarieCloseButtonState(false);

        logEvent(analytics, 'questionnaire_completed', {
          timeStamp: new Date(),
          userId: userSelector.uid,
        });

        setLoadingState(false);

        history.replace(URL_MAIN_HOME);
      }
    } catch (error: any | AxiosError) {
      setLoadingState((prevState: boolean) => (prevState = !prevState));

      if (error.response) {
        messageToastError(error);
      } else {
        messageToastError(null, 'There was a problem saving your answer, try again in a few minutes');
      }
    }
  };

  return (
    <footer>
      {showBackButton && <SlideButton fill="outline" iconName={arrowBack} onClick={prevPage} />}
      {!buttonFinish && showNextButton && <SlideButton iconName={arrowForward} disabled={disabled} onClick={nextPage} />}
      {buttonFinish && <SlideButton disabled={disabled} iconName={arrowForward} onClick={saveQuestionaries} />}
    </footer>
  );
};
