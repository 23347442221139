import { useEffect, useState } from 'react';
import { IonCheckbox, IonLabel } from '@ionic/react';
import { useRecoilState } from 'recoil';

import { UserHealthState } from '../../../../common/store-atom/user-health-atom';
import { mapQuestionnaires, getQuestionTitle } from '../../utils/utils';
import { SlideButtons } from '../buttons';
import { SliderBody } from '../slider-body';
import { SliderHeader } from '../slider-header';
import { ISliderProps } from '../slider';
import './styles.scss';

export const MultipleCheckboxQuestion = ({
  swiper,
  setActiveIndex,
  verifyLanguageSetting,
  question,
  index,
  isLast,
  isRepresentativeHRA,
}: ISliderProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [options, setOptions] = useState<Array<string>>([]);
  const [userHealthState, setUserHealthState] = useRecoilState(UserHealthState);

  const handleDivChange = (element: any) => {
    if (element.value.toLowerCase() !== 'none') {
      const elementNone: any = options.find((x: any) => x.value.toLowerCase() == 'none');

      if (!elementNone.isChecked) {
        handleIonChange({ value: element.value, checked: !element.checked });
      }
    } else {
      handleIonChange({ value: element.value, checked: !element.checked });
    }
  };

  const handleIonChange = (conditional: any) => {
    const arrayList = [...options].map((element: any) => {
      if (element.value.toLowerCase() === conditional.value.toLowerCase()) {
        element.isChecked = conditional.checked;
      }

      if (
        conditional.value.toLowerCase() === 'none' &&
        conditional.checked &&
        element.value.toLowerCase() !== conditional.value.toLowerCase()
      ) {
        element.disabled = true;
        element.isChecked = false;
      }

      if (
        conditional.value.toLowerCase() === 'none' &&
        !conditional.checked &&
        element.value.toLowerCase() !== conditional.value.toLowerCase()
      ) {
        element.disabled = false;
      }

      return element;
    });

    setOptions(arrayList);
    saveSelections(arrayList);
    setActiveIndex(question?.id);
  };

  const setMapQuestion = () => {
    const arrayList = mapQuestionnaires(question.options || [], verifyLanguageSetting());
    setOptions(arrayList);
  };

  const saveSelections = (arrayList: Array<any>) => {
    const listFiltered = arrayList.filter((elem: any) => elem.isChecked).map((x) => x.value);
    setUserHealthState({ ...userHealthState, [question.id]: [...listFiltered] });
    setDisabled(!(listFiltered.length > 0));
  };

  useEffect(() => {
    if (question) {
      setMapQuestion();
    }
  }, [question]);

  return (
    <>
      <div>
        <SliderBody>
          <div className="lc-slider-wrapper">
            <SliderHeader description={question && getQuestionTitle(question, verifyLanguageSetting())} />

            {options &&
              options.map((element: any, index: number) => {
                return (
                  <div
                    onClick={() => handleDivChange({ value: element.value, checked: element.isChecked })}
                    className={element.isChecked ? 'lc-slider-checkbox-selected lc-slider-checkbox' : 'lc-slider-checkbox'}
                    key={index}
                  >
                    <IonCheckbox
                      disabled={element.disabled}
                      className="lc-checkbox"
                      checked={element.isChecked}
                      value={element.value}
                    />
                    <IonLabel color="primary" className={element.isChecked ? 'lc-slider-label-selected' : ''}>
                      {element.name}
                    </IonLabel>
                  </div>
                );
              })}
          </div>
        </SliderBody>

        <SlideButtons
          swiper={swiper}
          disabled={disabled}
          showBackButton={index > 0}
          buttonFinish={isLast}
          isRepresentativeHRA={isRepresentativeHRA}
        />
      </div>
    </>
  );
};
