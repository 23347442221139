import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';

import { URL_HEALTH_BIOMETRICS } from '../../../../../common/constants/routers';
import BlankCard from '../../../../../components/blank-card';

export const AddBiometrics = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <BlankCard options={{ description: t('biometric_card_content'), title: `❤️ ${t('track_your_health')}` }}>
      <IonButton type="button" onClick={() => history.push(URL_HEALTH_BIOMETRICS)}>
        {t('track_your_health')}
      </IonButton>
    </BlankCard>
  );
};
