import { IonPage, IonContent, IonFooter, IonButton, IonList, IonItem } from '@ionic/react';
import { TFunction } from 'react-i18next';

import { REPRESENTATIVE_URL_HOME } from '../../../../../common/constants/routers';
import { Spinner } from '../../../../../components/spinner/spinner';
import { CSRClient } from './RepresentativeUsersContainer';
import './styles.scss';

interface RepresentativeUsersViewProps {
  t: TFunction<'translation'>;
  clientsList: Array<CSRClient>;
  isLoading: boolean;
  history: any;
}

export const RepresentativeUsersView = ({ t, clientsList, isLoading, history }: RepresentativeUsersViewProps) => (
  <IonPage>
    <IonContent className="representative-users-content" fullscreen color="background">
      <h1 className="title">{t('representative-users-title')}</h1>
      <div className="list-container">
        <div className="row">
          <div className="list-item">
            <h2>Client</h2>
          </div>
          <div className="list-item">
            <h2>Next sep</h2>
          </div>
        </div>
        <Spinner isOpen={isLoading} />
        <IonList>
          {clientsList.map(({ client: { firstName, lastName, createdAt }, nextStep }, index) => (
            <IonItem key={index}>
              <div className="row item-native">
                <div className="list-item client">
                  <h3>
                    {firstName} {lastName}
                  </h3>
                  <span>{createdAt}</span>
                </div>
                <div className="list-item next-step">{!nextStep && 'All steps completed'}</div>
              </div>
            </IonItem>
          ))}
        </IonList>
      </div>
    </IonContent>
    <IonFooter className="representative-users-footer">
      <IonButton expand="block" onClick={() => history.push(REPRESENTATIVE_URL_HOME)}>
        {t('representative-users-button')}
      </IonButton>
    </IonFooter>
  </IonPage>
);
