import React, { useEffect } from 'react';

import { TabCarePage } from '.';
import { useHousehold } from '../../../common/hooks/useHousehold';

const CareTabView: React.FC = () => {
  const { familyMembers, getFamilyMembers, loading } = useHousehold();

  useEffect(() => {
    getFamilyMembers();
  }, []);

  return <TabCarePage loading={loading} familyMembers={familyMembers} />;
};

export default CareTabView;
