import React from 'react';

export const VerificationLogo = () => {
  return (
    <svg width="252" height="216" viewBox="0 0 252 216" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.45">
        <path
          opacity="0.45"
          d="M125.999 216C195.586 216 251.997 208.297 251.997 198.795C251.997 189.293 195.586 181.59 125.999 181.59C56.4115 181.59 0 189.293 0 198.795C0 208.297 56.4115 216 125.999 216Z"
          fill="#C2ADFD"
        />
      </g>
      <g opacity="0.58">
        <path
          opacity="0.58"
          d="M193.109 183.373C193.109 183.373 183.087 180.682 180.837 171.363C180.837 171.363 196.341 168.164 196.848 184.175L193.109 183.373Z"
          fill="#77D5A7"
        />
      </g>
      <g opacity="0.73">
        <path
          opacity="0.73"
          d="M194.336 182.375C194.336 182.375 187.292 171.338 193.412 160.973C193.412 160.973 205.233 168.45 200.055 182.375H194.336Z"
          fill="#77D5A7"
        />
      </g>
      <path
        d="M196.103 182.375C196.103 182.375 199.752 170.676 210.919 168.418C210.919 168.418 213.047 175.986 203.736 182.375H196.103Z"
        fill="#77D5A7"
      />
      <path d="M188.871 182.138L190.966 196.013H203.745L205.569 182.138H188.871Z" fill="#24285B" />
      <path d="M168.762 26.041H73.9908V196.014H168.762V26.041Z" fill="#C9C9C9" />
      <path d="M160.278 45.4219H81.8284V165.039H160.278V45.4219Z" fill="white" />
      <g opacity="0.56">
        <path
          opacity="0.56"
          d="M121.663 189.64C126.786 189.64 130.94 185.486 130.94 180.362C130.94 175.239 126.786 171.085 121.663 171.085C116.539 171.085 112.385 175.239 112.385 180.362C112.385 185.486 116.539 189.64 121.663 189.64Z"
          fill="white"
        />
      </g>
      <g opacity="0.08">
        <path
          opacity="0.08"
          d="M109.538 91.1222L125.082 93.4457L124.6 94.4846C123.044 97.985 122.154 101.744 121.973 105.57C121.851 107.427 120.795 109.972 117.294 112.933C111.313 118.038 107.476 134.45 99.5325 140.864C97.1827 142.823 95.276 145.259 93.9389 148.011C92.6018 150.763 91.8648 153.767 91.7768 156.825L90.2304 195.981H74.8335L78.2368 136.102L82.3274 115.437L92.2921 105.21L101.422 93.4211L105.578 91.0977L109.538 91.1222Z"
          fill="black"
        />
      </g>
      <g opacity="0.12">
        <path opacity="0.12" d="M139.931 32.6836H113.359V36.5615H139.931V32.6836Z" fill="black" />
      </g>
      <path
        d="M103.729 39.3676C106.12 39.3676 108.057 37.43 108.057 35.0398C108.057 32.6496 106.12 30.7119 103.729 30.7119C101.339 30.7119 99.4016 32.6496 99.4016 35.0398C99.4016 37.43 101.339 39.3676 103.729 39.3676Z"
        fill="white"
      />
      <path d="M82.311 113.4L97.2254 94.8037L100.441 97.6262L87.7352 118.889L82.311 113.4Z" fill="#655DC6" />
      <path d="M57.6038 82.7773L60.3527 91.0403L53.8159 95.1882L51.4679 80.8711L57.6038 82.7773Z" fill="#F4A28C" />
      <path
        d="M49.2263 72.8952C49.565 72.1079 50.0952 71.4178 50.7688 70.8879C51.4424 70.358 52.2379 70.005 53.0828 69.8611C53.9277 69.7171 54.7952 69.7868 55.6062 70.0638C56.4173 70.3409 57.1461 70.8164 57.7264 71.4471C60.508 74.4168 63.8706 79.1374 60.0745 81.7145C54.2577 85.6496 48.9398 78.2947 48.9398 78.2947C48.4975 76.5063 48.5973 74.6268 49.2263 72.8952V72.8952Z"
        fill="#F4A28C"
      />
      <path
        d="M53.4724 77.9582C53.4724 77.9582 51.9835 76.7147 51.3045 78.4818C50.6254 80.2489 53.2597 81.0998 54.045 80.2081C54.8304 79.3163 53.4724 77.9582 53.4724 77.9582Z"
        fill="#F4A28C"
      />
      <path
        d="M46.1912 99.2462C47.1794 97.0597 48.6268 95.1118 50.4352 93.5349C52.2436 91.9579 54.3705 90.7891 56.6712 90.1078C65.5478 87.4408 81.9021 90.3614 86.8845 98.6735C93.7076 110.037 83.7183 146.124 83.7183 146.124L53.8733 148.497C53.8733 148.497 37.5028 118.857 46.1912 99.2462Z"
        fill="#655DC6"
      />
      <path d="M83.7182 146.149L79.7912 197.454H73.5899L68.0431 157.595L54.3232 148.465L83.7182 146.149Z" fill="#24285B" />
      <path d="M54.3232 148.465L45.7656 197.454H52.4169L66.7913 156.752L54.3232 148.465Z" fill="#24285B" />
      <g opacity="0.08">
        <path
          opacity="0.08"
          d="M59.2483 89.3145C59.2483 89.3145 45.2911 134.311 85.5999 137.248L83.7182 146.182L53.8732 148.497C53.8732 148.497 43.3603 131.979 43.8021 108.786C43.8021 108.786 44.064 97.3321 52.1061 92.2515C54.2496 90.7734 56.685 89.7719 59.2483 89.3145V89.3145Z"
          fill="black"
        />
      </g>
      <path
        d="M47.3283 197.348L43.3114 200.301C43.1259 200.445 43.0045 200.656 42.9738 200.889C42.9432 201.122 43.0056 201.358 43.1476 201.545C43.2243 201.655 43.3273 201.745 43.4472 201.807C43.5671 201.868 43.7002 201.899 43.8349 201.896H50.5516C50.6677 201.896 50.7826 201.874 50.8898 201.829C50.997 201.785 51.0943 201.72 51.1764 201.638C51.2584 201.556 51.3235 201.458 51.3679 201.351C51.4123 201.244 51.4353 201.129 51.4353 201.013V197.348H47.3283Z"
        fill="#655DC6"
      />
      <path
        d="M79.0304 197.347L82.8102 200.3C82.9957 200.444 83.1169 200.656 83.1476 200.889C83.1782 201.121 83.1157 201.357 82.9738 201.544C82.8908 201.654 82.7832 201.743 82.6596 201.804C82.536 201.865 82.3998 201.897 82.2619 201.896H77.476C76.7983 201.907 76.1437 201.649 75.6561 201.178C75.1684 200.707 74.8872 200.062 74.8743 199.384V197.339L79.0304 197.347Z"
        fill="#655DC6"
      />
      <path
        d="M71.4136 89.8623C69.9261 92.2479 69.1375 95.003 69.1375 97.8144C69.1375 100.626 69.9261 103.381 71.4136 105.767C74.6861 111.264 84.1436 121.965 90.6149 129.018C91.3452 129.819 92.2556 130.434 93.271 130.814C94.2864 131.194 95.3777 131.326 96.4544 131.201C97.5311 131.075 98.5626 130.695 99.4634 130.092C100.364 129.489 101.108 128.681 101.635 127.733L115.543 103.189L110.823 100.162L98.0762 118.848C98.0762 118.848 89.355 90.2141 71.4136 89.8623Z"
        fill="#655DC6"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M71.4136 89.8623C69.9261 92.2479 69.1375 95.003 69.1375 97.8144C69.1375 100.626 69.9261 103.381 71.4136 105.767C74.6861 111.264 84.1436 121.965 90.6149 129.018C91.3452 129.819 92.2556 130.434 93.271 130.814C94.2864 131.194 95.3777 131.326 96.4544 131.201C97.5311 131.075 98.5626 130.695 99.4634 130.092C100.364 129.489 101.108 128.681 101.635 127.733L115.543 103.189L110.823 100.162L98.0762 118.848C98.0762 118.848 89.355 90.2141 71.4136 89.8623Z"
          fill="white"
        />
      </g>
      <path
        d="M113.899 102.175C113.899 102.175 121.794 99.2788 118.57 94.8118C115.347 90.3449 110.823 100.203 110.823 100.203L113.899 102.175Z"
        fill="#F4A28C"
      />
      <path d="M113.955 86.0037L109.306 96.3818L116.422 99.5692L121.07 89.1911L113.955 86.0037Z" fill="#FFD200" />
      <path
        d="M51.9017 83.4971L47.4673 78.9238C47.0117 78.4455 46.6573 77.8802 46.4253 77.2616C46.1934 76.6431 46.0887 75.984 46.1175 75.3241C46.2321 73.0006 46.7312 69.2945 48.6128 66.5048C51.6317 62.136 55.6649 60.4834 56.663 64.2549C57.6611 68.0265 57.874 63.5513 60.3038 65.5557C62.7336 67.5601 62.2427 68.7464 60.3038 69.8099C58.3648 70.8735 54.3559 72.4115 54.0041 75.6186C53.6523 78.8256 56.7204 81.3536 51.9017 83.4971Z"
        fill="#24285B"
      />
      <path
        d="M97.978 95.4743C97.978 95.4743 98.9679 88.6593 105.578 89.3138C112.189 89.9683 109.767 91.6536 107.108 91.7682C106.566 91.8839 106.064 92.142 105.654 92.5158C105.244 92.8896 104.942 93.3658 104.777 93.8953C104.392 95.0898 103.32 96.636 100.473 97.6178L97.978 95.4743Z"
        fill="#F4A28C"
      />
      <path
        d="M169.4 62.8643C186.759 62.8643 200.832 48.7917 200.832 31.4322C200.832 14.0727 186.759 0 169.4 0C152.04 0 137.968 14.0727 137.968 31.4322C137.968 48.7917 152.04 62.8643 169.4 62.8643Z"
        fill="#77D5A7"
      />
      <path
        d="M166.741 46.8294H166.61C165.905 46.8065 165.217 46.6044 164.612 46.2423C164.006 45.8802 163.503 45.3699 163.149 44.7596L156.392 33.1423C155.884 32.1926 155.764 31.0828 156.059 30.0468C156.353 29.0107 157.038 28.1294 157.969 27.5885C158.9 27.0475 160.005 26.8892 161.051 27.1469C162.097 27.4046 163.002 28.0582 163.575 28.9699L166.986 34.8522L177.393 19.3079C178.006 18.3933 178.957 17.7597 180.037 17.5464C181.117 17.3331 182.238 17.5577 183.152 18.1707C184.067 18.7837 184.701 19.7348 184.914 20.8149C185.127 21.8951 184.902 23.0157 184.29 23.9302L170.193 45.005C169.812 45.5692 169.297 46.0307 168.695 46.3489C168.093 46.6671 167.422 46.8321 166.741 46.8294Z"
        fill="white"
      />
    </svg>
  );
};
