import { useState, useRef, useEffect, useContext } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  IonCheckbox,
  IonLabel,
  IonRouterLink,
} from '@ionic/react';
import InputMask from 'react-input-mask';
import { useRecoilValue } from 'recoil';
import { Address } from '@live-chair-health/lch-core';

import { ItemFormGroup } from '../../../components/item-form-group/item-form-group';
import { InputText } from '../../../components/inputs/input-text';
import { InputTextLocation } from '../../../components/inputs/input-text-location';
import { Spinner } from '../../../components/spinner/spinner';
import { URL_TERMS_CONDITIONS } from '../../../common/constants/routers';
import { OptionsModel } from '../../../common/models/option-model';
import { placeToAddress } from '../../../common/utils/place-to-address';
import { options } from '../../../common/utils/location-options';
import { InputNumber } from '../../../components/inputs/input-number';
import { FlagsContext } from '../../../common/firebase/flags-providers';
import { addressToString } from '../../../common/utils/address-to-string';
import { phoneNumberState } from '../../../common/store-atom/register-atom';
import '../styles.scss';

type ProfileRegisterViewParams = {
  t: any;
  isClient?: boolean;
  isDefaultRegister?: boolean;
  isLoadingHairProfessional?: boolean;
  isRepresentativeHRA?: boolean;
  isFromShop?: boolean;
  isLoadingBarberShops?: boolean;
  isLoadingRepresentatives?: boolean;
  isLoading?: boolean;
  barberShops?: OptionsModel[];
  lchRepresentatives?: OptionsModel[];
  hairProfessional?: OptionsModel[];
  setBarberShop?: (barber_shop: string | number) => void;
  goBack?: () => void;
  register: any;
  errors: any;
  onSubmit: any;
  handleSubmit: any;
  comesFromUniqueLink?: boolean;
};

export const ProfileRegisterView = ({
  t,
  isLoadingHairProfessional = false,
  isRepresentativeHRA = false,
  isLoadingBarberShops = false,
  isLoadingRepresentatives = false,
  isLoading = false,
  register,
  errors,
  onSubmit,
  handleSubmit,
  comesFromUniqueLink = false,
}: ProfileRegisterViewParams) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [parsedAddress, setParsedAddress] = useState<Address>({});
  const [renderZipCode, setRenderZipCode] = useState<boolean>(false);
  const autoCompleteRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const phoneNumber = useRecoilValue(phoneNumberState);

  const flags = useContext(FlagsContext);

  const { ref, ...rest } = register('address');

  useEffect(() => {
    if (window.google) {
      // @ts-ignore
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

      // @ts-ignore
      autoCompleteRef.current.addListener('place_changed', async function () {
        // @ts-ignore
        const place = await autoCompleteRef.current.getPlace();
        const addressObj = placeToAddress(place);
        setParsedAddress({
          ...addressObj,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          placeID: place.place_id,
          ...(addressObj && {
            formattedAddress: addressToString(addressObj),
          }),
        });
      });
    }
  }, [window.google]);

  useEffect(() => {
    setRenderZipCode(flags?.isAddressAZipCode || false);
  }, [flags]);

  const handleTermsAndConditionsChange = (val: boolean) => {
    setChecked(val);
  };

  return (
    <IonPage>
      <IonContent fullscreen class="lc-content-footer register-profile">
        <Spinner isOpen={isLoadingRepresentatives || isLoadingBarberShops || isLoadingHairProfessional || isLoading} />

        <IonItem lines="none">
          <IonText color="primary">
            <h2>{t(isRepresentativeHRA ? 'page_profile_register.hra_header.title' : 'register.header.title')}</h2>
          </IonText>
        </IonItem>

        <form
          onSubmit={handleSubmit(
            (data) => {
              return renderZipCode ? onSubmit(data) : onSubmit(data, parsedAddress);
            },
            (error) => {
              console.log('VALIDATION ERROR: ', error);
            }
          )}
          noValidate
        >
          <IonGrid>
            {/* First Name and Last Name */}
            <IonRow>
              <IonCol size="6">
                <ItemFormGroup label={t('page_profile_register.label_first_name')} errorMessage={errors.first_name?.message}>
                  <InputText name="first_name" register={register} />
                </ItemFormGroup>
              </IonCol>
              <IonCol size="6">
                <ItemFormGroup label={t('page_profile_register.label_last_name')} errorMessage={errors.last_name?.message}>
                  <InputText name="last_name" register={register} />
                </ItemFormGroup>
              </IonCol>
            </IonRow>
            {/* Email */}
            {isRepresentativeHRA || comesFromUniqueLink && (
              <IonRow>
                <IonCol>
                  <ItemFormGroup label={t('page_profile_register.label_email')} errorMessage={errors.email?.message}>
                    <InputText name="email" type="email" register={register} />
                  </ItemFormGroup>
                </IonCol>
              </IonRow>
            )}
            {/* Phone Number */}
            {!comesFromUniqueLink &&
              <IonRow>
                <IonCol>
                  {phoneNumber.phone ? (
                    <ItemFormGroup label={t('register_email.label_email')} errorMessage={errors.email?.message}>
                      <input type="email" name="email" className="lc-input" {...register('email')} />
                    </ItemFormGroup>
                  ) : (
                    <ItemFormGroup label={t('page_profile_register.label_cell_phone')} errorMessage={errors.phoneNumber?.message}>
                      <InputMask className="lc-input" mask="(999) 999 9999" {...register('phones')} />
                    </ItemFormGroup>
                  )}
                </IonCol>
              </IonRow>
            }
            {/* Address */}
            {!comesFromUniqueLink &&
              <IonRow>
                <IonCol>
                  {renderZipCode ? (
                    <ItemFormGroup label={t('page_profile_register.label_zip_code')} errorMessage={errors.zipCode?.message}>
                      <InputNumber name="zipCode" maxLength={5} register={register} />
                    </ItemFormGroup>
                  ) : (
                    <ItemFormGroup
                      label={t('page_profile_register.label_address')}
                      errorMessage={errors.address?.message}
                      helperText={t('page_profile_register.field_address_helper_text')}
                    >
                      <InputTextLocation passedRef={ref} rest={rest} inputRef={inputRef} />
                    </ItemFormGroup>
                  )}
                </IonCol>
              </IonRow>
            }
            {/* Date of birth */}
            {!comesFromUniqueLink &&
              <IonRow>
                <IonCol>
                  <ItemFormGroup label={t('page_profile_register.label_date_of_birth')} errorMessage={errors.date_of_birth?.message}>
                    <IonDatetime displayFormat="MM/DD/YYYY" {...register('date_of_birth')} />
                  </ItemFormGroup>
                </IonCol>
              </IonRow>
            }
            {/* Finish Button */}
            <IonRow className="lc-footer">
              <IonCol>
                <IonButton type="submit" expand="block">
                  Finish
                </IonButton>
              </IonCol>
            </IonRow>
            {/* Terms and conditions */}
            <IonRow>
              <IonCol data-public>
                <IonCheckbox
                  {...register('termsAndConditions')}
                  checked={checked}
                  onIonChange={(e) => handleTermsAndConditionsChange(e.detail.checked)}
                  name="termsAndConditions"
                  className="terms-check-box"
                  value={checked}
                />
                <IonLabel>
                  <span className="terms-text">{t('register.title_partners_clients_LCH')}</span>
                  <IonRouterLink routerLink={URL_TERMS_CONDITIONS} className="lc-underline">
                    {t('register.title_accept_terms_and_conditions')}
                  </IonRouterLink>
                </IonLabel>
                {errors.termsAndConditions && <p className="terms-error">{errors.termsAndConditions?.message}</p>}
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};
