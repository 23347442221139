/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonIcon, IonInput } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface IInputPasswordProps {
  name: string;
  errorMessage?: string | null;
  register: UseFormRegister<any>;
  callback?: (value: string | null | undefined) => void;
}
export const InputPassword = ({ name, errorMessage, register, callback }: IInputPasswordProps) => {
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(true);

  const changeTypeInput = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleOnChange = (value: string | null | undefined) => {
    if (callback) callback(value);
  };

  return (
    <div className="lc-input-password">
      {/* @ts-ignore */}
      <IonInput
        color="primary"
        type={isPasswordShown ? 'password' : 'text'}
        {...register(name)}
        autocomplete="off"
        onIonChange={(e) => handleOnChange(e.detail.value)}
      />
      <IonIcon icon={!isPasswordShown ? eyeOutline : eyeOffOutline} onClick={changeTypeInput} />
      <span>{errorMessage}</span>
    </div>
  );
};
