import { IonButton, IonIcon } from '@ionic/react';

interface ISlideButtonProps {
  onClick: () => void;
  iconName?: string;
  fill?: 'default' | 'clear' | 'outline' | 'solid' | undefined;
  disabled?: boolean;
}

export const SlideButton = ({ iconName, onClick, fill = 'solid', disabled = false }: ISlideButtonProps) => {
  return (
    <IonButton
      size="default"
      expand="block"
      disabled={disabled}
      color={disabled ? 'medium' : 'primary'}
      fill={fill}
      onClick={onClick}
    >
      <IonIcon slot="icon-only" icon={iconName} />
    </IonButton>
  );
};
