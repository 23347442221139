
export const RepresentativeHomeIllustration = () => (
    <svg width="375" height="368" viewBox="0 0 375 368" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.45" d="M188 359C291.277 359 375 352.06 375 343.5C375 334.94 291.277 328 188 328C84.7227 328 1 334.94 1 343.5C1 352.06 84.7227 359 188 359Z" fill="#C2ADFD" />
        <path d="M93.6395 241.496L81.7625 279.352C80.1632 284.458 77.526 289.178 74.0162 293.217C70.5063 297.257 66.1995 300.528 61.3657 302.826L43.9781 311.1L44.6736 316.233L70.7014 310.619C76.8067 308.812 82.4682 305.754 87.325 301.639C92.1817 297.523 96.127 292.442 98.9093 286.718L117.287 248.901L93.6395 241.496Z" fill="#A36554" />
        <path d="M319.838 125.332H188.669V343.396H319.838V125.332Z" fill="#655DC6" />
        <path d="M308.548 39.9337L179.119 61.2095L187.302 110.937L316.732 89.661L308.548 39.9337Z" fill="#655DC6" />
        <path d="M280.986 30.7752L203.736 43.2529L206.067 57.6645L283.316 45.1868L280.986 30.7752Z" fill="#C2ADFD" />
        <path opacity="0.07" d="M296.351 190.285H211.915C205.052 190.285 199.489 195.845 199.489 202.703V212.742C199.489 219.601 205.052 225.16 211.915 225.16H296.351C303.213 225.16 308.776 219.601 308.776 212.742V202.703C308.776 195.845 303.213 190.285 296.351 190.285Z" fill="white" />
        <path d="M288.848 217.1C294.004 217.1 298.183 212.923 298.183 207.769C298.183 202.616 294.004 198.439 288.848 198.439C283.692 198.439 279.512 202.616 279.512 207.769C279.512 212.923 283.692 217.1 288.848 217.1Z" fill="#C2ADFD" />
        <path d="M265.695 203.332H210.684V212.194H265.695V203.332Z" fill="white" />
        <path opacity="0.07" d="M296.351 139.488H211.915C205.052 139.488 199.489 145.048 199.489 151.907V161.946C199.489 168.804 205.052 174.364 211.915 174.364H296.351C303.213 174.364 308.776 168.804 308.776 161.946V151.907C308.776 145.048 303.213 139.488 296.351 139.488Z" fill="white" />
        <path d="M288.848 166.304C294.004 166.304 298.183 162.126 298.183 156.973C298.183 151.82 294.004 147.643 288.848 147.643C283.692 147.643 279.512 151.82 279.512 156.973C279.512 162.126 283.692 166.304 288.848 166.304Z" fill="#C2ADFD" />
        <path d="M265.695 152.535H210.684V161.398H265.695V152.535Z" fill="white" />
        <path opacity="0.07" d="M287.62 52.3873L204.028 65.8895C197.234 66.9869 192.617 73.3808 193.715 80.1707L195.322 90.1093C196.42 96.8992 202.817 101.514 209.611 100.417L293.203 86.9143C299.997 85.8169 304.614 79.4231 303.516 72.6331L301.909 62.6946C300.811 55.9047 294.413 51.2899 287.62 52.3873Z" fill="white" />
        <path d="M282.066 79.2142C287.222 79.2142 291.402 75.0368 291.402 69.8837C291.402 64.7306 287.222 60.5532 282.066 60.5532C276.91 60.5532 272.731 64.7306 272.731 69.8837C272.731 75.0368 276.91 79.2142 282.066 79.2142Z" fill="#C2ADFD" />
        <path d="M258.494 69.2504L204.212 78.1733L205.651 86.9185L259.933 77.9956L258.494 69.2504Z" fill="white" />
        <path opacity="0.07" d="M296.351 243.287H211.915C205.052 243.287 199.489 248.847 199.489 255.705V265.744C199.489 272.602 205.052 278.162 211.915 278.162H296.351C303.213 278.162 308.776 272.602 308.776 265.744V255.705C308.776 248.847 303.213 243.287 296.351 243.287Z" fill="white" />
        <path d="M288.848 270.102C294.004 270.102 298.183 265.924 298.183 260.771C298.183 255.618 294.004 251.441 288.848 251.441C283.692 251.441 279.512 255.618 279.512 260.771C279.512 265.924 283.692 270.102 288.848 270.102Z" fill="#C2ADFD" />
        <path d="M265.695 256.333H210.684V265.196H265.695V256.333Z" fill="white" />
        <path opacity="0.07" d="M296.351 296.289H211.915C205.052 296.289 199.489 301.849 199.489 308.707V318.746C199.489 325.605 205.052 331.165 211.915 331.165H296.351C303.213 331.165 308.776 325.605 308.776 318.746V308.707C308.776 301.849 303.213 296.289 296.351 296.289Z" fill="white" />
        <path d="M288.848 323.104C294.004 323.104 298.183 318.926 298.183 313.773C298.183 308.62 294.004 304.443 288.848 304.443C283.692 304.443 279.512 308.62 279.512 313.773C279.512 318.926 283.692 323.104 288.848 323.104Z" fill="#C2ADFD" />
        <path d="M265.695 309.335H210.684V318.198H265.695V309.335Z" fill="white" />
        <path d="M132.882 252.176L138.446 333.25L133.27 335.041L112.672 259.194L132.882 252.176Z" fill="#A36554" />
        <path d="M139.502 334.64C139.502 334.64 140.626 339.439 144.25 340.936C147.875 342.433 146.043 347.62 140.586 344.478C138.889 343.492 137.279 342.365 135.771 341.11C134.353 340.001 132.67 339.282 130.889 339.024C130.556 338.964 130.25 338.804 130.011 338.565C129.772 338.326 129.611 338.02 129.551 337.688C128.816 335.014 134.834 332.528 134.834 332.528L139.502 334.64Z" fill="#C2ADFD" />
        <path d="M44.6603 316.233C44.6603 316.233 40.9955 319.04 41.1025 322.703C41.2095 326.366 36.0468 326.807 36.6754 320.939C36.8643 319.131 37.2043 317.342 37.6919 315.592C38.1024 313.96 38.0701 312.248 37.5983 310.632C37.5325 310.316 37.5637 309.987 37.6878 309.689C37.8118 309.391 38.0231 309.137 38.2938 308.961C40.2867 307.33 44.7405 311.501 44.7405 311.501L44.6603 316.233Z" fill="#C2ADFD" />
        <path d="M101.838 134.663L103.764 142.456L97.438 145.798L96.3279 132.457L101.838 134.663Z" fill="#A36554" />
        <path d="M94.8432 124.944C95.2164 124.246 95.7579 123.653 96.4186 123.216C97.0794 122.78 97.8385 122.516 98.6273 122.447C99.416 122.378 100.21 122.506 100.936 122.821C101.663 123.136 102.299 123.627 102.788 124.249C105.129 127.19 107.87 131.788 104.125 133.874C98.4946 137.069 94.1209 129.864 94.1209 129.864C93.8802 128.188 94.1311 126.48 94.8432 124.944Z" fill="#A36554" />
        <path d="M30.168 174.178L58.3933 210.323L83.9413 190.396L55.716 154.25L30.168 174.178Z" fill="#FFC833" />
        <path d="M68.8689 159.112C68.8689 159.112 41.6375 201.674 47.0811 207.943C64.3883 227.834 102.815 183.026 102.815 183.026L68.8689 159.112Z" fill="#A36554" />
        <path d="M130.474 152.762C122.659 147.058 113.273 143.904 103.598 143.729C93.9223 143.555 84.4286 146.368 76.4125 151.786C69.2836 156.639 63.7865 163.817 66.301 174.377C72.1993 198.974 91.0581 215.188 91.7135 236.83L136.359 233.836V205.978C136.348 203.343 136.883 200.735 137.93 198.317C138.977 195.899 140.515 193.724 142.445 191.929C146.337 188.346 150.202 182.505 147.554 174.471C145.147 167.346 139.061 159.058 130.474 152.762Z" fill="#C2ADFD" />
        <path d="M98.3876 129.917C98.3876 129.917 97.0501 128.661 96.3546 130.238C95.6591 131.815 97.9596 132.791 98.7487 132.029C99.5379 131.267 98.3876 129.917 98.3876 129.917Z" fill="#A36554" />
        <path opacity="0.08" d="M108.379 164.352C108.379 164.352 98.7353 182.785 109.93 200.751C121.125 218.717 99.8989 236.282 99.8989 236.282L136.306 233.836V217.955C136.306 217.955 134.059 197.904 141 193.346C141 193.346 152.877 184.791 146.484 171.837C140.091 158.884 108.379 164.352 108.379 164.352Z" fill="black" />
        <path d="M89.7875 153.377C90.6435 150.049 94.6159 144.02 89.0118 133.072C87.8348 130.759 86.8985 125.346 89.1322 121.603C94.4822 112.633 103.015 115.694 101.999 120.72C100.982 125.747 100.274 128.139 100.274 128.139C100.274 128.139 116.484 139.756 114.117 148.324C112.017 155.877 86.1763 167.199 89.7875 153.377Z" fill="#230870" />
        <path d="M89.6938 225.441H136.306L143.729 289.899L74.5801 286.785L89.6938 225.441Z" fill="#230870" />
        <path d="M110.225 177.438C109.123 176.1 108.299 174.557 107.799 172.897C107.299 171.238 107.134 169.496 107.313 167.772C107.492 166.049 108.012 164.378 108.843 162.857C109.673 161.336 110.797 159.994 112.151 158.911C114.846 156.807 117.699 154.912 120.684 153.243C138.459 143.071 168.005 120.586 180.443 89.5739C180.443 89.5739 183.359 78.2383 192.013 78.6393C200.666 79.0403 197.684 89.7878 189.07 89.6808C189.07 89.6808 182.022 156.973 130.3 182.01C126.927 183.602 123.116 184.017 119.479 183.188C115.841 182.36 112.587 180.335 110.238 177.438H110.225Z" fill="#A36554" />
        <path d="M77.75 65.1382V89.6942H93.6261L97.1036 99.4659L101.21 89.6942H117.086V65.1382H77.75Z" fill="#C2ADFD" />
        <path opacity="0.12" d="M95.4317 82.9036C95.2542 82.8946 95.0807 82.8469 94.9236 82.7638C94.7665 82.6806 94.6295 82.5641 94.5222 82.4223L90.3626 77.0753C90.1785 76.8172 90.1013 76.4979 90.147 76.1841C90.1928 75.8704 90.3581 75.5865 90.6083 75.3916C90.8585 75.1967 91.1744 75.1059 91.4899 75.1382C91.8055 75.1705 92.0964 75.3233 92.302 75.5648L95.5655 79.7622L103.59 71.0733C103.7 70.9524 103.832 70.8543 103.979 70.7847C104.126 70.7152 104.286 70.6756 104.449 70.6682C104.612 70.6609 104.774 70.686 104.927 70.7419C105.08 70.7979 105.221 70.8838 105.34 70.9944C105.46 71.1051 105.556 71.2384 105.624 71.3866C105.692 71.5348 105.729 71.6949 105.734 71.8577C105.739 72.0205 105.712 72.1827 105.654 72.3349C105.596 72.4871 105.508 72.6263 105.396 72.7443L96.3412 82.5293C96.2231 82.6502 96.0814 82.7457 95.9251 82.8101C95.7687 82.8744 95.6008 82.9062 95.4317 82.9036Z" fill="black" />
    </svg>
)