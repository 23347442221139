import { atom } from 'recoil';
import { HRALanguageType, HRARegister } from '../models/hra-register';

const INIT_VALUE: HRARegister = {
  birthday: null,
  cellPhone: null,
  createdAt: null,
  email: null,
  firstName: null,
  language: HRALanguageType.EN,
  lastName: null,
  zipCode: null,
  address: null,
};

export const HRARegisterState = atom<HRARegister>({
  key: 'hraRegisterState',
  default: INIT_VALUE,
});
