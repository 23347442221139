import { atom } from 'recoil';

import { UserHealth } from '../models/user-health';

export const INIT_VALUE_USER_HEALTH: UserHealth = {};

export const UserHealthState = atom<UserHealth>({
  key: 'userHealth',
  default: INIT_VALUE_USER_HEALTH,
});
