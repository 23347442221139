/* eslint-disable no-param-reassign */
import { AxiosError, AxiosInstance } from 'axios';
import { AuthError } from 'firebase/auth';
import { URL_500_INTERNAL_ERROR_PAGE, URL_LOGIN } from '../constants/routers';
import { auth } from '../firebase/firebase';
import { getNewToken } from '../firebase/session-management';
import { clearStore } from '../storage';

export const handleError = async (instance: AxiosInstance, error: AxiosError) => {
  if (auth.currentUser == null) {
    await clearStore();
    window.location.href = URL_LOGIN;
  }

  return auth.currentUser?.getIdToken(true).then(
    async () => {
      const token = getNewToken();
      error.config.headers.Authorization = `Bearer ${token}`;
      return instance.request(error.config);
    },
    () => {
      window.location.href = URL_LOGIN;
    }
  );
};

export const handleErrorRequest = (error: AxiosError) => {
  const networkError = 'There was a problem creating your request, try again in a few minutes';

  if (!error.response || error.response.status === 404) {
    error.message = networkError;
  }

  if (error?.response?.status == 500) {
    return (window.location.href = URL_500_INTERNAL_ERROR_PAGE);
  }
  return Promise.reject(error);
};

export const handleErrorPassword = (message: string) => {
  const wrongOldPassword = 'The password is invalid or the user does not have a password.';
  const messageOldPassword = 'The old password is incorrect.';

  return message === wrongOldPassword ? messageOldPassword : message;
};

export const isEmailOrPasswordInvalid = (error: AuthError): boolean => {
  const { message } = error;
  return message?.includes('auth/wrong-password') || message?.includes('auth/user-not-found');
};
