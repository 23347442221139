import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getOrganizations } from '../../../../../common/adapters/csr-adapter';
import { RepresentativeOrganizationsView } from './RepresentativeOrganizationsView';

export interface CSROrganization {
  id: string;
  name: string;
  createdAt: string;
  address: string;
  type: string;
}

export const RepresentativeOrganizationsContainer = () => {
  const { t } = useTranslation();
  const { isLoading, data, isSuccess } = useQuery('getOrganizations', getOrganizations);

  const organizationsList = useMemo<Array<CSROrganization>>(() => {
    if (!isSuccess) return [];

    return data?.data.data.map(({ id, name, type, address }) => {
      return {
        id,
        name,
        type,
        address: address?.formatted_address ?? '',
        createdAt: '',
      };
    });
  }, [isSuccess, data]);

  return <RepresentativeOrganizationsView t={t} organizationsList={organizationsList} isLoading={isLoading} />;
};
