import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIonToast } from '@ionic/react';
import { CellphoneVerificationView } from './CellphoneVerificationView';
import { getCellphoneNumberVerificationCode } from '../../../common/adapters/user-profile-adapter';
import { URL_PHONE_VERIFICATION_VERIFY_CODE } from '../../../common/constants/routers';

export const CellphoneVerificationPageContainer: React.FC = () => {
  const [present, dismiss] = useIonToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const redirectToCodeVerification = () => {
    return history.replace(URL_PHONE_VERIFICATION_VERIFY_CODE);
  };

  const onError = (error) => {
    setIsLoading(false);
    present({
      color: 'danger',
      buttons: [{ text: 'Ok', handler: () => dismiss() }],
      message: error.response.data.message ? error.response.data.message : error.response.data.exceptionMessage,
      duration: 2500,
    });
  };

  const onSuccess = (response) => {
    setIsLoading(false);
    present({
      color: 'success',
      buttons: [{ text: 'Ok', handler: () => dismiss() }],
      message: response.data.message,
      duration: 2500,
    });
    redirectToCodeVerification();
  };

  const createVerificationCode = () => {
    setIsLoading(true);
    getCellphoneNumberVerificationCode().then(onSuccess).catch(onError);
  };

  return <CellphoneVerificationView createVerificationCode={createVerificationCode} isLoading={isLoading} />;
};
