/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { HOST_NAME_CLIENTS, HOST_NAME_REPRESENTATIVES, HOST_NAME_PARTNERS } from '../constants/base-url';

export const UserAccess = () => {
  const [isClient, setIsClient] = useState<boolean>(false);
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const [isRepresentativeHRA, setIsRepresentativeHRA] = useState<boolean>(false);

  useEffect(() => {
    const { hostname } = window.location;
    const isClientHost = hostname.includes(HOST_NAME_CLIENTS);
    const isPartnersHost = hostname.includes(HOST_NAME_PARTNERS);
    const isRepresentativeHRAsHost = hostname.includes(HOST_NAME_REPRESENTATIVES);
    setIsClient(isClientHost);
    setIsPartner(isPartnersHost);
    setIsRepresentativeHRA(isRepresentativeHRAsHost);
  });

  return { isClient, isPartner, isRepresentativeHRA };
};
