import { Network } from '@capacitor/network';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { URL_NO_CONNECTION } from '../../../../../../../../common/constants/routers';
import { getCollectionQuestions } from '../../../../../../../../common/firebase/firestorage';
import { UserAccess } from '../../../../../../../../common/hooks/user-access';
import { HRARegisterState } from '../../../../../../../../common/store-atom/hra-register-atom';
import { QuestionsState } from '../../../../../../../../common/store-atom/questionares-atom';
import { questionareCloseBottonState } from '../../../../../../../../common/store-atom/questionares-close-botton';
import { HealthQuestionnaireView } from './HealthQuestionnaireView';
import { getQuestions } from '../../../../../../../../common/adapters/questionnaire-adapter';
import { User } from '../../../../../../../../common/models/user';
import { QuestionnaireQuestionsState } from '../../../../../../../../common/store-atom/questionnaire-questions';
import { UserState } from '../../../../../../../../common/store-atom/user-atom';

export const HealthQuestionnaireContainer = () => {
  const [state] = useRecoilState(HRARegisterState);
  const { t, i18n } = useTranslation();
  const { isRepresentativeHRA } = UserAccess();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const setQuestionnaireQuestions = useSetRecoilState(QuestionnaireQuestionsState);
  const setQuestions = useSetRecoilState(QuestionsState);
  const userSelector: User = useRecoilValue(UserState);

  const showQuestionnarieCloseButtonState = useRecoilValue(questionareCloseBottonState);

  const fetchCollectionQuestions = useCallback(async () => {
    const stats = await Network.getStatus();
    if (stats.connected === false) {
      window.location.href = URL_NO_CONNECTION;
      return;
    }
    const collections = await getCollectionQuestions();
    setQuestions(collections);
  }, []);

  const getQuestionnaireQuestions = async () => {
    const questions = await getQuestions(userSelector.uid || null);
    setQuestionnaireQuestions(questions.data.data);
  };

  useEffect(() => {
    fetchCollectionQuestions();
    getQuestionnaireQuestions();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(state.language);
  }, [state.language]);

  return (
    <HealthQuestionnaireView
      t={t}
      setShowWarningModal={setShowWarningModal}
      showWarningModal={showWarningModal}
      showQuestionnarieCloseButtonState={showQuestionnarieCloseButtonState}
      isRepresentativeHRA={isRepresentativeHRA}
    />
  );
};
