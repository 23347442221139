import { useEffect } from 'react';

/**Tabs */
import { LCTabsClients } from './components/tabs-clients';
import { LCTabsPartner } from './components/tabs-partner';
import { LCTabsRepresentatives } from './components/tabs-representatives';

/**Header Menu */
import { LCHeaderMenu } from '../../components/header-menu';
import { subdomainType } from '../../common/utils/subdomin-from-uri';

export const MenuTabRoot: React.FC = () => {
  const { isClientSubdomain, isPartnerSubdomain, isRepresentativeSubdomain } = subdomainType();

  useEffect(() => {}, []);

  return (
    <>
      <LCHeaderMenu />
      {isClientSubdomain && <LCTabsClients />}
      {isPartnerSubdomain && <LCTabsPartner />}
      {isRepresentativeSubdomain && <LCTabsRepresentatives />}
    </>
  );
};
