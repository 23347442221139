import { ConfirmationResult, getAuth, RecaptchaVerifier } from 'firebase/auth';
import { fireBaseApp } from './firebase';

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
    isPhoneAuth: boolean;
  }
}

export const phoneAuth = getAuth(fireBaseApp);

export const generateRecaptcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(
    'sign-in-button',
    {
      size: 'invisible',
    },
    phoneAuth
  );
};
