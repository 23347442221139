import { IonPage, IonContent, IonText, IonFooter, IonButton } from '@ionic/react';

import { LANGUAGE_ENGLISH, LANGUAGE_SPANISH } from '../../../../../common/constants/language';
import { RepresentativeHomeIllustration } from './RepresentativeHomeIllustration';
import './styles.scss';

export const RepresentativeHomeView = ({ t, setQuestionareLanguage }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="representative-block-center">
          <div className="representative-home-title">
            <IonText color="primary">{t('representative-home-title')}</IonText>
          </div>
          <div className="representative-home-subtitle">
            <p>{t('representative-home-sub-title')}</p>
          </div>
          <div className="representative-home-Illustration">
            <RepresentativeHomeIllustration />
          </div>
        </div>
      </IonContent>
      <IonFooter className="representative-home-footer">
        <IonButton
          color="primary"
          className="representative-home-button"
          expand="block"
          onClick={() => setQuestionareLanguage(LANGUAGE_ENGLISH)}
        >
          English questionnaire
        </IonButton>
        <IonButton className="representative-home-button" expand="block" onClick={() => setQuestionareLanguage(LANGUAGE_SPANISH)}>
          Cuestionario en español
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
