import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonItem,
  IonModal,
  IonPage,
  IonRouterLink,
  IonText,
} from '@ionic/react';

import { REPRESENTATIVE_URL_HOME, URL_MAIN_HOME } from '../../../../../../../../common/constants/routers';
import { Slides } from '../../../../../../../questionares/components/slider';
import { Steps } from '../../../../../../../questionares/components/steps';
import '../styles.scss';

export const HealthQuestionnaireView = ({
  t,
  showQuestionnarieCloseButtonState,
  setShowWarningModal,
  showWarningModal,
  isRepresentativeHRA,
}) => {
  return (
    <IonPage>
      <IonHeader>
        <IonItem lines="none" className="lc-modal-header">
          <IonText color="primary">
            <h3>{t('questionnaire.title')}</h3>
          </IonText>
          {showQuestionnarieCloseButtonState && (
            <IonButton fill="clear" onClick={() => setShowWarningModal(true)}>
              {t('button_title_close')}
            </IonButton>
          )}
        </IonItem>
        <Steps />
      </IonHeader>
      <IonContent fullscreen>
        <Slides isRepresentativeHRA={isRepresentativeHRA} />

        <IonModal isOpen={showWarningModal} cssClass="lc-modal-backdrop">
          <IonCard className="ion-text-center">
            <IonCardHeader />
            <h4>{t('questionnaire_leave_modal_card.header')}</h4>
            <p>{t('questionnaire_leave_modal_card.sub_title_no_rewards')} 🙁</p>

            <IonCardContent>
              <IonRouterLink routerLink={isRepresentativeHRA ? REPRESENTATIVE_URL_HOME : URL_MAIN_HOME}>
                {t('questionnaire_leave_modal_leave_button.label')}{' '}
              </IonRouterLink>
              <IonButton fill="clear" onClick={() => setShowWarningModal(false)}>
                {t('questionnaire_leave_modal_continue_questionnaire_button.label')}
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
