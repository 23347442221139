import { useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { menuController } from '@ionic/core';
import { clipboard, home, person, ribbon } from 'ionicons/icons';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';

import { setNavigationPage, getLastNavigationPage } from '../../../common/storage';
import { TabMenuState } from '../../../common/store-atom/tab-menu-atom';
import { TabHealthPage } from '../../tabs/health';
import { EarningsConfirmationPage } from '../../tabs/earnings/components/confirmation';
import { EarningsErrorPage } from '../../tabs/earnings/components/error';
import { TabHomePage } from '../../tabs/home';
import { TabImpactPage } from '../../tabs/Impact';
import { AboutYourHealthPage } from '../../tabs/about-your-health';
import { AppointmentConfirmationPage } from '../../tabs/health/pages/AppointmentConfirmationPage';
import { RequestAppointmentPage } from '../../tabs/health/pages/RequestAppointmentPage';
import { NotFoundPage } from '../../../components/error-page/404';
import { ShareTopicOfTheMonthContainer } from '../../share-topic-of-the-month/components/ShareTopicOfTheMonthContainer';
import { CellphoneVerificationPageContainer } from './../../cellphone-verification/components/CellphoneVerificationPageContainer';
import { VerifyCodeContainer } from '../../cellphone-verification/components/VerifyCodeContainer';
import { ConfirmationPage } from './../../cellphone-verification/components/Confirmation';
import { TabEarningsPage } from './../../tabs/earnings/index';
import { PaypalPostRequestPage } from '../../tabs/earnings/components/paypalPostRequestPage';
import { CheckInViewContainer } from '../../check-in/components/CheckInViewContainer';
import { getFlagValue } from '../../../common/firebase/remote-config';

import {
  URL_MAIN,
  URL_MAIN_HEALTH,
  URL_MAIN_EARNINGS,
  URL_MAIN_EARNINGS_CONFIRMATION,
  URL_MAIN_EARNINGS_ERROR,
  URL_MAIN_HOME,
  URL_MAIN_IMPACT,
  URL_MAIN_HEALTH_APPOINTMENT,
  URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION,
  URL_ABOUT_YOUR_HEALTH,
  URL_SHARE_TOPIC_OF_THE_MONTH,
  URL_MAIN_PHONE_NUMBER_VERIFICATION,
  URL_PHONE_VERIFICATION_VERIFY_CODE,
  URL_PHONE_VERIFICATION_CONFIRMATION,
  URL_PAYPAL_OAUTH_PAGE,
  URL_MAIN_CHECK_IN,
} from '../../../common/constants/routers';
import './styles.scss';

export const LCTabsPartner = () => {
  const history = useHistory();
  const setTabMenuState = useSetRecoilState(TabMenuState);
  const { t } = useTranslation();

  const [selected, setSelected] = useState(false);
  const checkEarnings = async () => {
    let response = await getLastNavigationPage();
    if (response === '/main/earnings') {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  const handleTabsDidChange = async (event: CustomEvent) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    await setNavigationPage(URL_MAIN.concat('/').concat(event.detail.tab));
    checkEarnings();
    setTabMenuState(event.detail.tab);
    history.push(`${URL_MAIN}/${event.detail.tab}`);
  };

  const handleMenu = async () => {
    await menuController.toggle();
  };

  return (
    <>
      <IonTabs>
        <IonRouterOutlet id="main-content">
          <Route path={URL_MAIN} render={() => <Redirect to={URL_MAIN_HOME} />} exact />
          <Route path="/" render={() => <Redirect to={URL_MAIN_HOME} />} exact />
          <Route path={URL_MAIN_HOME} component={TabHomePage} exact />
          <Route path={URL_MAIN_HEALTH_APPOINTMENT} component={RequestAppointmentPage} exact />
          <Route path={URL_MAIN_HEALTH_APPOINTMENT_CONFIRMATION} component={AppointmentConfirmationPage} exact />
          <Route path={URL_MAIN_HEALTH} component={TabHealthPage} exact />
          <Route path={URL_MAIN_PHONE_NUMBER_VERIFICATION} component={CellphoneVerificationPageContainer} exact />
          <Route path={URL_PHONE_VERIFICATION_VERIFY_CODE} component={VerifyCodeContainer} exact />
          <Route path={URL_PHONE_VERIFICATION_CONFIRMATION} component={ConfirmationPage} exact />
          <Route path={URL_MAIN_IMPACT} component={TabImpactPage} exact />
          <Route path={URL_MAIN_EARNINGS} component={TabEarningsPage} exact />
          <Route path={URL_MAIN_EARNINGS_CONFIRMATION} component={EarningsConfirmationPage} exact />
          <Route path={URL_MAIN_EARNINGS_ERROR} component={EarningsErrorPage} exact />
          <Route path={URL_PAYPAL_OAUTH_PAGE} component={PaypalPostRequestPage} exact />
          <Route path={URL_ABOUT_YOUR_HEALTH} component={AboutYourHealthPage} exact />
          <Route exact path={URL_MAIN_CHECK_IN} component={CheckInViewContainer} />
          <Route exact path={URL_SHARE_TOPIC_OF_THE_MONTH} component={ShareTopicOfTheMonthContainer} />
          <Route component={NotFoundPage} />
        </IonRouterOutlet>

        <IonTabBar onIonTabsDidChange={handleTabsDidChange} color="navy" slot="bottom" className="custom-tab-rounded">
          <IonTabButton tab="home" href={URL_MAIN_HOME}>
            <IonIcon className="custom-tab-icon" icon={home} />
            <IonLabel>{t('menu_tab.title_home')}</IonLabel>
          </IonTabButton>

          {getFlagValue('tab_providers') && (
            <IonTabButton tab="provider" href={URL_MAIN_HEALTH_APPOINTMENT}>
              <IonIcon className="custom-tab-icon" src="/assets/icon/menu/icon-provider.svg" />
              <IonLabel>{t('menu_tab.title_providers')}</IonLabel>
            </IonTabButton>
          )}

          {getFlagValue('tab_impact') && (
            <IonTabButton tab="impact" href={URL_MAIN_IMPACT}>
              <IonIcon className="custom-tab-icon" src="/assets/icon/menu/icon-impact.svg" />
              <IonLabel>{t('menu_tab.title_impact')}</IonLabel>
            </IonTabButton>
          )}

          {getFlagValue('tab_earnings') && (
            <IonTabButton selected={selected} tab="earnings" href={URL_MAIN_EARNINGS} disabled={selected}>
              <IonIcon className="custom-tab-icon" icon={ribbon} />
              <IonLabel>{t('menu_tab.title_earnings')}</IonLabel>
            </IonTabButton>
          )}

          {getFlagValue('tab_about_health') && (
            <IonTabButton tab="about-your-health" href={URL_ABOUT_YOUR_HEALTH}>
              <IonIcon className="custom-tab-icon" icon={clipboard} />
              <IonLabel>{t('menu_tab.title_health_actions')}</IonLabel>
            </IonTabButton>
          )}

          <IonTabButton>
            <div className="custom-tab-button" onClick={handleMenu}>
              <IonIcon className="custom-tab-icon" icon={person} />
              <IonLabel>{t('menu_tab.title_account')}</IonLabel>
            </div>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};
