/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ProfileState } from '../../common/store-atom/profile-atom';
import { AxiosError } from 'axios';
import { updateAccountSetting } from '../../common/adapters/user-profile-adapter';
import { AccountSettingView } from './account-settings-view';
import { UserAccess } from '../../common/hooks/user-access';
import { getHairProfessionals } from '../../common/adapters/barber-adapter';
import { AccountSetting } from '../../common/models/user';
import { getAccountSettings } from '../../common/adapters/account-settings';
import { UserState } from '../../common/store-atom/user-atom';
import { MessageToastHooks } from '../../common/hooks/message-toast';
import { OptionsModel } from '../../common/models/option-model';
import { HairProfessional } from '../../common/models/HairProfessional';
import { Spinner } from '../../components/spinner/spinner';
import { addressToString } from '../../common/utils/address-to-string';

export const AccountSettings = () => {
  const { isClient } = UserAccess();
  const { messageToastError, messageToastSuccess } = MessageToastHooks();
  const [barberShopSelected, setBarberShopSelected] = useState<number>(0);
  const [onSuccess, setOnSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data, refetch } = useQuery('getAccountSettings', () => getAccountSettings(profileState?.user_type || 0), {
    enabled: false,
  });
  const [userState, setUserState] = useRecoilState(UserState);
  const [messageErrorFiled, setMessageErrorFiled] = useState<Array<any>[]>([]);
  const profileState = useRecoilValue(ProfileState);

  const queryAllHairProfessional = useQuery(
    ['getAllHairProfessional', barberShopSelected],
    () => getHairProfessionals(barberShopSelected, profileState.app_language),
    {
      enabled: !!barberShopSelected,
    }
  );

  const onChangesSuccess = (data: any) => {
    setOnSuccess(true);
      setUserState({
        ...data.data.data,
        authType: userState.authType,
      });
      messageToastSuccess();
  };

  const mutationSaveAccountSetting = useMutation(updateAccountSetting, {
    onSuccess: (data: any) => {
      onChangesSuccess(data);
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 600) {
        setMessageErrorFiled(error.response.data.messages);
      } else messageToastError(error);
    },
  });

  const saveAccountSetting = (accountSetting: AccountSetting) => {
    mutationSaveAccountSetting.mutate({ ...accountSetting });
  };

  useEffect(() => {
    setIsLoading(true);
    refetch().finally(() => {
      setIsLoading(false);
    });
  }, []);

  const formatHpList = (): OptionsModel[] => {
    const listHp: OptionsModel[] = queryAllHairProfessional.data
      ? queryAllHairProfessional.data.map((hp: HairProfessional) => {
          const optionsModel: OptionsModel = { value: String(hp.id), text: `${hp.firstName} ${hp.lastName}` };
          return optionsModel;
        })
      : [];

    return listHp;
  };

  let address = '';

  if (data?.profile.address) {
    address = addressToString(data.profile.address);
  }

  return (
    <>
      <Spinner isOpen={isLoading} />
      {data && data.profile && data.barberShops && !isLoading && (
        <AccountSettingView
          isClient={isClient}
          accountSetting={{
            ...data.profile,
            ...(address.length && { address }),
          }}
          originalAddress={data.profile.address}
          barber_shop={data.barberShops}
          allHairProfessional={queryAllHairProfessional.data === undefined ? data.hairProfessional : formatHpList()}
          callback={setBarberShopSelected}
          handleOnSave={saveAccountSetting}
          submitFormSucces={onSuccess}
          messageErrorFiled={messageErrorFiled}
          originalPhones={data.profile.phones}
          onChangesSuccess={onChangesSuccess}
        />
      )}
    </>
  );
};
