import { atom, selector } from 'recoil';
import { getAnalytics, logEvent } from 'firebase/analytics';

const analytics = getAnalytics();

/**
 * Represents the different url types for registration
 */
export enum RegistrationUrlType {
  SHOP = 'shop',
  CAMPAIGN = 'campaign',
  NONE = 'none',
}

/**
 * Describes the data structure of registration parameters.
 */
export interface RegistrationState {
  campaignId: string | null;
  shopId: string | null;
  registrationId: string | null;
  questionnaireId: string | null;
  // This can be helpful if some piece of code needs to wait for the URL to
  // be decoded. Optional and can be removed if not needed
  initialized: boolean;
}

/**
 * Maintains the global state of registration parameters.
 */
export const registrationStateAtom = atom<RegistrationState>({
  key: 'registerState',
  default: {
    campaignId: 'not_from_campaign',
    shopId: 'not_from_shop',
    registrationId: null,
    initialized: false,
    questionnaireId: 'default_Lch_HRA',
  },
});

// urlType
interface UrlTypeSelector {
  urlType: RegistrationUrlType;
  isFromShop: boolean;
  isFromCampaign: boolean;
}

export const urlTypeSelector = selector<UrlTypeSelector>({
  key: 'urlTypeSelector',
  get: ({ get }) => {
    const { campaignId, shopId } = get(registrationStateAtom);

    /*
     * Technically, we could have a bad url that has both a shop and a campaign
     * id. I think this is an edge case that may not need to be handled but
     * should be logged.
     */
    if (shopId && campaignId) {
      logEvent(analytics, 'Bad-Register-URL', {
        description: 'The URL has both Campaign and Shop ID',
      });
    }

    let urlType = RegistrationUrlType.NONE;
    let isFromCampaign = false;
    let isFromShop = false;
    if (campaignId !== 'not_from_campaign') {
      urlType = RegistrationUrlType.CAMPAIGN;
      isFromCampaign = true;
    } else if (shopId !== 'not_from_shop') {
      urlType = RegistrationUrlType.SHOP;
      isFromShop = true;
    }

    return {
      urlType,
      isFromShop,
      isFromCampaign,
    };
  },
});

export const phoneNumberState = atom<{ phone: string }>({
  key: 'phoneNumberState',
  default: { phone: '' },
});

export const comesFromAccountSettingsState = atom<{ comesFromAccountSettings: boolean }>({
  key: 'comesFromAccountSettingsState',
  default: { comesFromAccountSettings: false },
});

export const comesFromUniqueLinkState = atom<{ comesFromUniqueLink: boolean }>({
  key: 'comesFromUniqueLinkState',
  default: { comesFromUniqueLink: false },
});

export const wasNewPhoneNumberVerifiedState = atom<{ wasNewPhoneNumberVerified: boolean }>({
  key: 'wasNewPhoneNumberVerifiedState',
  default: { wasNewPhoneNumberVerified: false },
});

export const verificationIdState = atom<{ verificationId: string }>({
  key: 'verificationId',
  default: { verificationId: '' },
});

export const uniqueLinkIdState = atom<{ uniqueLinkId: string }>({
  key: 'uniqueLinkId',
  default: { uniqueLinkId: '' },
});

export const randomStringState = atom<{ randomString: string }>({
  key: 'randomString',
  default: { randomString: '' },
});

