/* eslint-disable no-return-await */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Network } from '@capacitor/network';
import { CODE_ID_TOKEN_FAILED } from '../exceptions/contants';
import { handleError, handleErrorRequest } from '../exceptions/handle-error';
import { URL_404_NOT_FOUND_PAGE, URL_500_INTERNAL_ERROR_PAGE, URL_NO_CONNECTION } from '../constants/routers';
import { getNewToken } from '../firebase/session-management';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

axios.defaults.withCredentials = true;
instance.defaults.withCredentials = false;

instance.interceptors.request.use(
  async (request) => {
    const accessToken = await getNewToken();
    request.headers.common.Authorization = `Bearer ${accessToken}`;
    return request;
  },
  (error: AxiosError) => {
    if (error?.response?.status === 500) {
      window.location.href = URL_500_INTERNAL_ERROR_PAGE;
    }
    return Promise.reject(error);
  }
);

export const responseInterceptor = {
  handler: (response: AxiosResponse<any>) => response,
  errorHandler: async (error: AxiosError) => {
    const stats = await Network.getStatus();
    if (stats.connected === false) {
      return (window.location.href = URL_NO_CONNECTION);
    }
    if (error?.response?.status == 500) {
      return (window.location.href = URL_500_INTERNAL_ERROR_PAGE);
    }
    if (error?.response?.status === 404) {
      window.location.href = URL_404_NOT_FOUND_PAGE;
    }
    if (error.response?.status === CODE_ID_TOKEN_FAILED) {
      return await handleError(instance, error);
    }

    return handleErrorRequest(error);
  },
};

const { handler, errorHandler } = responseInterceptor;

export const interceptor = instance.interceptors.response.use(handler, errorHandler);

const responseBody = (response: AxiosResponse) => response.data.data;

export const requestsApi = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: unknown) => instance.post(url, body).then(responseBody),
  put: (url: string, body: unknown) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};

export default instance;
