import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IonContent, IonPage, IonText, useIonViewWillEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import TopCard from '../../../../components/top-card';
import BlankCard from '../../../../components/blank-card';
import BloodPressureCard from '../components/bloodPressureCard';
import { Spinner } from '../../../../components/spinner/spinner';
import BiometricsGraph from '../../../../components/biometrics-graph';
import { BiometricsResponse } from '../../../../common/models/biometrics';
import { getAllBiometrics } from '../../../../common/adapters/biometrics-adapter';
import { URL_MAIN_HEALTH, URL_MAIN_HEALTH_BP_CHART } from '../../../../common/constants/routers';
import './styles.scss';

const HealthRecordsPage: React.FC = () => {
  const [biometrics, setBiometrics] = useState<Array<BiometricsResponse>>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useIonViewWillEnter(() => {
    getAllBiometrics().then((response) => {
      setBiometrics(response.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <IonPage>
      <Spinner isOpen={loading} />
      <IonContent fullscreen className="lc-full-spot-bg">
        <div className="page-container">
          <div className="content">
            <TopCard message={`💪🏾 ${t('say_hello_t0_healthy')}`} redirectTo={URL_MAIN_HEALTH} />

            <BlankCard
              options={{
                title: '📈 ' + t('title_bp_journey'),
                description: t('text_bp_journey'),
                showHeaderImage: false,
              }}
            >
              <BiometricsGraph data={biometrics} />

              <IonText>
                <h3 className="bp-chart-link">
                  <Link to={URL_MAIN_HEALTH_BP_CHART}>{t('learn_about_graph')}</Link>
                </h3>
              </IonText>
            </BlankCard>

            <BlankCard
              options={{
                title: '📝 ' + t('title_bp_records'),
                description: t('text_bp_records'),
                showHeaderImage: false,
              }}
            >
              <section
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {biometrics.map((BPInfo) => (
                  <BloodPressureCard key={BPInfo.id} BPData={BPInfo} />
                ))}
              </section>
            </BlankCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HealthRecordsPage;
