import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/messaging';

/** Common */
import { IonIcon, useIonToast } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { REPRESENTATIVE_URL_HOME } from '../../../../../common/constants/routers';
import { getAuth, User } from 'firebase/auth';
import { signInWithSocialNetwork } from '../../../../../common/firebase/sign-in';
import { Spinner } from '../../../../../components/spinner/spinner';
import { LCButton } from '../../../../../components/buttons/button';

/** Component */

interface ISocialNetworkButtonProps {
  provider: firebase.auth.AuthProvider;
  title: string;
  icon: string;
  backgroundColor: string;
}
export const RepresentativeSocialNetworkButton: React.FC<ISocialNetworkButtonProps> = ({
  provider,
  title,
  icon,
  backgroundColor,
}: ISocialNetworkButtonProps) => {
  const history = useHistory();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [present, dismiss] = useIonToast();

  const checkCustomClaim = async () => {
    getAuth()
      .currentUser?.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user has proper custom claim
        if (idTokenResult.claims.type === 'CSR') {
          //Go Home
          return history.push(REPRESENTATIVE_URL_HOME);
        } else {
          present({
            color: 'danger',
            buttons: [{ text: 'Ok', handler: () => dismiss() }],
            message: 'User not found. Contact LCH to validate your account access.',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postRegisterAction = (user: User) => {
    checkCustomClaim();
  };

  /** Login in the user using GoogleProvider */
  const handleLoginByGoogle = async () => {
    setButtonClicked(true);
    await signInWithSocialNetwork(provider, postRegisterAction);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Spinner isOpen={isLoading} />
      <LCButton onClick={handleLoginByGoogle} customColor={backgroundColor} expand="block">
        <IonIcon slot="start" icon={icon} />
        {title}
      </LCButton>
    </>
  );
};