import { IonPage, IonContent, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { URL_MAIN_HOME } from '../../../common/constants/routers';

export const InvalidCheckInPage = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <IonContent fullscreen className="lc-justify-center ion-text-center">
        <div className="block-center-error-page">
          <div className="lc-error-page-buttons-container">
            <div className="lch-error-container">
              <div className="error-code">
                <h6></h6>
              </div>
              <div className="error-title">
                <h6>
                  {t('page_error_invalid-check-in').toUpperCase()} <br />
                  {t('page_error_invalid-check-in-2').toUpperCase()}
                </h6>
              </div>
              <div className="error-message">
                <h6>
                  {t('page_error_invalid-check-in-detail')} <br />
                  {t('page_error_invalid-check-in-detail-2')}
                </h6>
              </div>
              <div className="lch-error-footer">
                <IonButton href={URL_MAIN_HOME} className="lch-error-redirect-button">
                  Go back home
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
