import React from 'react';
import { IonButton, IonItem, IonLoading, IonModal, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { DiseaseType } from '../../pages/tabs/home/enums/disease-type';
import { URL_MAIN_HOME } from '../../common/constants/routers';
import api from '../../common/adapters/api';

import './styles.scss';

interface ExternalLinkModalProps {
  show?: boolean;
  handleOnhideModal: () => void;
  linkTitle?: string;
  diseaseType?: DiseaseType;
}

export const FindHelpModal: React.FC<ExternalLinkModalProps> = ({ show = false, handleOnhideModal, linkTitle, diseaseType }) => {
  const { t } = useTranslation();

  const handleNotifyReadArticle = async () => {
    if (diseaseType) {
      await api.put(`articles/diseases/${diseaseType}`);
      redirectHome();
    }
    return;
  };

  const redirectHome = () => {
    return (window.location.href = URL_MAIN_HOME);
  };

  return (
    <IonModal isOpen={show} backdropDismiss={false} cssClass="custom-modal">
      <IonItem lines="none" className="lc-modal-header-findhelp">
        <IonText color="primary">
          <h3>{linkTitle ? linkTitle : t('external_link_modal_default.title')}</h3>
        </IonText>
        <IonButton
          fill="solid"
          onClick={() => {
            handleOnhideModal();
            handleNotifyReadArticle();
          }}
        >
          {t('button_title_close')}
        </IonButton>
      </IonItem>
      <iframe className="findHelp-iframe" src="https://www.findhelp.org/">
        <IonLoading
          isOpen={true}
          backdropDismiss={true}
          duration={2000}
          message={t('spinner.message')}
          showBackdrop={true}
          translucent={true}
        />
      </iframe>
    </IonModal>
  );
};
