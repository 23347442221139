import { useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useIonToast, IonHeader, IonIcon, IonChip, IonLabel, IonButton } from '@ionic/react';
import { removeOutline, checkmarkCircleOutline, timeOutline } from 'ionicons/icons';
import { cancelAttendance, confirmAttendance } from '../../../common/adapters/events-adapter';
import { Spinner } from '../../spinner/spinner';
import { checkAttendance } from '../../events-calendar/utils/functions';

export const EventModalCard: React.FC<{
  onDismiss: () => void;
  eventList: any[];
  profileId: string;
}> = ({ eventList, profileId }) => {
  const [loading, setLoading] = useState(false);
  const [present, dismiss] = useIonToast();
  const { t } = useTranslation();

  const handleRsvp = (handler: (eventId?: string) => Promise<AxiosResponse<any>>) => {
    setLoading(true);
    handler()
      .then((_) => {
        window.location.reload();
      })
      .catch(() => {
        present({
          color: 'danger',
          buttons: [{ text: 'Ok', handler: () => dismiss() }],
          message: t('events-management-attendance-error'),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderButtons = (eventData, type) => {
    if (type === 'event') {
      if (!checkAttendance(eventData, profileId)) {
        return <IonButton onClick={() => handleRsvp(() => confirmAttendance(eventData.id))}>RSVP</IonButton>
      } else {
        return (
        <IonButton fill="clear" onClick={() => handleRsvp(() => cancelAttendance(eventData.id))}>
          {t('cancel_attendance')}
        </IonButton>
        )
      }
    } else return null;
  };

  return (
    <div className="event-modal-inner">
      <IonHeader className="event-modal-header">
        <IonIcon icon={removeOutline} />
      </IonHeader>

      <div className="event-list-modal">
        {eventList.map((eventData) => {
          const type = !!eventData.patientsNames ? 'appointment' : 'event';
          const eventDateTime = type === 'event' ? new Date(eventData.eventStartTime || eventData.dateTime) : new Date(eventData.AppointmentDateTime);
          const eventEndTime = type === 'event' && eventData.eventEndTime ? new Date(eventData.eventEndTime) : '';
          return (
            <>
              <div key={eventData.id} className="event_item-container">
                <Spinner isOpen={loading} />
                <section className="event_inner-container2">
                  <section className="event_first-line">
                    <span className="event_date">
                      {eventDateTime && format(new Date(eventDateTime), 'EEEE, MMMM, d')}
                    </span>
                    {type === 'event' &&
                      <IonChip className={`event_chip ${checkAttendance(eventData, profileId) ? 'confirmed' : 'unconfirmed'}`}>
                        <IonIcon
                          icon={checkAttendance(eventData, profileId) ? checkmarkCircleOutline : timeOutline}
                          color="white"
                        />
                        <IonLabel>{checkAttendance(eventData, profileId) ? t('events-management-going') : 'RSVP'}</IonLabel>
                      </IonChip>
                    }
                  </section>

                  <section className="event_info-container">
                    <span className="event_info-title">{type === 'event' ? eventData?.title : 'Family Health Appointment'}</span>
                    {type === 'event' &&
                      <span className="event_info-description">{eventData.description}</span>
                    }
                    <span className="event_info-description">
                      <em>{t('events-management-start')}:</em> {format(new Date(eventDateTime), 'h:mm a')}
                    </span>
                    {type === 'event' && eventEndTime &&
                        <span className="event_info-description">
                          <em>{t('events-management-end')}:</em> {format(new Date(eventEndTime), 'h:mm a')}
                        </span>
                    }
                    <span className="event_info-description">
                      <em>{t('events-management-where')}:</em> {eventData.location || eventData?.PcpSupplierFormattedAddress}
                    </span>
                    {type === 'appointment' &&
                      <span className="event_info-description">
                        <em>{t('events-management-who')}:</em> {eventData.patientsNames}
                      </span>
                    }

                    {renderButtons(eventData, type)}
                  </section>
                </section>
              </div>
              <hr />
            </>
          );
        })}
      </div>
    </div>
  );
};
