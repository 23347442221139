import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonContent,
  IonModal,
  IonText,
  IonCardContent,
  IonIcon,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import { RequestAppointment } from '../../../../../../components/request-appointment';
import { ContactType } from '../../../enums/contact-type';
import './../styles.scss';

interface IRegisterRequestAppointmentProps {
  isOpen?: boolean;
  contactType: ContactType,
  title: string;
  handleCloseModal: () => void;
  callback?: () => void;
}
export const RegisterRequestAppointment = ({
  isOpen = false,
  title,
  handleCloseModal,
  callback,
  contactType,
}: IRegisterRequestAppointmentProps) => {
  const handleOnPostSave = () => {
    if (callback) {
      callback();
    }

    handleCloseModal();
  };

  return (
    <IonContent>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => handleCloseModal()}
        cssClass="lc-modal-backdrop lc-modal-request"
      >
        <section>
          <IonCard>
            <IonCardHeader>
              <IonText color="primary">
                <h2>{title}</h2>
              </IonText>
              <IonButton fill="clear" onClick={() => handleCloseModal()}>
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonCardHeader>
            <IonCardContent>
              <RequestAppointment title={title} contactType={contactType} callback={() => handleOnPostSave()} />
            </IonCardContent>
          </IonCard>
        </section>
      </IonModal>
    </IonContent>
  );
};
