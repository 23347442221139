import app from 'firebase/compat/app';
import 'firebase/compat/messaging';
import * as config from './config';

import { savePushNotificationDeviceToken } from './../adapters/push-notification';

export function requestPermission(): Promise<void> {
  return new Promise<void>(async (resolve) => {
    if (!app.messaging.isSupported()) {
      resolve();
      return;
    }
    try {
      const messaging = app.messaging();
      const token: string = await messaging.getToken({
        vapidKey: config.getConfigFireBase().vapidKey,
      });
      savePushNotificationDeviceToken(token);
    } catch (err) {
      console.log(err);
    }

    resolve();
  });
}
