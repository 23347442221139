import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import { REPRESENTATIVE_URL_HOME } from '../../../../../common/constants/routers';
import { HRARegister } from '../../../../../common/models/hra-register';
import { HRARegisterState } from '../../../../../common/store-atom/hra-register-atom';
import { RepresentativeDoneView } from './RepresentativeDoneView';

export const RepresentativeDoneContainer = () => {
  const history = useHistory();
  const demographics = history.location.state as HRARegister;
  const [state] = useRecoilState(HRARegisterState);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(state.language);
  }, [state.language]);

  const onSubmit = () => {
    history.push(REPRESENTATIVE_URL_HOME);
  };

  return <RepresentativeDoneView t={t} onSubmit={onSubmit} demographics={demographics} />;
};
