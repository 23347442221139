import { fetchAndActivate, getAll, getValue } from 'firebase/remote-config';
import { remoteConfig } from './firebase';

interface IFlags {
  [value: string]: boolean;
}

export const defaultFlags: IFlags = {
  testing_feature_flag: true,
  unique_phone_validation: true,
  isAddressAZipCode: false,
  hide_rewards: false,
  disable_contact_info_edit: true,
  tab_biometrics: true,
  tab_home: true,
  tab_rewards: true,
  tab_home_rebrand: false,
  tab_earnings: true,
  tab_care: false,
  tab_about_health: false,
  tab_impact: false,
  tab_providers: false,
};

remoteConfig.settings = {
  minimumFetchIntervalMillis: 43200000,
  fetchTimeoutMillis: 60000,
};

remoteConfig.defaultConfig = defaultFlags;

export const getFlags = async (fetch = fetchAndActivate, remote = remoteConfig) => {
  return await fetch(remote)
    .then((activated) => {
      activated && global.console.info('activated');
      return getAll(remote);
    })
    .then((remoteFlags) => {
      let newFlags = {
        ...defaultFlags,
      };
      for (const [key, config] of Object.entries(remoteFlags)) {
        // @ts-expect-error
        newFlags[key] = config._value === 'true';
      }
      return newFlags;
    })
    .catch((error) => console.error(error));
};

export const getFlagValue = (flagName: string, getVal = getValue) => {
  return getVal(remoteConfig, flagName).asBoolean();
};
