import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation, Virtual } from 'swiper';
import { SliderOne } from './components/SliderOne';
import { SliderTwo } from './components/SliderTwo';
import { URL_REGISTER } from '../../common/constants/routers';

export const OnboardingSliderBody = () => {
  const [isLast, setIsLast] = useState(false);
  const history = useHistory();
  const nextSlide = () => {
    setIsLast(true);
  };
  const prevSlide = () => {
    setIsLast(false);
  };
  const onSwipe = () => {
    setIsLast(!isLast);
  };
  const redirectRegister = () => {
    return history.push(URL_REGISTER);
  };

  return (
    <IonPage>
      <IonContent>
        <Swiper
          modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, Navigation, Virtual]}
          autoplay={false}
          onSlideChange={onSwipe}
          spaceBetween={50}
          keyboard
          pagination={{ clickable: false }}
          speed={500}
          scrollbar={false}
          zoom={false}
          navigation={{
            nextEl: '.review-swiper-button-next',
            prevEl: '.review-swiper-button-prev',
          }}
          slidesPerView={1}
          loop={false}
        >
          <SwiperSlide>
            <SliderOne></SliderOne>
          </SwiperSlide>
          <SwiperSlide>
            <SliderTwo></SliderTwo>
          </SwiperSlide>
          <div className="slide-buttons-container">
            {isLast ? (
              <>
                <IonButton onClick={prevSlide} className="review-swiper-button-prev">
                  PREV
                </IonButton>
              </>
            ) : (
              <>
                <IonButton onClick={prevSlide} className="review-swiper-button-prev"></IonButton>
              </>
            )}
            {isLast ? (
              <IonButton onClick={redirectRegister} className="review-swiper-button-next">
                FINISH
              </IonButton>
            ) : (
              <>
                <IonButton onClick={nextSlide} className="review-swiper-button-next">
                  NEXT
                </IonButton>
              </>
            )}
          </div>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};
