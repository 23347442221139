import React, { useState } from 'react';
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonDatetime,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonToast,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Patient as Profile } from '@live-chair-health/lch-core';

import { ItemFormGroup } from '../../../../components/item-form-group/item-form-group';
import { addFamilyMember } from '../../../../common/adapters/household-adapter';
import { IFamilyMember } from '../../../../common/models/households';
import { Spinner } from '../../../../components/spinner/spinner';
import { addFamilyMemberSchema } from '../utils/utils';

const AddFamilyMember: React.FC<{ userInfo: Profile; dismiss(): void }> = ({ userInfo, dismiss }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [toast] = useIonToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IFamilyMember>>({
    resolver: yupResolver(addFamilyMemberSchema),
    defaultValues: { dob: new Date(), firstName: '', lastName: '', relationship: '' },
  });

  const submitForm = async (formData: Partial<IFamilyMember>) => {
    setLoading(true);
    formData.dob = new Date(formData.dob as Date).toISOString();

    addFamilyMember(String(userInfo?.app_household_id), formData)
      .then(() => {
        dismiss();
        window.location.reload();
      })
      .catch((e) => {
        toast({
          color: 'danger',
          position: 'top',
          message: e.message,
          buttons: [{ text: 'Ok' }],
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Spinner isOpen={loading} />
      <IonCardHeader className="lc-care-add-member-title">
        <span>{t('care_tab_add_member')}</span>
      </IonCardHeader>
      <IonCardContent className="lc-care-add-member">
        <form onSubmit={handleSubmit(submitForm)}>
          <IonRow>
            <IonCol>
              <ItemFormGroup label={t('page_setting_account.label_first_name')} errorMessage={errors?.firstName?.message}>
                <input {...register('firstName')} className="lc-input" />
              </ItemFormGroup>
            </IonCol>

            <IonCol>
              <ItemFormGroup label={t('page_setting_account.label_last_name')} errorMessage={errors?.lastName?.message}>
                <input {...register('lastName')} className="lc-input" />
              </ItemFormGroup>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <ItemFormGroup label={t('page_setting_account.label_date_of_birth')} errorMessage={errors?.dob?.message}>
                {/* @ts-ignore */}
                <IonDatetime {...register('dob')} />
              </ItemFormGroup>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <ItemFormGroup label={t('care_tab_relationship')} errorMessage={errors?.relationship?.message}>
                <IonSelect {...register('relationship')} className="lc-input">
                  <IonSelectOption value={'child'}>{t('care_tab_add_form_child')}</IonSelectOption>
                  <IonSelectOption value={'elderParent'}>{t('care_tab_add_form_parent')}</IonSelectOption>
                  <IonSelectOption value={'spouse'}>{t('care_tab_add_form_spouse')}</IonSelectOption>
                </IonSelect>
              </ItemFormGroup>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">
                {t('button_title_save')}
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonCardContent>
    </>
  );
};

export default AddFamilyMember;
