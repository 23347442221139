import React from 'react';
import { phonePortraitOutline } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { URL_AUTH_PHONE } from '../../../common/constants/routers';

export const PhoneButton: React.FC = () => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(URL_AUTH_PHONE);
  };

  return (
    <IonButton onClick={handleOnClick} className="register-button" expand="block">
      <IonIcon slot="start" icon={phonePortraitOutline} />
      Phone
    </IonButton>
  );
};
