import i18next from 'i18next';
import * as yup from 'yup';
import { validateZipCode } from '../../../common/adapters/zip-code';
import { isEmpty } from '../../../common/utils/string-empty';

export const validateHairProfessional = (isClient: boolean, value: string | undefined) => {
  return !isClient || !isEmpty(value);
};

export const schemaAccountSettings = (isClient: boolean, isZipCode: boolean) => {
  return yup.object().shape({
    first_name: yup.string().required(i18next.t('message_field_required')),
    last_name: yup.string().required(i18next.t('message_field_required')),
    email: yup.string(),
    phones: yup.string().nullable(),
    ...(isZipCode && {
      zipCode: yup
        .string()
        .required(i18next.t('message_field_required'))
        .test('isZipCode', i18next.t('message_valid_us_zip_code'), async (value) => validateZipCode(value)),
    }),
    ...(!isZipCode && {
      address: yup.string().required(i18next.t('message_field_required')),
    }),
    barberShop: yup.string(),
    date_of_birth: yup.date().required(i18next.t('message_field_required')),
    barberId: yup.string(),
    language: yup.string().required(i18next.t('message_field_required')),
    smsNotifications: yup.boolean(),
  });
};

export const setTitleSelectBarberShop = (isClient: boolean) => {
  return isClient ? i18next.t('page_setting_account.label_shop') : i18next.t('page_setting_account.label_shop_I_work_for');
};
