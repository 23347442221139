import clsx from 'clsx';
import {
  add,
  addDays,
  endOfMonth,
  format,
  getDaysInMonth,
  isSameDay,
  isThisMonth,
  lastDayOfMonth,
  setDate,
  startOfMonth,
  sub,
  subMonths,
} from 'date-fns';
import { Event, AppointmentDetails } from '@live-chair-health/lch-core';

import Cell from './Cell';
import CurrentMonthCell from './CurrentMonthCell';
import '../styles.scss';

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const totalWeekDays = weekDays.length;

type CalendarViewProps = {
  currentDate?: Date;
  onChange: (date: Date) => void;
  eventList: Event[],
  scheduledAppointments: AppointmentDetails[],
  userId: string;
};

const CalendarView: React.FC<CalendarViewProps> = ({ eventList, scheduledAppointments, userId, onChange, currentDate = new Date() }) => {
  const fistDayOfMonth = startOfMonth(currentDate);
  const lastDayOfCurrentMonth = endOfMonth(currentDate);
  const totalDaysInMonth = getDaysInMonth(currentDate);

  const previousMonthDays = fistDayOfMonth.getDay();
  const nextMonthDays = totalWeekDays - (lastDayOfCurrentMonth.getDay() + 1);

  const prevMonth = () => onChange(sub(currentDate, { months: 1 }));
  const nextMonth = () => onChange(add(currentDate, { months: 1 }));

  const handleClickDate = (index: number) => {
    const date = setDate(currentDate, index);
    onChange(date);
  };

  const findEventsToRender = (events: Event[], calendarDate: Date) => (
    events.filter((event) => {
      // @ts-ignore
      return isSameDay(new Date(event.dateTime), new Date(calendarDate));
    })
  );

  const findAppointmentsToRender = (appointments: AppointmentDetails[], calendarDate: Date) => (
    appointments.filter((appointment) => {
      return isSameDay(new Date(appointment.AppointmentDateTime), new Date(calendarDate));
    })
  );

  return (
    <div className="calendar-container">
      {/* Next/Previous month buttons */}
      <div className="calendar-grid">
        <Cell clickable onClick={prevMonth}>
          {'<'}
        </Cell>
        <Cell className="calendar-current-month">{format(currentDate, 'LLLL yyyy')}</Cell>
        <Cell clickable onClick={nextMonth}>
          {'>'}
        </Cell>
      </div>

      {/* Name of week days */}
      <div className="calendar-grid">
        {weekDays.map((week) => (
          <Cell key={week}>{week}</Cell>
        ))}
      </div>

      <div className={clsx('calendar-grid', 'calendar-grid-days')}>
        {/* Days of previous month */}
        {Array.from({ length: previousMonthDays }).map((_, index) => {
          const date = index + 1;
          const lastDayOfPreviousMonth = lastDayOfMonth(subMonths(new Date(), 1)).getDate();
          return (
            <Cell disabled key={index}>
              {lastDayOfPreviousMonth - previousMonthDays + date}
            </Cell>
          );
        })}

        {/* Days of currrent month */}
        {Array.from({ length: totalDaysInMonth }).map((_, index) => {
          const date = index + 1;
          const thisCellDate = addDays(startOfMonth(currentDate), index);
          return (
            <CurrentMonthCell
              eventsToRender={findEventsToRender(eventList, thisCellDate)}
              appointmentsToRender={findAppointmentsToRender(scheduledAppointments, thisCellDate)}
              userId={userId}
              key={thisCellDate.toISOString()}
              today={date === new Date().getDate() && isThisMonth(currentDate)}
              onClick={() => handleClickDate(date)}
            >
              {date}
            </CurrentMonthCell>
          );
        })}

        {/* Days of next month */}
        {Array.from({ length: nextMonthDays }).map((_, index) => {
          const date = index + 1;
          return (
            <Cell disabled key={index}>
              {date}
            </Cell>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarView;
