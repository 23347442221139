import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonFooter,
  IonItem,
  IonPage,
  IonRow,
  IonText, useIonToast
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from 'react-router';
import { signInWithPhoneNumber } from "firebase/auth";

import { ItemFormGroup } from "../../components/item-form-group/item-form-group";
import { verifyBirthYear, verifyUniqueLinkExpirationDate } from '../../common/adapters/unique-links';
import { ProfileState } from "../../common/store-atom/profile-atom";
import { comesFromUniqueLinkState, phoneNumberState, randomStringState, uniqueLinkIdState } from "../../common/store-atom/register-atom";
import { generateRecaptcha, phoneAuth } from "../../common/firebase/phone-auth";
import { URL_EXPIRED_LINK, URL_INVALID_UNIQUE_LINK, URL_LOGIN, URL_REGISTER_PHONE_VERIFY } from "../../common/constants/routers";
import { addCountryCode } from "../../common/utils/cel-phone";
import { Spinner } from "../../components/spinner/spinner";

import lc_logo from '../../assets/img/lc_rebrand_logo.svg';
import member from '../../assets/img/member.png';
import './styles.scss';

export const DobVerification: React.FC = () => {
  const { t } = useTranslation();
  const [present] = useIonToast();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  const [dob, setDob] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [failedDOB, setFailedDOB] = useState<boolean>(false);

  const setPatientData = useSetRecoilState(ProfileState);
  const setUniqueLinkId = useSetRecoilState(uniqueLinkIdState);
  const setPhoneNumber = useSetRecoilState(phoneNumberState);
  const setComesFromUniqueLink = useSetRecoilState(comesFromUniqueLinkState);
  const setRandomString = useSetRecoilState(randomStringState);

  const randomString = urlParams.get('random_string') || '';

  useEffect(() => {
    const verifyLink = async () => {
      try {
        if (!randomString) {
          history.push(URL_INVALID_UNIQUE_LINK);
          return;
        }
        setLoading(true);
        setRandomString({ randomString });
        setComesFromUniqueLink({ comesFromUniqueLink: true });

        // case there's no unique link
        const { data: { data } } = await verifyUniqueLinkExpirationDate({ randomString });
        // case an account has been created already
        if (!data.isValid && data.hasAccountBeenCreated) {
          history.push(URL_LOGIN);
          return;
        }

        // case the link is expired
        if (!data.isValid && data.phoneNumber && data.patientId) {
          setPhoneNumber({ phone: data.phoneNumber });
          // @ts-ignore
          setPatientData({ id: data.patientId });
          history.push(URL_EXPIRED_LINK);
          return;
        }

        // case unique link doesn't exist
        if (!data.isValid) {
          history.push(URL_INVALID_UNIQUE_LINK);
          return;
        }

        if (data.isValid) {
          setRandomString({ randomString })
          setFirstName(data.firstName || '')
        }
      } catch (err) {
        history.push(URL_INVALID_UNIQUE_LINK);
        return;
      } finally {
        setLoading(false);
      }
    }   
    verifyLink();
  }, [randomString]);

  const submitDob = async (e) => {
    e.preventDefault();
    setLoading(true);
    const birthYear = new Date(dob).getFullYear().toString();
    const res = await verifyBirthYear({
      birthYear,
      randomString,
    });
    const { data : { data } } = res;
    if (data?.isValid && data?.patientData && Array.isArray(data.patientData.phones)) {
      setPatientData(data.patientData);
      const phone = addCountryCode(data?.patientData?.phones[0]);
      setPhoneNumber({ phone });
      setUniqueLinkId({ uniqueLinkId: data.uniqueLinkId || '' })

      generateRecaptcha();

      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(phoneAuth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setComesFromUniqueLink({ comesFromUniqueLink: true });
          history.push(URL_REGISTER_PHONE_VERIFY);
        })
        .catch((e) => {
          errorCallback();
        });
    } else {
      errorCallback();
    }
    setLoading(false);
  }

  const errorCallback = () => {
    setFailedDOB(true);
    present({
      position: 'top',
      cssClass: 'lc-dob-toast',
      message: t('uniqueLink.dob_verification.wrong_dob'),
    });
  };

  return (
    <>
      <div id="sign-in-button" />
      <IonPage>
        <IonContent fullscreen>
          <div className="block-center">
            <div className="lc-login-container ion-justify-content-center">
              <div className="ion-text-start">
                <img className="lc-register-logo" alt="live chair logo" src={lc_logo} />
              </div>
              {loading && <Spinner isOpen={loading} />}
              <IonItem lines="none">
                <IonText className="lc-register-headline">{t('uniqueLink.hi')}, {firstName}!</IonText>
              </IonItem>
              <IonItem lines="none">
                <IonText className="lc-dob-verification-link-subtitle">{t('uniqueLink.dob_verification_subtitle')}</IonText>
              </IonItem>
              {/* Date of birth */}
              <IonRow>
                <IonCol>
                  <ItemFormGroup label={t('page_profile_register.label_date_of_birth')} >
                    <IonDatetime displayFormat="YYYY" onIonChange={(e) => {
                      e.detail.value && setDob(e.detail?.value);
                    }}/>
                  </ItemFormGroup>
                </IonCol>
              </IonRow>
              
              <IonButton expand="block" onClick={submitDob} disabled={!dob || failedDOB} color={dob ? 'primary' : 'medium'}>
                {t('uniqueLink.let_go')}
              </IonButton>
              <IonFooter className="lc-footer lc-login-container lch-no-border lch-light-teal">
                <div className={'lc-footer-container'}>
                  <img className="lch-circle" src={member} alt="footer image" />
                  <div className={"lch-member-text"}>
                    <div>{t('uniqueLink.page_member_advice')}</div>
                    <div className={"lc-bold"}>{t('uniqueLink.lch_member')}</div>
                  </div>
                </div>
              </IonFooter>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};
