import { useEffect, useState } from 'react';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { LoadingState } from '../../../../common/store-atom/loading-atom';
import { UserHealthState } from '../../../../common/store-atom/user-health-atom';
import { Spinner } from '../../../../components/spinner/spinner';
import { mapQuestionnaires, getQuestionTitle } from '../../utils/utils';
import { SlideButtons } from '../buttons';
import { SliderBody } from '../slider-body';
import { SliderHeader } from '../slider-header';
import { ISliderProps } from '../slider';

import './styles.scss';

export const RadioWithSubquestionsQuestion = ({
  swiper,
  isRepresentativeHRA = false,
  verifyLanguageSetting,
  question,
  index,
  isLast,
}: ISliderProps) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<Array<any>>();
  const [selections, setSelections] = useState<{}>({});
  const [userHealthState, setUserHealthState] = useRecoilState(UserHealthState);
  const [loadingState] = useRecoilState(LoadingState);

  const setMappedQuestions = () => {
    let mappedQuestions: Array<any>;
    if (question?.questions) {
      mappedQuestions = question.questions.map((q) => mapQuestionnaires(q.options, verifyLanguageSetting()));
      setQuestions(mappedQuestions);
    }
  };

  const handleOnChange = (value: string, id: string) => {
    setSelections({
      ...selections,
      [id]: value,
    });
    setUserHealthState({ ...userHealthState, [id]: value });
  };

  useEffect(() => {
    setMappedQuestions();
  }, [question]);

  return (
    <div>
      <Spinner isOpen={loadingState} />

      <SliderBody>
        <div className="lc-slider-wrapper">
          <SliderHeader description={getQuestionTitle(question, verifyLanguageSetting())} />

          {!!questions &&
            Array.isArray(questions) &&
            questions.map((q, index) => (
              <IonItem key={`${index}-question-item`} lines="none" className="lc-form-group">
                <IonLabel position="stacked">
                  {getQuestionTitle(question?.questions && question.questions[index], verifyLanguageSetting())}
                </IonLabel>
                <IonSelect
                  placeholder={t('questionnaire.selectOne')}
                  okText={t('button_title_apply')}
                  cancelText={t('ModalWarning_button_cancel')}
                  onIonChange={(e) => handleOnChange(e.detail.value, (question.questions && question.questions[index].id) || '')}
                >
                  {q &&
                    q.map((elem: any, index: number) => {
                      return (
                        <IonSelectOption key={`${index}-select-option-radio`} value={elem.value}>
                          {elem.name}
                        </IonSelectOption>
                      );
                    })}
                </IonSelect>
              </IonItem>
            ))}
        </div>
      </SliderBody>

      <SlideButtons
        swiper={swiper}
        disabled={Object.keys(selections).length < (questions?.length || 1)}
        isRepresentativeHRA={isRepresentativeHRA}
        buttonFinish={isLast}
        showBackButton={index > 0}
      />
    </div>
  );
};
