import { IonButton, IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { REQUIRED_MESSAGE } from '../../common/constants/validation-message-error';
import { RequestAppointmentModel } from '../../common/models/request-appointment';
import { useMutation } from 'react-query';
import { requestAppointment } from '../../common/adapters/request-appointment';
import { AxiosError } from 'axios';
import { MessageToastHooks } from '../../common/hooks/message-toast';
import { ContactType } from '../../pages/tabs/home/enums/contact-type';
import './styles.scss';

interface IRequestAppointmentProps {
  callback: () => void;
  contactType: ContactType;
  title: string;
}
export const RequestAppointment = ({ callback, contactType }: IRequestAppointmentProps) => {
  const { messageToastError } = MessageToastHooks();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    contactTime: yup.string().required(REQUIRED_MESSAGE),
    notes: yup.string(),
    contactType: yup.string(),
  });

  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: { contactTime: '', notes: '', contactType: contactType, title: '' },
    resolver: yupResolver(schema),
  });

  const mutationRequestAppointment = useMutation(requestAppointment, {
    onSuccess: () => {
      reset();
      callback();
    },
    onError: (error: AxiosError) => {
      messageToastError(error);
    },
  });

  const onSubmit = (requestAppointment: RequestAppointmentModel) => {
    mutationRequestAppointment.mutate({ ...requestAppointment });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonLoading isOpen={mutationRequestAppointment.isLoading} message={t('spinner.message')}></IonLoading>

        <IonItem
          lines="none"
          className={formState.errors.contactTime?.message ? 'lc-form-group lc-form-group-error' : 'lc-form-group'}
        >
          <IonLabel position="stacked" color={formState.errors.contactTime?.message ? 'danger' : 'primary'}>
            {t('providers_tab_request_appointment_modal.title')}?
          </IonLabel>
          <IonSelect cancelText={t('button_title_cancel')} okText={t('button_title_apply')} {...register('contactTime')}>
            <IonSelectOption key="Morning" value="Morning">
              {t('providers_tab_request_appointment_modal_time.select_morning')}
            </IonSelectOption>
            <IonSelectOption key="Afternoon" value="Afternoon">
              {t('providers_tab_request_appointment_modal_time.select_afternoon')}
            </IonSelectOption>
            <IonSelectOption key="After 4pm " value="After 4pm">
              {t('providers_tab_request_appointment_modal_time.select_after_four_pm')}{' '}
            </IonSelectOption>
          </IonSelect>
          <span>{formState.errors.contactTime?.message}</span>
        </IonItem>

        <IonItem lines="none">
          <IonLabel position="stacked">{t('providers_tab_request_appointment_additional_notes.label')}</IonLabel>
          <textarea className="text-box" maxLength={255} {...register('notes')} />
        </IonItem>

        <IonButton type="submit" expand="block">
          {t('providers_tab_request_button.label')}
        </IonButton>
      </form>
    </>
  );
};
