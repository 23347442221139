import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { format, formatISO } from 'date-fns';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { IonCard, IonChip, IonIcon, IonLabel, IonText } from '@ionic/react';
import { checkmarkCircleOutline, chevronForwardOutline, timeOutline } from 'ionicons/icons';

import { ActionCard } from '../../enums/action_card';
import { StatusCard } from '../../enums/status-card';
import { ICardProps } from '../../interfaces/ICardProps';
import { ExternalLinkModal } from '../../../../../components/externalLinkModal/ExternalLinkModal';
import { ContactType } from '../../enums/contact-type';
import { RegisterRequestAppointment } from './components/request-appointment';
import { URL_MAIN_HOME_HEALTH_QUESTIONNAIRE, URL_MAIN_HEALTH_APPOINTMENT } from '../../../../../common/constants/routers';
import BG1 from '../../../../../assets/img/rebrand-cards/BG1.jpg';
import BG2 from '../../../../../assets/img/rebrand-cards/BG2.jpg';
import BG3 from '../../../../../assets/img/rebrand-cards/BG3.jpg';
import BG4 from '../../../../../assets/img/rebrand-cards/BG4.jpg';
import stepIcon from '../../../../../assets/icons/step.svg';
import api from '../../../../../common/adapters/api';
import './styles.scss';
import '../rebrandCards.scss';

const BGList = [BG1, BG2, BG3, BG4];

export const TakeNextStepCard: React.FC<ICardProps> = (props) => {
  const { action, status } = props;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isOpenModalCard, setOpenModalCard] = useState<boolean>(false);
  const [isOpenArticleModal, setOpenArticleModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const BGImage = useMemo(() => Math.floor(Math.random() * 4), []);

  const handleOnClick = () => {
    if (props.status === StatusCard.ASSIGNED && props.action === ActionCard.PCP_LIST_REDIRECT) {
      history.push(URL_MAIN_HEALTH_APPOINTMENT);
    } else {
      setOpenModalCard(true);
    }
  };

  const handleRefresHomePage = () => {
    queryClient.invalidateQueries('homeCards');
  };

  const handleOpenArticleModal = () => {
    setOpenArticleModal(true);
  };

  const handleNotifyReadArticle = async () => {
    if (props.customProperties?.diseaseType) {
      await api.put(`articles/diseases/${props.customProperties.diseaseType}`);
      InAppBrowser.create(props.customProperties.url);
    }
    return;
  };

  const redirectToQuestionnaire = () => {
    history.push(URL_MAIN_HOME_HEALTH_QUESTIONNAIRE);
  };

  const CardIcon = {
    Step: stepIcon,
    Generic: stepIcon,
    PCP: stepIcon,
  };

  return (
    <>
      {action === ActionCard.INSURANCE_APPOINTMENT && status === StatusCard.ASSIGNED && isOpenModalCard && (
        <RegisterRequestAppointment
          isOpen={isOpenModalCard}
          title={t('journey_chronic_diseases_request_appointment_modal.title')}
          handleCloseModal={() => setOpenModalCard(false)}
          callback={() => handleRefresHomePage()}
          contactType={ContactType.INSURANCE_OPTIONS}
        />
      )}
      {action === ActionCard.CHRONIC_DISEASES_APPOINTMENT && status === StatusCard.ASSIGNED && isOpenModalCard && (
        <RegisterRequestAppointment
          isOpen={isOpenModalCard}
          title={t('journey_health_insurance_request_appointment_modal.title')}
          handleCloseModal={() => setOpenModalCard(false)}
          callback={() => handleRefresHomePage()}
          contactType={ContactType.CHRONIC_DISEASES}
        />
      )}
      {action === ActionCard.QUIT_SMOKING_APPOINTMENT && status === StatusCard.ASSIGNED && isOpenModalCard && (
        <RegisterRequestAppointment
          isOpen={isOpenModalCard}
          title={t('journey_quit_smoking_program_request_appointment_modal.title')}
          handleCloseModal={() => setOpenModalCard(false)}
          callback={() => handleRefresHomePage()}
          contactType={ContactType.QUIT_SMOKING_PROGRAM}
        />
      )}
      {action === ActionCard.OPEN_ARTICLE_TRIGGER && status === StatusCard.ASSIGNED && isOpenArticleModal && (
        <ExternalLinkModal
          show={isOpenArticleModal}
          handleOnhideModal={() => setOpenArticleModal(false)}
          externalURL={props.customProperties?.url}
          diseaseType={props.customProperties?.diseaseType}
        />
      )}
      <IonCard
        className="rebrand-card"
        style={{ boxShadow: props.status === 'done' ? 'none' : '' }}
        {...(action && { onClick: handleOnClick })}
      >
        {status === StatusCard.ASSIGNED && (
          <div
            style={{
              backgroundImage: `url(${BGList[BGImage]})`,
            }}
          />
        )}

        {status === StatusCard.PENDING && (
          <div
            style={{
              backgroundImage: `url(${BGList[BGImage]})`,
            }}
          />
        )}

        <section className="rebrand-card-container">
          <section className="rebrand-card-content">
            <section className="rebrand-card-date">
              <IonIcon src={CardIcon[props.type]} />
              <time dateTime={formatISO(new Date(String(props.createdAt)), { representation: 'date' })}>
                <span>{format(new Date(props.createdAt), 'MMM').toUpperCase()}</span>
                <span>{format(new Date(props.createdAt), 'dd')}</span>
              </time>
            </section>

            <section className="rebrand-card-text">
              {status === StatusCard.ASSIGNED && (
                <IonText style={{ display: props.type === 'Step' ? 'inherit' : 'none' }}>
                  {t('page_home_next_step').toUpperCase()}
                </IonText>
              )}

              {status.toLocaleLowerCase() === StatusCard.DONE ? (
                <IonChip className={'rebrand-card-chip confirmed'}>
                  <IonIcon icon={props.status ? checkmarkCircleOutline : timeOutline} color="white" />
                  <IonLabel>{props.status}</IonLabel>
                </IonChip>
              ) : (
                <IonChip className={'rebrand-card-chip unconfirmed'}>
                  <IonIcon icon={props.status ? checkmarkCircleOutline : timeOutline} color="white" />
                  <IonLabel>{props.status}</IonLabel>
                </IonChip>
              )}

              <IonText>{props.title}</IonText>
              <IonText>{props.detail}</IonText>
            </section>
          </section>

          {action === ActionCard.HEALTH_QUESTIONAIRIE && status === StatusCard.ASSIGNED && (
            <section
              onClick={redirectToQuestionnaire}
              style={{
                opacity: props.status === 'done' ? 0 : 1,
                backgroundColor: '#D7F0FD',
              }}
              className="rebrand-card-arrow"
            >
              <IonIcon icon={chevronForwardOutline} />
            </section>
          )}
          {action === ActionCard.OPEN_ARTICLE_TRIGGER && status === StatusCard.ASSIGNED && (
            <section
              onClick={() => handleOpenArticleModal()}
              style={{
                opacity: props.status === 'done' ? 0 : 1,
                backgroundColor: '#D7F0FD',
              }}
              className="rebrand-card-arrow"
            >
              <IonIcon icon={chevronForwardOutline} />
            </section>
          )}
          {action === ActionCard.OPEN_EXTERNAL_ARTICLE_TRIGGER && StatusCard.ASSIGNED && (
            <a onClick={handleNotifyReadArticle} href={props.customProperties?.url} target="_blank">
              <section
                style={{
                  opacity: props.status === 'done' ? 0 : 1,
                }}
                className="rebrand-card-arrow"
              >
                <IonIcon icon={chevronForwardOutline} />
              </section>
            </a>
          )}
        </section>
      </IonCard>
    </>
  );
};
